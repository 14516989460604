import { lazy, Suspense } from 'react';
import Spinner from '~/components/UIElements/Spinner';

/**
 * Lazily load the mentioned component which resides in the page directory
 * This method will be used in routes so that the files are loaded only
 * When users are on that route
 */
export function lazyLoadRoutes(componentName: string) {
    const LazyElement = lazy(
        () => import(`../../pages/${componentName}/index.tsx`)
    );

    return (
        <Suspense
            fallback={
                <div
                    style={{
                        width: '100vw',
                        height: '100vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#fff',
                    }}
                >
                    <Spinner height={80} width={80} />
                </div>
            }
        >
            <LazyElement />
        </Suspense>
    );
}
