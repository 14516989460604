import { useEffect, useMemo, useState } from 'react';
import { BaseModal, Button, SelectField, TextField } from '../UIElements';
import { X } from 'react-feather';
import './index.scss';
import analytics, { EVENT_NAMES_ENUM } from '~/helpers/analytics';
import { IntlShape } from 'react-intl';
import { projectsServices } from '~/services';
import toast from 'react-hot-toast';
import { getErrorCode } from '~/utils/getErrorCode';
import { LabelValueOptionInterface } from '~/types/labelValueOption.interface';
import useWorkspaces from '~/modules/Workspaces/hooks/useWorkspaces';
import useWorkspacesStore from '~/modules/Workspaces/stores';
import { ProjectInterface } from '~/modules/Projects/types/projects.interface';

interface CreateProjectDialogProps {
    intl: IntlShape;
    onCreate: (newProject: ProjectInterface) => void;
    onClose: () => void;
}

const CreateProjectDialog: React.FC<CreateProjectDialogProps> = ({
    onCreate,
    onClose,
    intl,
}) => {
    const { selectedWorkspace } = useWorkspaces();
    const workspaces = useWorkspacesStore((state) => state.workspaces);

    const [loading, setLoading] = useState(false);

    const [project, setProject] = useState<{
        name: string;
        workspace: LabelValueOptionInterface | null;
    }>({
        name: '',
        workspace: null,
    });

    const WORKSPACE_OPTIONS: LabelValueOptionInterface[] = useMemo(() => {
        const filtered = workspaces.filter((ws) => ws.details.user_membership);

        return filtered.map((ws) => ({
            label: ws.name,
            value: ws.id,
        }));
    }, []);

    useEffect(() => {
        const defaultWs = WORKSPACE_OPTIONS.find(
            (ws) => ws.value === selectedWorkspace?.id
        );

        setProject({
            ...project,
            workspace: defaultWs || WORKSPACE_OPTIONS[0],
        });
    }, [WORKSPACE_OPTIONS]);

    const onSubmit = async (e) => {
        e.preventDefault();
        const { name, workspace } = project;

        setLoading(true);

        const [error, response] = await projectsServices.createProject({
            name,
            workspace_id: workspace.value,
        });

        setLoading(false);

        if (error) {
            toast.error(
                intl.formatMessage({ id: getErrorCode(response.error_code) })
            );
            return;
        }

        toast.success(
            intl.formatMessage({ id: 'project_created_successfully' })
        );

        analytics.trackGenericEvent(EVENT_NAMES_ENUM.CREATE_PROJECT);

        onCreate(response.data);
        onClose();
    };

    return (
        <BaseModal handleClose={onClose} size="small">
            <div className="dg-create-project-dialog">
                <div>
                    <h3>{intl.formatMessage({ id: 'new_project' })}</h3>
                    <X
                        onClick={(e) => {
                            onClose();
                            e.stopPropagation();
                        }}
                    />
                </div>

                <form onSubmit={onSubmit}>
                    <TextField
                        label={intl.formatMessage({ id: 'project_name' })}
                        inputProps={{
                            onChange: (e) =>
                                setProject({
                                    ...project,
                                    name: e.target.value,
                                }),
                            value: project.name,
                            type: 'text',
                            placeholder:
                                intl.formatMessage({ id: 'project_name' }) +
                                '...',
                            autoFocus: true,
                        }}
                    />
                    <SelectField
                        label={intl.formatMessage({ id: 'workspace' })}
                        inputProps={{
                            options: WORKSPACE_OPTIONS,
                            onChange: (e) =>
                                setProject({ ...project, workspace: e }),
                            value: project.workspace,
                            placeholder:
                                intl.formatMessage({ id: 'workspace' }) + '...',
                        }}
                    />

                    <Button
                        disabled={
                            loading || !project.workspace || !project.name
                        }
                        type="submit"
                        variant="accent"
                    >
                        {intl.formatMessage({ id: 'create_project' })}
                    </Button>
                </form>
            </div>
        </BaseModal>
    );
};

export default CreateProjectDialog;
