import Swal from 'sweetalert2';

interface DeletionDialogInterface {
    title?: string;
    text?: string;
    confirmationText: string;
    onConfirm: () => void;
}

export const fireDeletionDialog = ({
    title,
    text,
    confirmationText,
    onConfirm,
}: DeletionDialogInterface) => {
    Swal.fire({
        title,
        text,
        confirmButtonColor: '#d33',
        confirmButtonText: confirmationText,
        showClass: {
            popup: 'animate__animated animate__fadeIn animate__faster',
        },
        hideClass: {
            popup: 'animate__animated animate__fadeOut animate__faster',
        },
        customClass: {
            denyButton: 'd-none',
            confirmButton: 'w-100',
        },
    }).then((result) => {
        if (result.isConfirmed) {
            onConfirm();
        }
    });
};
