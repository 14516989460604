import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import LanguageProvider from './LanguageProvider';

//css
import './assets/css/bootstrap.css';
import './assets/css/core.scss';
import 'normalize.css';
import 'react-loading-skeleton/dist/skeleton.css';

import Router from './components/Router';
import { environment } from '~/config/env';
import { hotjar } from 'react-hotjar';
import AuthProvider from '~/context/auth';

class App extends React.PureComponent {
    render() {
        if (environment.hotjarId && environment.hotjarVersion) {
            hotjar.initialize(environment.hotjarId, environment.hotjarVersion);
        }
        return (
            <LanguageProvider>
                <AuthProvider>
                    <BrowserRouter>
                        <Router />
                    </BrowserRouter>
                </AuthProvider>
            </LanguageProvider>
        );
    }
}

export default App;
