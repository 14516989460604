import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Info } from 'react-feather';
import { ImportFileTypes } from '~/constants/importFiles';
import { BaseModal, Button } from '~/components/UIElements';
import DropArea from './DropArea';
import Header from './Header';
import FilesToImporTList from './FilesToImportList';
import LinksToImportsList from './LinksToImportList';
import RejectedFiles from './RejectedFiles';
import useImportModal from './ImportModal.hook';

import styles from './ImportModal.module.scss';

interface ImportModalProps {
    handleClose: () => void;
}

const ImportModal = ({ handleClose }: ImportModalProps) => {
    const intl = useIntl();
    const {
        importLoading,
        filesToImport,
        linksToImport,
        rejectedFiles,
        onAddFiles,
        onRemoveFile,
        onStartImport,
        onClose,
        areWeblinksDisabled,
        importMessage,
        showImportMessage,
        setLinksToImport,
        setRejectedFiles,
        clearImportFiles,
    } = useImportModal(handleClose);

    const [view, setView] = useState<ImportFileTypes>(ImportFileTypes.FILE);

    const setImportView = (view: string) => setView(view as ImportFileTypes);

    const filesImportedMessage = intl.formatMessage(
        { id: filesToImport.length === 1 ? 'single_file' : 'files' },
        { count: filesToImport.length }
    );

    return (
        <BaseModal size="xlarge" handleClose={onClose} noPadding>
            <Header
                currentView={view}
                handleView={setImportView}
                loading={importLoading}
                onStartImport={onStartImport}
                onCancelImport={onClose}
                areWeblinksDisabled={areWeblinksDisabled}
                disableImport={!filesToImport.length && !linksToImport.length}
            />

            {view === ImportFileTypes.FILE ? (
                <DropArea
                    onDropFiles={onAddFiles}
                    addedItems={filesToImport}
                    handleRejectedFiles={setRejectedFiles}
                >
                    {filesToImport.length > 0 && (
                        <div
                            style={{
                                width: '100%',
                                paddingBottom: rejectedFiles.length
                                    ? '56px'
                                    : '0',
                            }}
                        >
                            <div className={styles.cgImportModal__summary}>
                                <span
                                    className={
                                        styles.cgImportModal__summaryTitle
                                    }
                                >
                                    {filesImportedMessage}
                                </span>
                                <Button
                                    variant="tertiary"
                                    onClick={() => clearImportFiles()}
                                >
                                    {intl.formatMessage({
                                        id: 'clear_all',
                                    })}
                                </Button>
                            </div>

                            <FilesToImporTList
                                files={filesToImport}
                                onRemoveFile={onRemoveFile}
                            />
                        </div>
                    )}
                </DropArea>
            ) : (
                <LinksToImportsList
                    links={linksToImport}
                    handleLinks={setLinksToImport}
                    loading={importLoading}
                />
            )}

            {showImportMessage && (
                <div className={styles.cgImportModal__message}>
                    <Info />
                    <span>{importMessage}</span>
                </div>
            )}
            {rejectedFiles.length > 0 && (
                <RejectedFiles
                    files={rejectedFiles}
                    onClose={() => setRejectedFiles([])}
                />
            )}
        </BaseModal>
    );
};

export default ImportModal;
