import { useEffect, useMemo, useState } from 'react';
import './WorkspaceMembers.scss';
import { Button, SelectInput, TextField } from '../../../UIElements';
import { WORKSPACE_MEMBER_ROLES } from '../../../../constants/memberRoles';
import { Mail } from 'react-feather';
import { workspaceServices } from '~/services';
import toast from 'react-hot-toast';
import MemberRow from '../members/MemberRow';
import { useEntityContext } from '../../../../hooks';
import Skeleton from 'react-loading-skeleton';
import randomColors from '~/constants/randomColorsArray';
import { AuthContextInterface } from '~/interfaces/contexts';
import { AuthContext } from '~/context/auth';
import useWorkspaces from '~/modules/Workspaces/hooks/useWorkspaces';
import { useShallow } from 'zustand/react/shallow';
import { useIntl } from 'react-intl';
import useWorkspacesStore from '~/modules/Workspaces/stores';

const mainClass = 'cg-ws-members';

const WorkspaceMembers = () => {
    const intl = useIntl();

    const { selectedWorkspace } = useWorkspaces();

    const [members, invitations, loadingMembers, fetchMembersAndInvitations] =
        useWorkspacesStore(
            useShallow((state) => [
                state.members,
                state.invitations,
                state.loadingMembers,
                state.fetchMembersAndInvitations,
            ])
        );

    const { user } = useEntityContext<AuthContextInterface>(AuthContext);

    const initialValues = useMemo(
        () => ({
            email: '',
            role: {
                value: WORKSPACE_MEMBER_ROLES.USER,
                label: intl.formatMessage({ id: 'user' }),
            },
        }),
        []
    );

    const [loading, setLoading] = useState(false);
    const [member, setMember] = useState(initialValues);

    useEffect(() => {
        fetchMembersAndInvitations();
    }, []);

    const PERMISSIONS = useMemo(
        () => [
            {
                value: WORKSPACE_MEMBER_ROLES.ADMIN,
                label: intl.formatMessage({ id: 'admin' }),
            },
            {
                value: WORKSPACE_MEMBER_ROLES.USER,
                label: intl.formatMessage({ id: 'user' }),
            },
        ],
        []
    );

    const DROPDOWN_OPTIONS = useMemo(
        () => [
            ...PERMISSIONS,
            {
                label: intl.formatMessage({ id: 'remove' }),
                value: 'remove',
                variant: 'distructive',
            },
        ],
        []
    );

    const onChange = (name, value) => {
        setMember({
            ...member,
            [name]: value,
        });
    };

    const onAddMember = async (e) => {
        e.preventDefault();
        setLoading(true);

        const [error] = await workspaceServices.inviteMember({
            email: member.email,
            role: member.role.value,
            workspace_id: selectedWorkspace.id,
        });

        setLoading(false);

        if (error)
            return toast.error(intl.formatMessage({ id: 'general_error' }));

        toast.success(intl.formatMessage({ id: 'invitation_sent' }));

        setMember(initialValues);
        fetchMembersAndInvitations();
    };

    const onRemoveInvitation = async (invitation) => {
        setLoading(true);

        const [error] = await workspaceServices.deleteMemberInvitation(
            invitation.id
        );

        setLoading(false);
        if (error) {
            return toast.error(intl.formatMessage({ id: 'general_error' }));
        }

        toast.success(
            intl.formatMessage({ id: 'invitation_deleted_successfully' })
        );

        fetchMembersAndInvitations();
    };

    const handleInvitationPermission = async (invitation, role) => {
        setLoading(true);

        const [error] = await workspaceServices.updateMemberInvitation(
            invitation.id,
            {
                role,
            }
        );

        setLoading(false);
        if (error) {
            return toast.error(intl.formatMessage({ id: 'general_error' }));
        }
        toast.success(
            intl.formatMessage({ id: 'invitation_updated_successfully' })
        );

        fetchMembersAndInvitations();
    };

    const onRemoveMember = async (member) => {
        setLoading(true);

        const [error] = await workspaceServices.deleteMember(member.id);

        setLoading(false);
        if (error) {
            return toast.error(intl.formatMessage({ id: 'general_error' }));
        }

        toast.success(
            intl.formatMessage({ id: 'member_deleted_successfully' })
        );

        fetchMembersAndInvitations();
    };

    const handleMemberPermission = async (member, role) => {
        setLoading(true);

        const [error] = await workspaceServices.updateMember(member.id, {
            role,
        });

        setLoading(false);
        if (error) {
            return toast.error(intl.formatMessage({ id: 'general_error' }));
        }
        toast.success(
            intl.formatMessage({ id: 'member_updated_successfully' })
        );

        fetchMembersAndInvitations();
    };

    const nomralizedMembers = useMemo(() => {
        const normalized = members.map((member) => ({
            ...member,
            first_name: member.user.first_name,
            last_name: member.user.last_name,
            email: member.user.email,
        }));

        return normalized;
    }, [members]);

    return (
        <div className={mainClass}>
            <form onSubmit={onAddMember}>
                <TextField
                    label={intl.formatMessage({
                        id: 'invite_people_via_mail',
                    })}
                    inputProps={{
                        value: member.email,
                        onChange: (e) => onChange('email', e.target.value),
                        placeholder: 'john@gmail.com',
                        type: 'text',
                        disabled: loadingMembers || loading,
                    }}
                    iconBefore={Mail}
                />
                <div
                    className={`${mainClass}__invitation-dropdown ${
                        loadingMembers
                            ? `${mainClass}__invitation-dropdown__loading`
                            : ''
                    }`}
                >
                    <SelectInput
                        options={PERMISSIONS}
                        value={member.role}
                        onChange={(e) => onChange('role', e)}
                        dropdownAlign="center"
                    />
                    <Button
                        disabled={
                            !member.email.length || loadingMembers || loading
                        }
                        type="submit"
                        variant="accent"
                    >
                        {intl.formatMessage({
                            id: 'add',
                        })}
                    </Button>
                </div>
            </form>

            {loading || loadingMembers ? (
                <Skeleton className={mainClass + '__skeleton'} count={5} />
            ) : (
                <div className={`${mainClass}__members`}>
                    {invitations.length > 0 && (
                        <div>
                            <span>{intl.formatMessage({ id: 'pending' })}</span>
                            <div className={mainClass + '__list'}>
                                {invitations.map((member, idx) => (
                                    <MemberRow
                                        key={member.id}
                                        member={member}
                                        onChangeMember={
                                            handleInvitationPermission
                                        }
                                        onRemoveMember={onRemoveInvitation}
                                        dropdownOptions={DROPDOWN_OPTIONS}
                                        permissions={PERMISSIONS}
                                        intl={intl}
                                        color={randomColors[idx]}
                                        editable
                                        pending
                                    />
                                ))}
                            </div>
                        </div>
                    )}

                    <div>
                        <span
                            style={{
                                marginBottom: '4px',
                            }}
                        >
                            {intl.formatMessage({ id: 'members' })}
                        </span>

                        <div className={mainClass + '__list'}>
                            {nomralizedMembers.map((member, idx) => (
                                <MemberRow
                                    key={member.id}
                                    member={member}
                                    onChangeMember={handleMemberPermission}
                                    onRemoveMember={onRemoveMember}
                                    dropdownOptions={DROPDOWN_OPTIONS}
                                    permissions={PERMISSIONS}
                                    intl={intl}
                                    color={randomColors[idx]}
                                    editable={
                                        member.role !==
                                            WORKSPACE_MEMBER_ROLES.OWNER &&
                                        member.user_id !== user?.id
                                    }
                                />
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default WorkspaceMembers;
