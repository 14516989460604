import { useEffect, useMemo, useRef } from 'react';
import { ChevronDown, ChevronUp, Info, X } from 'react-feather';
import { FileWithPath } from 'react-dropzone';
import { useIntl } from 'react-intl';
import { useFloatMenu } from '~/hooks';
import classNames from 'classnames';

import styles from './RejectedFiles.module.scss';

interface RejectedFilesProps {
    files: FileWithPath[];
    onClose: () => void;
}
const RejectedFiles = ({ files, onClose }: RejectedFilesProps) => {
    const intl = useIntl();
    const contentEl = useRef<HTMLDivElement>(null);
    const accordionMenu = useFloatMenu();

    const accordionStyles = useMemo(() => {
        return {
            height:
                accordionMenu.showMenu && contentEl?.current
                    ? contentEl.current.scrollHeight + 12
                    : 0,
            marginTop: accordionMenu.showMenu ? '10px' : 0,
        };
    }, [contentEl, accordionMenu]);

    const headerTitle = intl.formatMessage(
        {
            id: files.length > 1 ? 'files_rejected' : 'file_rejected',
        },
        { count: files.length }
    );

    useEffect(() => {
        if (files.length) accordionMenu.setShowMenu(false);
    }, [files]);

    return (
        <div className={styles.cgRejectedFiles}>
            <div className={styles.cgRejectedFiles__header}>
                <div className={styles.cgRejectedFiles__headerItem}>
                    <Info />
                    <span>{headerTitle}</span>
                </div>

                <div
                    className={classNames(
                        styles.cgRejectedFiles__headerItem,
                        styles['cgRejectedFiles__headerItem--controls']
                    )}
                >
                    {accordionMenu.showMenu ? (
                        <ChevronUp onClick={accordionMenu.handleMenu} />
                    ) : (
                        <ChevronDown onClick={accordionMenu.handleMenu} />
                    )}
                    <X onClick={onClose} />
                </div>
            </div>

            <div
                className={styles.cgRejectedFiles__accordion}
                style={accordionStyles}
            >
                <div
                    ref={contentEl}
                    className={styles.cgRejectedFiles__content}
                >
                    {files.map((file, fileIndex) => (
                        <span
                            key={fileIndex}
                            className={styles.cgRejectedFiles__item}
                        >
                            {file.name}
                        </span>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default RejectedFiles;
