import { StageTabsEnum } from '~/constants/importFiles';
import './ImportStageTabs.scss';

const mainClass = 'import-stage-tabs';

interface TabInterface {
    label: string;
    value: StageTabsEnum;
    count: number;
}

interface ImportStageTabsInterface {
    tabs: TabInterface[];
    activeTab: StageTabsEnum;
    onChangeTab: (tab: StageTabsEnum) => void;
}

const ImportStageTabs: React.FC<ImportStageTabsInterface> = ({
    tabs,
    activeTab,
    onChangeTab,
}) => {
    return (
        <div className={mainClass}>
            {tabs.map((tab) => (
                <div
                    className={`${mainClass}__tab ${activeTab === tab.value ? `${mainClass}__active-tab` : ''}`}
                    key={tab.value}
                    onClick={() => onChangeTab(tab.value)}
                >
                    <span>{tab.label} </span>
                    <span className={mainClass + '__tab__count-bubble'}>
                        {tab.count}
                    </span>
                </div>
            ))}
        </div>
    );
};

export default ImportStageTabs;
