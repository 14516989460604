import { IntlShape } from 'react-intl';
import ImportHistoryModal from '~/components/Import/ImportHistoryModal';
import ImportModal from '~/modules/Import/components/ImportModal';
import ImportProgressBar from '~/components/Import/ImportProgressBar';
import PublicLibrary from '~/components/PublicLibrary';
import { ImageViewerModal } from '~/components/UIElements';
import { ImageViewerContext } from '~/context/imageViewer';
import { ProjectContext } from '~/context/project';
import { TagsContext } from '~/context/tags';
import { UploadContext } from '~/context/upload';
import { useEntityContext } from '~/hooks';
import {
    ImageViewerProviderInterface,
    ProjectProviderInterface,
} from '~/interfaces/contexts';
import { TagsProviderInterface } from '~/modules/Tags/types/TagsContext.interface';
import { UploadProviderInterface } from '~/interfaces/contexts/UploadContext.interface';

const GlobalAccessModals = ({ intl }: { intl: IntlShape }) => {
    const {
        handleImportHistoryModal,
        showImportHistoryModal,
        showProgressMenu,
    } = useEntityContext<UploadProviderInterface>(UploadContext);

    const {
        showLibrary,
        handleShowLibrary,
        showImportModal,
        handleShowImportModal,
    } = useEntityContext<ProjectProviderInterface>(ProjectContext);

    const { getTags, getFolderStructure } =
        useEntityContext<TagsProviderInterface>(TagsContext);

    const { image: viewerImage, closeViewer: closeImageViewer } =
        useEntityContext<ImageViewerProviderInterface>(ImageViewerContext);

    const onImportLibaryCallback = () => {
        getTags();
        getFolderStructure();
    };

    return (
        <>
            {viewerImage && (
                <ImageViewerModal
                    img={viewerImage.blob}
                    onClose={closeImageViewer}
                    intl={intl}
                    hideDownload={viewerImage.options?.hideDownload}
                />
            )}

            {showImportHistoryModal && (
                <ImportHistoryModal
                    intl={intl}
                    handleClose={() => handleImportHistoryModal(false)}
                />
            )}

            {showProgressMenu && <ImportProgressBar intl={intl} />}

            {showImportModal && (
                <ImportModal
                    handleClose={() => handleShowImportModal(false)}
                    intl={intl}
                />
            )}

            {showLibrary && (
                <PublicLibrary
                    intl={intl}
                    onClose={() => handleShowLibrary(false)}
                    onImportSuccess={onImportLibaryCallback}
                />
            )}
        </>
    );
};

export default GlobalAccessModals;
