import { ProjectInterfaceDetailed } from '~/modules/Projects/types/projects.interface';
import {
    PublicTagInterfaceDetailed,
    TagInterfaceDetailed,
} from '~/modules/Tags/types/Tag.interface';

export function normalizePublicTags(
    tags: PublicTagInterfaceDetailed[]
): TagInterfaceDetailed[] {
    return tags.map((tag) => ({
        ...tag,
        project: {} as ProjectInterfaceDetailed,
        details: {
            document_count: 0,
        },
        tag_group_id: tag.public_tag_group_id,
    }));
}
