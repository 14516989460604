import React, { useCallback } from 'react';
import './MemberRow.scss';
import { Mail } from 'react-feather';
import { Avatar, SelectInput } from '../../../UIElements';
import getNameInitials from '../../../../helpers/getNameInitials';
import { LabelValueOptionInterface } from '~/types/labelValueOption.interface';
import { IntlShape } from 'react-intl';
import { ProjectMemberInterfaceDetailed } from '~/modules/Projects/types/projects.interface';

interface MemberRowProps {
    member: ProjectMemberInterfaceDetailed;
    onChangeMember: (
        member: ProjectMemberInterfaceDetailed,
        role: string
    ) => void;
    permissions: LabelValueOptionInterface[];
    dropdownOptions: LabelValueOptionInterface[];
    color: string;
    editable: boolean;
    onRemoveMember: (
        member: ProjectMemberInterfaceDetailed,
        role: string
    ) => void;
    pending: boolean;
    intl: IntlShape;
}

const MemberRow: React.FC<MemberRowProps> = ({
    member,
    onChangeMember,
    permissions,
    dropdownOptions,
    color,
    editable,
    onRemoveMember,
    pending,
    intl,
}) => {
    const getValueByRole = useCallback(
        (role: ProjectMemberInterfaceDetailed['role']) => {
            const value = permissions.find((p) => p.value === role);
            return value || null;
        },
        []
    );

    const onChangeSelector = (e) => {
        if (e.value === 'remove') return onRemoveMember(member, e.value);
        onChangeMember(member, e.value);
    };

    return (
        <div className="cg-member-row">
            <div>
                {pending ? (
                    <div className="cg-member-row__mail">
                        <Mail />
                    </div>
                ) : (
                    <Avatar
                        value={getNameInitials(
                            member.first_name,
                            member.last_name
                        )}
                        size="small"
                        style={{
                            backgroundColor: color,
                        }}
                    />
                )}
                <div className="cg-member-row__name">
                    <span
                        style={{
                            textTransform: !pending ? 'capitalize' : 'none',
                        }}
                    >
                        {!pending ? (
                            `${member.first_name} ${member.last_name}`
                        ) : (
                            <>
                                {member.email}{' '}
                                <small>
                                    {intl.formatMessage({
                                        id: 'pending_invite',
                                    })}
                                </small>
                            </>
                        )}
                    </span>
                    {!pending && <span>{member.email}</span>}
                </div>
            </div>
            {editable && (
                <div className="dg-topbar-member-stack__input">
                    <SelectInput
                        value={getValueByRole(member.role)}
                        options={dropdownOptions}
                        onChange={onChangeSelector}
                        dropdownAlign="center"
                    />
                </div>
            )}
        </div>
    );
};

export default MemberRow;
