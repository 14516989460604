import { PropsWithChildren, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { UploadCloud } from 'react-feather';
import { useDropzone, FileWithPath, FileRejection } from 'react-dropzone';
import { FileToImportInterface } from '~/modules/Import/types/importFiles';
import {
    MAX_FILE_SIZE,
    MAX_FILE_SIZE_MB,
    MIN_FILE_SIZE,
} from '~/modules/Import/constants/fileSizes';
import {
    acceptedFormats,
    allowedFormats,
} from '~/modules/Import/constants/fileTypes';
import classNames from 'classnames';

import styles from './DropArea.module.scss';

interface DropAreaProps {
    addedItems: FileToImportInterface[];
    disabled?: boolean;
    onDropFiles: (files: FileWithPath[]) => void;
    handleRejectedFiles: (rejectedFiles: FileWithPath[]) => void;
}

const DropArea = ({
    addedItems,
    disabled,
    children,
    onDropFiles,
    handleRejectedFiles,
}: PropsWithChildren<DropAreaProps>) => {
    const intl = useIntl();

    const supportedTypesMessage = `${intl.formatMessage({ id: 'supported_types' })}: ${allowedFormats.join(', ')}. ${intl.formatMessage({ id: 'max_size' })}: ${MAX_FILE_SIZE_MB}`;

    const checkFileToAdd = (files: FileWithPath[]) => {
        const addedFileNames = new Set(
            addedItems.map((item) => (item.data as FileWithPath).name)
        );

        return files.filter((file) => {
            const isNotDeeplyNested = (file.path?.split('/').length ?? 0) < 5; // (less than 5 levels deep)
            const isNotAdded = !addedFileNames.has(file.name);
            return isNotDeeplyNested && isNotAdded;
        });
    };

    const onDropAccepted = (files: FileWithPath[]) => {
        const filtered = checkFileToAdd(files);
        onDropFiles(filtered);
    };

    const onDropRejected = (files: FileRejection[]) => {
        const rejected = files.map((file) => file.file);
        handleRejectedFiles(rejected);
    };

    const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
        accept: acceptedFormats,
        onDropAccepted,
        onDropRejected,
        disabled,
        noClick: true,
        maxSize: MAX_FILE_SIZE,
        minSize: MIN_FILE_SIZE,
    });

    const style = useMemo(
        () => ({
            ...(isDragActive ? { border: '2px dashed #addc26' } : {}),
        }),
        [isDragActive]
    );

    return (
        <div
            className={`${styles.cgDropArea} animate__animated animate__fadeIn animate__faster`}
            {...getRootProps({ style })}
        >
            <input {...getInputProps()} />

            <div
                className={classNames(styles.cgDropArea__container, {
                    [styles['cgDropArea__container--dragging']]: isDragActive,
                })}
            >
                <div className={styles.cgDropArea__selector}>
                    <UploadCloud />

                    {isDragActive ? (
                        <p className={styles.cgDropArea__text}>
                            {intl.formatMessage({ id: 'drop_files' })}
                        </p>
                    ) : (
                        <div>
                            {intl.formatMessage({
                                id: 'drag_and_drop_files_or',
                            })}
                            <span className={styles['cgDropArea__text--link']}>
                                {' '}
                                <u onClick={open}>
                                    {intl.formatMessage({ id: 'browse' })}
                                </u>
                            </span>
                        </div>
                    )}
                </div>
                <small className={styles.cgDropArea__infoFiles}>
                    {supportedTypesMessage}
                </small>
            </div>
            {children}
        </div>
    );
};

export default DropArea;
