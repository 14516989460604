import ReactDOM from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/react';

// check if Sentry variables are set, if they are ---> initialize Sentry
export const isSentryEnabled =
    import.meta.env.VITE_APP_SENTRY_DSN &&
    import.meta.env.VITE_APP_SENTRY_ENVIRONMENT;

if (isSentryEnabled)
    Sentry.init({
        dsn: import.meta.env.VITE_APP_SENTRY_DSN,
        environment: import.meta.env.VITE_APP_SENTRY_ENVIRONMENT,

        integrations: [
            new Sentry.BrowserTracing({}),
            Sentry.replayIntegration({
                maskAllText: true,
                blockAllMedia: true,
            }),
        ],

        tracePropagationTargets: ['localhost', /\.cogny\.so$/],

        // Performance Monitoring
        tracesSampleRate: 0.1, //  Capture 10% of the transactions
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });

const root = ReactDOM.createRoot(document.getElementById('root')!);

root.render(<App />);

serviceWorker.unregister();
