import { Accordion, BaseModal, Button } from '~/components/UIElements';
import './ProjectsMenu.scss';
import { LogOut, Plus, Settings, X } from 'react-feather';
import { CognyIcon } from '~/assets/icons';
import { IntlShape } from 'react-intl';
import React, { useCallback, useMemo, useState } from 'react';
import { useEntityContext, useFloatMenu, useRecentProjects } from '~/hooks';
import {
    AuthContextInterface,
    ProjectProviderInterface,
} from '~/interfaces/contexts';
import { ProjectContext } from '~/context/project';
import ProjectRow from '~/components/Layout/Topbar/projects/ProjectRow';
import {
    ProjectInterface,
    ProjectInterfaceDetailed,
} from '~/modules/Projects/types/projects.interface';
import Skeleton from 'react-loading-skeleton';
import RestrictedUI from '~/components/RestrictedUI';
import { ENTITIES } from '~/constants/entities';
import { PERMISSIONS } from '~/constants/memberPermissions';
import { compareStringsAlphabetically } from '~/utils/orderStringAlphabetically';
import CreateProjectDialog from '~/components/CreateProjectDialog';
import { AuthContext } from '~/context/auth';
import useWorkspaces from '~/modules/Workspaces/hooks/useWorkspaces';
import useWorkspacesStore from '~/modules/Workspaces/stores';

interface ProjectsMenuProps {
    intl: IntlShape;
    handleClose: () => void;
    onOpenSettings: () => void;
}

const mainClass = 'cg-projects-menu-sidebar';

const ProjectsMenu: React.FC<ProjectsMenuProps> = ({
    intl,
    handleClose,
    onOpenSettings,
}) => {
    const {
        getProjects,
        allProjects: projects,
        handleSelectedProject,
        selectedProject,
        loading,
    } = useEntityContext<ProjectProviderInterface>(ProjectContext);

    const { selectedWorkspace } = useWorkspaces();
    const workspaces = useWorkspacesStore((state) => state.workspaces);

    const { user, logout } =
        useEntityContext<AuthContextInterface>(AuthContext);

    const { recentProjects } = useRecentProjects();

    const createProjectMenu = useFloatMenu();

    const [expandedWorkspaces, setExpandedWorkspaces] = useState<string[]>([
        selectedWorkspace?.id as string,
    ]);

    const projectsByWorkspaces = useMemo(() => {
        if (!projects.length) return [];

        // sort workspaces alphabetically
        const sortedWs = workspaces.sort((a, b) =>
            compareStringsAlphabetically(a?.name, b?.name)
        );

        const projectsWithWs = sortedWs.map((ws) => ({
            ...ws,
            projects: projects.filter((p) => p.workspace_id === ws.id),
        }));

        return projectsWithWs;
    }, [projects]);

    const userIsManager = useCallback(
        (project: ProjectInterfaceDetailed) => {
            const isManager = project.details.administrators.some(
                (admin) => admin.id === user?.id
            );

            return isManager;
        },
        [user]
    );

    const onCreateProject = async (project: ProjectInterface) => {
        handleClose();
        handleSelectedProject(project);
        getProjects();
    };

    const recentProjectsArray = useMemo(() => {
        const maped: ProjectInterfaceDetailed[] = [];

        recentProjects.forEach((rp) => {
            const project = projects.find((p) => p.id === rp);
            if (project) maped.push(project);
        });

        return maped;
    }, [recentProjects, projects]);

    const onSelectProject = (project: ProjectInterfaceDetailed) => {
        handleSelectedProject(project);
        handleClose();
    };

    // the user should be allowed to create project if at least one workspaces allowed it
    const userCanCreateProjects = useMemo(
        () => workspaces.some((ws) => ws.details.user_membership),
        []
    );

    const handleExpandedWs = (wsId: string) => {
        let prev = [...expandedWorkspaces];

        if (expandedWorkspaces.includes(wsId)) {
            prev = prev.filter((id) => id !== wsId);
        } else {
            prev.push(wsId);
        }

        setExpandedWorkspaces(prev);
    };

    return (
        <BaseModal
            handleClose={handleClose}
            size="small"
            fromLeft
            noPadding
            noOverflow
        >
            {createProjectMenu.showMenu && (
                <CreateProjectDialog
                    onClose={createProjectMenu.handleMenu}
                    intl={intl}
                    onCreate={onCreateProject}
                />
            )}

            <div className={mainClass}>
                <div className={mainClass + '__upper'}>
                    <div className={mainClass + '__header'}>
                        <X onClick={handleClose} /> <CognyIcon />
                    </div>
                    <div className={mainClass + '__body'}>
                        {userCanCreateProjects && (
                            <div className={mainClass + '__new'}>
                                <Button
                                    iconBefore={Plus}
                                    onClick={createProjectMenu.handleMenu}
                                    variant="accent"
                                >
                                    {intl.formatMessage({
                                        id: 'create_new_project',
                                    })}
                                </Button>
                            </div>
                        )}

                        {loading ? (
                            <div className={mainClass + '__loading'}>
                                <Skeleton count={6} />
                            </div>
                        ) : (
                            <>
                                {recentProjectsArray.length > 0 && (
                                    <div className={mainClass + '__recents'}>
                                        <h5>
                                            {intl.formatMessage({
                                                id: 'recents',
                                            })}
                                        </h5>
                                        <div>
                                            {recentProjectsArray.map(
                                                (p, idx) => (
                                                    <ProjectRow
                                                        key={idx}
                                                        project={p}
                                                        onSelectProject={
                                                            onSelectProject
                                                        }
                                                        intl={intl}
                                                        onUpdateProject={
                                                            getProjects
                                                        }
                                                        canDelete={userIsManager(
                                                            p
                                                        )}
                                                        isSelected={false}
                                                    />
                                                )
                                            )}
                                        </div>
                                    </div>
                                )}
                                <div className={mainClass + '__list'}>
                                    {projectsByWorkspaces.map((ws) => (
                                        <Accordion
                                            key={ws.id}
                                            title={ws.name}
                                            iconPosition="right"
                                            expanded={expandedWorkspaces.includes(
                                                ws.id
                                            )}
                                            onExpand={() =>
                                                handleExpandedWs(ws.id)
                                            }
                                        >
                                            {ws.projects.map((p) => (
                                                <ProjectRow
                                                    key={p.id}
                                                    project={p}
                                                    onSelectProject={
                                                        onSelectProject
                                                    }
                                                    intl={intl}
                                                    onUpdateProject={
                                                        getProjects
                                                    }
                                                    canDelete={userIsManager(p)}
                                                    isSelected={
                                                        p.id ===
                                                        selectedProject?.id
                                                    }
                                                />
                                            ))}
                                            {ws.projects.length === 0 && (
                                                <span>
                                                    {intl.formatMessage({
                                                        id: 'no_projects_in_ws',
                                                    })}
                                                    .
                                                </span>
                                            )}
                                        </Accordion>
                                    ))}
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div className={mainClass + '__lower'}>
                    <RestrictedUI
                        entity={ENTITIES.WORKSPACES}
                        to={PERMISSIONS.EDIT}
                    >
                        <Button
                            iconBefore={Settings}
                            onClick={onOpenSettings}
                            variant="tertiary"
                        >
                            {intl.formatMessage({ id: 'workspace_settings' })}
                        </Button>
                    </RestrictedUI>
                    <Button
                        iconBefore={LogOut}
                        onClick={logout}
                        variant="tertiary"
                    >
                        {intl.formatMessage({ id: 'logout' })}
                    </Button>
                </div>
            </div>
        </BaseModal>
    );
};

export default ProjectsMenu;
