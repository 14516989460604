import { useMemo } from 'react';
import { BookOpen, Plus, Upload } from 'react-feather';
import { IntlShape } from 'react-intl';
import { Popover } from 'react-tiny-popover';
import { BasicMenuList, Button } from '~/components/UIElements';
import { ProjectContext } from '~/context/project';
import analytics, { EVENT_NAMES_ENUM } from '~/helpers/analytics';
import { useEntityContext, useFloatMenu } from '~/hooks';
import { ProjectProviderInterface } from '~/interfaces/contexts';

interface AddFilesButtonProps {
    intl: IntlShape;
    openImportModal: (value: boolean) => void;
    openLibraryModal: () => void;
}

const AddFilesButton: React.FC<AddFilesButtonProps> = ({
    intl,
    openImportModal,
    openLibraryModal,
}) => {
    const addFilesMenu = useFloatMenu();
    const { hasProjects } =
        useEntityContext<ProjectProviderInterface>(ProjectContext);

    const ADD_FILES_MENUS = useMemo(
        () => [
            {
                label: intl.formatMessage({ id: 'public_documents_library' }),
                onClick: () => {
                    openLibraryModal();
                    addFilesMenu.handleMenu();
                    analytics.trackGenericEvent(
                        EVENT_NAMES_ENUM.OPEN_PUBLIC_LIBRARY
                    );
                },
                icon: BookOpen,
            },
            {
                label: intl.formatMessage({ id: 'import_from_device_or_web' }),
                onClick: () => {
                    openImportModal(true);
                    addFilesMenu.handleMenu();
                },
                icon: Upload,
            },
        ],
        [addFilesMenu]
    );
    return (
        <Popover
            isOpen={addFilesMenu.showMenu}
            positions={['bottom', 'left', 'right']}
            align="start"
            onClickOutside={() => {
                addFilesMenu.handleMenu();
            }}
            content={
                <BasicMenuList
                    legend={intl.formatMessage({
                        id: 'add_from',
                    })}
                    menus={ADD_FILES_MENUS}
                />
            }
        >
            <Button
                onClick={addFilesMenu.handleMenu}
                variant="secondary"
                iconBefore={Plus}
                disabled={!hasProjects}
            >
                {intl.formatMessage({ id: 'add_files' })}
            </Button>
        </Popover>
    );
};

export default AddFilesButton;
