import './WorkspaceDisplay.scss';
import { useEntityContext, useFloatMenu } from '../../../../hooks';
import Skeleton from 'react-loading-skeleton';
import { Menu, Plus } from 'react-feather';
import SettingsModal from './SettingsModal';
import { ProjectProviderInterface } from '~/interfaces/contexts';
import { IntlShape } from 'react-intl';
import ProjectsMenu from '~/components/Layout/Topbar/projects/ProjectsMenu';
import { ProjectContext } from '~/context/project';
import { BaseModal, BasicFieldForm } from '~/components/UIElements';
import { projectsServices } from '~/services';
import toast from 'react-hot-toast';
import { getErrorCode } from '~/utils/getErrorCode';
import { useState } from 'react';
import analytics, { EVENT_NAMES_ENUM } from '~/helpers/analytics';
import useWorkspaces from '~/modules/Workspaces/hooks/useWorkspaces';
import useWorkspacesStore from '~/modules/Workspaces/stores';

const mainClass = 'cg-ws-switcher';

const WorkspaceDisplay = ({ intl }: { intl: IntlShape }) => {
    const settingsMenu = useFloatMenu();
    const projectsMenu = useFloatMenu();
    const newProjectMenu = useFloatMenu();

    const [loading, setLoading] = useState<boolean>(false);

    const { selectedWorkspace } = useWorkspaces();
    const loadingWs = useWorkspacesStore((state) => state.loading);
    const getWorkspaces = useWorkspacesStore((state) => state.fetchWorkspaces);

    const {
        selectedProject,
        hasProjects,
        handleSelectedProject,
        getProjects,
        loading: loadingProjects,
    } = useEntityContext<ProjectProviderInterface>(ProjectContext);

    const onCreateProject = async (projectName: string) => {
        setLoading(true);

        const [error, response] = await projectsServices.createProject({
            name: projectName,
            workspace_id: selectedWorkspace?.id as string,
        });

        setLoading(false);

        if (error) {
            toast.error(
                intl.formatMessage({
                    id: getErrorCode(response.error_code),
                })
            );
            return;
        }

        analytics.trackGenericEvent(EVENT_NAMES_ENUM.CREATE_PROJECT);

        toast.success(
            intl.formatMessage({ id: 'project_created_successfully' })
        );
        newProjectMenu.handleMenu();
        handleSelectedProject(response.data);
        getProjects();
    };

    return (
        <div className={mainClass}>
            {newProjectMenu.showMenu && (
                <BaseModal
                    size="small"
                    handleClose={newProjectMenu.handleMenu}
                    showCloseIcon
                >
                    <BasicFieldForm
                        intl={intl}
                        onSubmit={onCreateProject}
                        title={intl.formatMessage({ id: 'create_project' })}
                        confirmText={intl.formatMessage({ id: 'create' })}
                        placeholder={intl.formatMessage({ id: 'project_name' })}
                        loading={loading}
                    />
                </BaseModal>
            )}

            {settingsMenu.showMenu && (
                <SettingsModal
                    handleClose={settingsMenu.handleMenu}
                    intl={intl}
                    onUpdate={getWorkspaces}
                />
            )}

            {projectsMenu.showMenu && (
                <ProjectsMenu
                    intl={intl}
                    handleClose={projectsMenu.handleMenu}
                    onOpenSettings={settingsMenu.handleMenu}
                />
            )}

            {loadingWs || loadingProjects ? (
                <div className={mainClass + '__skeleton'}>
                    <Skeleton />
                </div>
            ) : (
                <>
                    <Menu onClick={projectsMenu.handleMenu} />
                    <div className={mainClass + '__title'}>
                        <span>{selectedWorkspace?.name}</span>

                        {hasProjects ? (
                            <h4 title={selectedProject?.name}>
                                {selectedProject?.name}
                            </h4>
                        ) : (
                            <>
                                {selectedWorkspace?.details.user_membership && (
                                    <h5 onClick={newProjectMenu.handleMenu}>
                                        {intl.formatMessage({
                                            id: 'create_a_project',
                                        })}
                                        <Plus />
                                    </h5>
                                )}
                            </>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default WorkspaceDisplay;
