import {
    CopyProjectDTO,
    ProjectToDuplicate,
} from '../types/projects.interface';

const convertProjectUpsertPayload = (
    project: ProjectToDuplicate
): CopyProjectDTO => {
    return {
        name: project.name,
        copy_existing_permissions: project.copyPermissions,
        copy_comment_dynamic_properties: project.copyCommentTable,
        copy_document_dynamic_properties: project.copyDocumentTable,
        copy_tags: project.copyTags,
        copy_saved_searches: project.copySavedSearches,
        copy_folders: project.copyFolders,
        copy_documents: project.copyDocuments,
        copy_topics: project.copyTopics,
    };
};

export default convertProjectUpsertPayload;
