import {
    PropsWithChildren,
    createContext,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useEntityContext, useQueueUpload, useTable } from '../../hooks';
import { importServices } from '../../services';
import { ProjectContext } from '../project';
import {
    AuthContextInterface,
    ProjectProviderInterface,
} from '~/interfaces/contexts';
import { UploadProviderInterface } from '~/interfaces/contexts/UploadContext.interface';
import { FileToImportInterface } from '~/modules/Import/types/importFiles';
import { onFinishImportInterface } from '~/hooks/useQueueUpload';
import {
    ImportInterface,
    ImportInterfaceDetailed,
} from '~/interfaces/entities';
import toast from 'react-hot-toast';
import { getErrorCode } from '~/utils/getErrorCode';
import { IMPORT_STATUSES } from '~/constants/ImportStatuses.enum';
import { useIntl } from 'react-intl';
import { AuthContext } from '~/context/auth';
import { ImportHistoryViews } from '~/constants/importFiles';

export const UploadContext = createContext<UploadProviderInterface | null>(
    null
);

export interface ImportDataInterface {
    filesToUpload: FileToImportInterface[];
    importId: string;
    status: string;
    addedToQueue: boolean;
}

const MAX_CONCURRENT_UPLOADING = 15;

const UploadProvider = ({ children }: PropsWithChildren) => {
    const intl = useIntl();

    const intervalRef = useRef<NodeJS.Timeout | null>(null);

    const { selectedProject } =
        useEntityContext<ProjectProviderInterface>(ProjectContext);

    const { user } = useEntityContext<AuthContextInterface>(AuthContext);

    const [showProgressMenu, setShowProgressMenu] = useState(false);
    const [runningImport, setRunningImport] =
        useState<ImportInterfaceDetailed | null>(null);

    const [showImportHistoryModal, setShowImportHistoryModal] = useState(false);
    const [historyModalView, setHistoryModalView] = useState(
        ImportHistoryViews.IMPORT_HISTORY
    );
    const [loading, setLoading] = useState(false);

    // store how many files are being uploaded. It is the stage between an import is pending and started
    const [uploadingFilesCount, setUploadingFilesCount] = useState(0);

    // selected import to show the details
    const [selectedImport, setSelectedImport] =
        useState<ImportInterfaceDetailed | null>(null);

    const [imports, setImports] = useState<ImportInterfaceDetailed[]>([]);

    const userHasRunningImport = useCallback(
        (i: ImportInterface) =>
            i.owner_id === user?.id &&
            [IMPORT_STATUSES.STARTED, IMPORT_STATUSES.PENDING].includes(
                i.status
            ),
        [user]
    );

    useEffect(() => {
        if (!selectedProject) return;

        // check if some import is running to display the progress menu
        getImports();
    }, [selectedProject?.id]);

    useEffect(() => {
        if (!imports.length) return;
        // Clear the existing interval if it exists
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }

        intervalRef.current = setInterval(() => {
            // check if some import is pending
            const existPendingImports = imports.some((imp) =>
                userHasRunningImport(imp)
            );

            if (existPendingImports) {
                getImports(selectedImport?.id);
            } else {
                clearInterval(intervalRef.current);
            }
        }, 10000);
    }, [imports]);

    const { pageSize, pageNumber, totalPages, onPageChange, setTotalItems } =
        useTable({});

    useEffect(() => {
        if (pageNumber > totalPages || pageNumber === 0) return;
        getMoreImports();
    }, [pageNumber]);

    const getImports = async (
        preselectedImportId?: string,
        runningImportId?: string
    ) => {
        const [error, response] = await importServices.getImports({
            project_ids: selectedProject?.id,
            page_size: pageSize,
            page_number: 0,
        });

        if (error) {
            if (intervalRef.current) clearInterval(intervalRef.current);
            if (runningImport) setRunningImport(null);
            setShowProgressMenu(false);
            return toast.error(
                intl.formatMessage({ id: getErrorCode(response.error_code) })
            );
        }
        setTotalItems(response.paging.total);
        setImports(response.data);

        // when the page intializes, check if the user has a running import
        const userImport = response.data.find((i: ImportInterface) =>
            userHasRunningImport(i)
        );

        if (userImport) {
            setShowProgressMenu(true);
            setRunningImport(userImport);
            if (uploadingFilesCount) setUploadingFilesCount(0);
        }

        // when importing only one file or multiple light files, the import is set as finished quickly so the running import keeps as pending
        if (runningImportId && !userImport) {
            const runningFinishedImport = response.data.find(
                (imp) => imp.id === runningImportId
            );

            setRunningImport(runningFinishedImport || null);
        }

        // if exist running import but not userImport, means that the import has finished
        if (runningImport && !userImport) {
            const finishedImport = response.data.find(
                (imp) => imp.id === runningImport.id
            );
            setRunningImport(finishedImport || null);

            // if finished import doesn't exist, it means that the project has changed
            if (!finishedImport) setShowProgressMenu(false);
        }

        const isSameImport = preselectedImportId === selectedImport?.id;

        if (isSameImport) {
            const updatedImport = response.data.find(
                (imp) => imp.id === selectedImport?.id
            );
            setSelectedImport(updatedImport || null);
        }
    };

    const getMoreImports = async () => {
        setLoading(true);

        const [error, response] = await importServices.getImports({
            project_ids: selectedProject?.id,
            page: pageNumber,
            page_size: pageSize,
        });

        if (error) {
            setLoading(false);
            return;
        }
        setLoading(false);

        setImports([...imports, ...response.data]);
    };

    const onFileUpload = () => {
        setUploadingFilesCount((prev) => {
            if (prev === 1) return prev;
            return prev - 1;
        });
    };

    /**
     * When all the files have been uploaded (or at least one did not fail to upload), launch the import
     */
    const onUploadEnd = async ({
        importId,
        totalFileCount,
        failedFileCount,
        addedToQueue,
    }: onFinishImportInterface) => {
        if (totalFileCount === failedFileCount) {
            toast.error(
                intl.formatMessage({
                    id: 'import_has_failed_all_uploads',
                })
            );
            return;
        }

        if (failedFileCount > 0) {
            toast.error(
                intl.formatMessage({
                    id: 'import_has_failed_some_uploads',
                })
            );
        }

        if (addedToQueue) return; // no need to launch the import if true

        const [error, response] = await importServices.launchImport(importId);

        // if import cannot be started for some reason (could be internet connection), abort the import
        if (error) {
            toast.error(
                intl.formatMessage({
                    id: getErrorCode(response.error_code),
                })
            );

            setShowProgressMenu(false);
            setRunningImport(null);

            const [abortError] = await importServices.abortImport(importId);

            if (!abortError) {
                toast.error(
                    intl.formatMessage({
                        id: 'import_canceled',
                    })
                );
            }
            return;
        }

        getImports(undefined, importId);
    };

    const { startUpload } = useQueueUpload({
        maxFiles: MAX_CONCURRENT_UPLOADING,
        onFinish: onUploadEnd,
        onFileUpload,
    });

    const startImport = async (items: FileToImportInterface[]) => {
        if (showProgressMenu) setShowProgressMenu(false);
        if (runningImport) setRunningImport(null);
        if (selectedImport) setSelectedImport(null);

        setLoading(true);

        let importId = null;
        // check if the user has a running import
        const usersImport = await importServices.getUserRunningImport(
            user?.id as string,
            selectedProject?.id as string
        );

        if (usersImport) {
            importId = usersImport.id;
            usersImport.details.processing_in_progress_count =
                usersImport.details.processing_in_progress_count + items.length;

            setRunningImport(usersImport);
        } else {
            const [error, response] = await importServices.createImport(
                selectedProject?.id as string
            );

            setUploadingFilesCount(items.length);
            if (error) {
                toast.error(
                    intl.formatMessage({
                        id: getErrorCode(response.error_code),
                    })
                );
            } else {
                importId = response.data.id;
            }
        }

        setLoading(false);

        if (importId) {
            const importData: ImportDataInterface = {
                filesToUpload: items,
                importId: importId,
                status: 'pending',
                addedToQueue: Boolean(usersImport), // this flag is to avoid creating a new import if there is already one running
            };

            startUpload(importData);
            setShowProgressMenu(true);
        }

        return {
            error: Boolean(!importId),
            addedToQueue: Boolean(usersImport),
        };
    };

    const handleSelectedImport = (imp: ImportInterfaceDetailed) => {
        setSelectedImport(imp);
        if (!imp) return;
        getImports(imp.id);

        setShowImportHistoryModal(true);
        setHistoryModalView(ImportHistoryViews.IMPORT_STATUS);
    };

    const handleImportHistoryModal = () => {
        setShowImportHistoryModal(!showImportHistoryModal);
        setHistoryModalView(ImportHistoryViews.IMPORT_HISTORY);
    };

    return (
        <UploadContext.Provider
            value={{
                loading,
                showImportHistoryModal,
                showProgressMenu,
                selectedImport,
                runningImport,
                imports,
                historyModalView,
                uploadingFilesCount,
                handleHistoryModalView: setHistoryModalView,
                getImports,
                startImport,
                handleSelectedImport,
                handleImportHistoryModal,
                handleRunningImport: setRunningImport,
                handleProgressMenu: setShowProgressMenu,
                getMoreImports,
                handleLoading: setLoading,
                increasePage: () => !loading && onPageChange(pageNumber + 1),
            }}
        >
            {children}
        </UploadContext.Provider>
    );
};

export default UploadProvider;
