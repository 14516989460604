import { useRoutes } from 'react-router-dom';
import PublicRoutes from './PublicRoutes';
import PrivateRoutes from './PrivateRoutes';
import LinkRoutes from './LinkRoutes';
import { PUBLIC_ROUTES } from './paths';
import SentryErrorPage from '~/components/SentryErrorPage';
import useEntityContext from '~/hooks/useEntityContext';
import { AuthContextInterface } from '~/interfaces/contexts';
import { AuthContext } from '~/context/auth';
import { lazyLoadRoutes } from './LazyLoadRoutes';

const AccountValidation = lazyLoadRoutes('AccountValidation');

export default function Router() {
    const { isAuth } = useEntityContext<AuthContextInterface>(AuthContext);

    // with this approach we avoid redirecting manually the user
    const routesOnAuth = isAuth
        ? [...PrivateRoutes, ...LinkRoutes]
        : PublicRoutes;

    const routing = useRoutes([
        ...routesOnAuth,

        {
            path: PUBLIC_ROUTES.validate_account,
            element: AccountValidation,
        },

        // The pourpose of the following route is to trigger an intentional error to test Sentry error tracking
        {
            path: PUBLIC_ROUTES.trigger_error,
            element: <SentryErrorPage />,
        },
    ]);

    return routing;
}
