import { useMemo, useState, FormEvent } from 'react';
import { useIntl } from 'react-intl';
import toast from 'react-hot-toast';
import { ChevronDown } from 'react-feather';
import { ProjectContext } from '~/context/project';
import { TagsContext } from '~/context/tags';
import {
    useEntityContext,
    useFloatMenu,
    useTemporaryErrorDisplay,
    useOpenFolders,
} from '~/hooks';
import { tagsServices } from '~/services';
import { getErrorCode } from '~/utils/getErrorCode';
import { ProjectProviderInterface } from '~/interfaces/contexts';
import { TagsProviderInterface } from '~/modules/Tags/types/TagsContext.interface';
import { UNCATEGORIZED_FOLDER_NAME } from '~/constants/folders';
import { BaseModal, Button, FolderSelector, TextField } from '../../UIElements';

import './CreateTagDialog.scss';

const mainClass = 'create-tag-dialog';

const initialValues = {
    name: '',
    tag_group_id: '',
};

interface CreateTagDialogProps {
    handleClose: () => void;
    callback: () => void;
}

const CreateTagDialog = ({ handleClose, callback }: CreateTagDialogProps) => {
    const intl = useIntl();
    const { selectedProject } =
        useEntityContext<ProjectProviderInterface>(ProjectContext);
    const { groupedTags: folderStructure } =
        useEntityContext<TagsProviderInterface>(TagsContext);

    const [tag, setTag] = useState(initialValues);
    const [loading, setLoading] = useState(false);
    const { error, handleError } = useTemporaryErrorDisplay();
    const { openedFolders, openFolder } = useOpenFolders();

    const selectorModal = useFloatMenu();

    const handleSubmit = async (e: FormEvent<HTMLButtonElement>) => {
        e.preventDefault();

        if (!tag.name?.trim())
            return handleError(intl.formatMessage({ id: 'required_field' }));
        setLoading(true);

        const groupId =
            !tag.tag_group_id || tag.tag_group_id === UNCATEGORIZED_FOLDER_NAME
                ? null
                : tag.tag_group_id;

        if (!selectedProject) return;

        const [error, response] = await tagsServices.createTag({
            name: tag.name,
            tag_group_id: groupId!,
            project_id: selectedProject.id,
        });

        setLoading(false);

        if (error)
            return toast.error(
                intl.formatMessage({ id: getErrorCode(response.error_code) })
            );

        toast.success(intl.formatMessage({ id: 'tag_created_successfully' }));

        handleClose();
        callback();
    };

    const folderPath = useMemo(
        () =>
            folderStructure.find((f) => f.id === tag.tag_group_id)?.name || '',
        [folderStructure, tag.tag_group_id]
    );

    return (
        <BaseModal handleClose={handleClose} showCloseIcon>
            <div className={`${mainClass}`}>
                <h4>{intl.formatMessage({ id: 'create_tag' })} </h4>
                <form onSubmit={handleSubmit}>
                    <TextField
                        label={intl.formatMessage({ id: 'tag_name' })}
                        inputProps={{
                            onChange: (e) =>
                                setTag({
                                    ...tag,
                                    name: e.target.value,
                                }),
                            value: tag.name,
                            autoFocus: true,
                            placeholder: intl.formatMessage({ id: 'tag_name' }),
                        }}
                        error={error && intl.formatMessage({ id: error })}
                    />

                    <TextField
                        inputProps={{
                            onChange: () => {},
                            value: folderPath,
                            onClick: selectorModal.handleMenu,
                            placeholder: intl.formatMessage({ id: 'root' }),
                        }}
                        label={intl.formatMessage({ id: 'tag_location' })}
                        iconAfter={ChevronDown}
                    />

                    <Button type="submit" disabled={loading} variant="accent">
                        {intl.formatMessage({ id: 'create' })}
                    </Button>
                </form>

                {selectorModal.showMenu && (
                    <BaseModal
                        showCloseIcon
                        handleClose={selectorModal.handleMenu}
                        noPadding
                    >
                        <FolderSelector
                            structure={folderStructure}
                            value={tag.tag_group_id}
                            onConfirm={(folderId) => {
                                setTag({ ...tag, tag_group_id: folderId });
                                selectorModal.handleMenu();
                            }}
                            onCancel={selectorModal.handleMenu}
                            expandedFolders={openedFolders}
                            onExpandFolder={openFolder}
                            placeholder={intl.formatMessage({
                                id: 'search_folders_and_tags',
                            })}
                            confirmButtonText={intl.formatMessage({
                                id: 'confirm_location',
                            })}
                            showUncategorizedFolder
                        />
                    </BaseModal>
                )}
            </div>
        </BaseModal>
    );
};

export default CreateTagDialog;
