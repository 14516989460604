import React, { useEffect, useMemo, useState } from 'react';
import './SettingsModal.scss';
import {
    BaseModal,
    BasicMenuList,
    Button,
    IconPill,
    Spinner,
    SwitchField,
    TextAreaField,
    TextField,
} from '~/components/UIElements';
import { PillVariants } from '~/components/UIElements/Pill/constants';
import { workspaceServices } from '~/services';
import toast from 'react-hot-toast';
import WorkspaceMembers from './WorkspaceMembers';
import { IntlShape } from 'react-intl';
import { ArrowLeft, ChevronDown, ToggleLeft, User } from 'react-feather';
import { Popover } from 'react-tiny-popover';
import { useFloatMenu } from '~/hooks';
import CaretBoxIcon from '~/assets/icons/shared/CaretBoxIcon';
import { WORKSPACE_MEMBER_ROLES } from '~/constants/memberRoles';
import useWorkspaces from '~/modules/Workspaces/hooks/useWorkspaces';
import useWorkspacesStore from '~/modules/Workspaces/stores';

const mainClass = 'cg-settings-modal';

interface SettingsModalInterface {
    intl: IntlShape;
    handleClose: () => void;
    onUpdate: () => void;
}

const SettingsModal: React.FC<SettingsModalInterface> = ({
    intl,
    handleClose,
    onUpdate,
}) => {
    const { selectedWorkspace, getUserRoleInWs } = useWorkspaces();

    const workspaces = useWorkspacesStore((state) => state.workspaces);
    const handleSelectedWorkspace = useWorkspacesStore(
        (state) => state.handleSelectedWorkspace
    );

    const [loading, setLoading] = useState(false);
    const selectorMenu = useFloatMenu();

    const [settings, setSettings] = useState({
        name: '',
        allowWebImport: false,
        message: '',
    });

    useEffect(() => {
        if (!selectedWorkspace) return;

        setSettings({
            name: selectedWorkspace.name,
            allowWebImport:
                selectedWorkspace.details.configuration.allow_url_import,
            message: selectedWorkspace.details.configuration.import_message,
        });
    }, [selectedWorkspace]);

    const onChange = (value, name) => {
        setSettings({
            ...settings,
            [name]: value,
        });
    };

    const dataHasChanged = useMemo(() => {
        if (!selectedWorkspace) return {};

        const {
            name,
            details: {
                configuration: { allow_url_import, import_message },
            },
        } = selectedWorkspace;

        return {
            name: name !== settings.name,
            allowWebImport: allow_url_import !== settings.allowWebImport,
            message: import_message !== settings.message,
        };
    }, [settings, selectedWorkspace]);

    const onSaveName = async () => {
        setLoading(true);

        const [error] = await workspaceServices.updateWorkspace(
            selectedWorkspace?.id as string,
            {
                name: settings.name,
            }
        );

        onFinishUpdate(error);
    };

    const onSaveCheckbox = async (checked: boolean) => {
        setLoading(true);

        const [error] = await workspaceServices.updateWorkspaceSettings(
            selectedWorkspace?.details.configuration.id,
            {
                allow_url_import: checked,
            }
        );

        onFinishUpdate(error);
    };

    const onSaveMessage = async () => {
        setLoading(true);

        const [error] = await workspaceServices.updateWorkspaceSettings(
            selectedWorkspace.details.configuration.id,
            {
                import_message: settings.message || null,
            }
        );

        onFinishUpdate(error);
    };

    const onFinishUpdate = (error) => {
        setLoading(false);

        if (error)
            return toast.error(intl.formatMessage({ id: 'general_error' }));

        toast.success(
            intl.formatMessage({ id: 'workspace_updated_successfully' })
        );

        onUpdate();
    };

    const WS_OPTIONS = useMemo(() => {
        const filtered = workspaces.filter((ws) =>
            [
                WORKSPACE_MEMBER_ROLES.OWNER,
                WORKSPACE_MEMBER_ROLES.ADMIN,
            ].includes(getUserRoleInWs(ws))
        );

        const options = filtered.map((ws) => ({
            label: ws.name,
            onClick: () => {
                handleSelectedWorkspace(ws.id);
                selectorMenu.setShowMenu(false);
            },
            value: ws.id,
        }));

        return options;
    }, [workspaces]);

    return (
        <BaseModal handleClose={handleClose} fromLeft noPadding>
            <div className={mainClass}>
                <div className={mainClass + '__header'}>
                    <Popover
                        isOpen={selectorMenu.showMenu}
                        positions={['bottom']}
                        align={'start'}
                        content={<BasicMenuList menus={WS_OPTIONS} />}
                        onClickOutside={selectorMenu.handleMenu}
                    >
                        <div className={mainClass + '__header__content'}>
                            <ArrowLeft onClick={handleClose} />
                            <div onClick={selectorMenu.handleMenu}>
                                <h4>{selectedWorkspace?.name}</h4>{' '}
                                <ChevronDown />
                            </div>
                        </div>
                    </Popover>

                    {loading && <Spinner width={20} height={20} weight="2" />}
                </div>

                <div className={mainClass + '__separator'}></div>

                <div className={mainClass + '__field'}>
                    <IconPill
                        icon={CaretBoxIcon}
                        variant={PillVariants.AMETHYST}
                    />
                    <div className={mainClass + '__field__form'}>
                        <TextField
                            label={intl.formatMessage({ id: 'workspace_name' })}
                            inputProps={{
                                placeholder: intl.formatMessage({
                                    id: 'workspace_name',
                                }),
                                value: settings.name,
                                onChange: (e) =>
                                    onChange(e.target.value, 'name'),
                                disabled: loading,
                            }}
                        />
                        {dataHasChanged.name && (
                            <Button
                                onClick={onSaveName}
                                className="animate__animated animate__fadeIn"
                                variant="accent"
                                disabled={loading}
                            >
                                {intl.formatMessage({ id: 'save' })}
                            </Button>
                        )}
                    </div>
                </div>

                <div className={mainClass + '__separator'}></div>

                <div className={mainClass + '__features'}>
                    <IconPill
                        icon={ToggleLeft}
                        variant={PillVariants.ELECTRIC}
                    />
                    <div className={mainClass + '__features__form'}>
                        <label>{intl.formatMessage({ id: 'features' })}</label>
                        <SwitchField
                            label={intl.formatMessage({
                                id: 'allow_web_import',
                            })}
                            inputProps={{
                                value: settings.allowWebImport,
                                onChange: (checked) => {
                                    onChange(checked, 'allowWebImport');
                                    onSaveCheckbox(checked);
                                },
                                disabled: loading,
                            }}
                        />
                    </div>
                </div>

                <div className={`${mainClass}__field ${mainClass}__message`}>
                    <TextAreaField
                        label={intl.formatMessage({
                            id: 'custom_file_import_message',
                        })}
                        inputProps={{
                            value: settings.message || '',
                            onChange: (e) =>
                                onChange(e.target.value || null, 'message'),
                            disabled: loading,
                        }}
                        // hint={intl.formatMessage({
                        //     id: 'import_message_aclaration',
                        // })}
                    />

                    <div className={mainClass + '__field__buttons'}>
                        {settings.message && (
                            <Button
                                variant="secondary"
                                onClick={() => onChange(null, 'message')}
                                disabled={loading}
                            >
                                {intl.formatMessage({
                                    id: 'clear_message',
                                })}
                            </Button>
                        )}
                        {dataHasChanged.message && (
                            <Button
                                variant="accent"
                                onClick={onSaveMessage}
                                disabled={loading}
                            >
                                {intl.formatMessage({ id: 'save' })}
                            </Button>
                        )}
                    </div>
                </div>

                <div className={mainClass + '__separator'}></div>

                <div className={`${mainClass}__field`}>
                    <IconPill icon={User} variant={PillVariants.TURQUOISE} />
                    <WorkspaceMembers intl={intl} />
                </div>
            </div>
        </BaseModal>
    );
};

export default SettingsModal;
