import { useState } from 'react';
import { useIntl } from 'react-intl';
import LinkRow from '~/modules/Import/components/ImportModal/LinkRow';
import { useTemporaryErrorDisplay } from '~/hooks';
import isValidURL from '~/utils/isValidUrl';
import { TextField } from '~/components/UIElements';
import classNames from 'classnames';

import styles from './LinksToImportList.module.scss';

interface LinksToImportListProps {
    links: string[];
    handleLinks: (links: string[]) => void;
    loading: boolean;
}

const LinksToImportsList = ({
    links,
    handleLinks,
    loading,
}: LinksToImportListProps) => {
    const intl = useIntl();
    const [newLink, setNewLink] = useState<string>('');
    const { error, handleError } = useTemporaryErrorDisplay();

    const addLink = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!isValidURL(newLink)) {
            handleError(intl.formatMessage({ id: 'please_enter_valid_url' }));
            return;
        }

        setNewLink('');
        const isAlreadyAdded = links.includes(newLink);
        if (!isAlreadyAdded) handleLinks([...links, newLink]);
    };

    const onRemoveLink = (link: string) => {
        const newLinks = links.filter((l) => l !== link);
        handleLinks(newLinks);
    };
    const onModifyLink = (value: string, index: number) => {
        const newLinks = links.map((l, i) => (i === index ? value : l));
        handleLinks(newLinks);
    };

    return (
        <div
            className={classNames(
                styles.cgLinksList,
                'animate__animated animate__fadeIn animate__faster'
            )}
        >
            {links.map((link, index) => (
                <LinkRow
                    key={link + index}
                    link={link}
                    onModify={(value: string) => onModifyLink(value, index)}
                    onRemove={() => onRemoveLink(link)}
                    disabled={loading}
                />
            ))}

            <form onSubmit={addLink}>
                <TextField
                    inputProps={{
                        value: newLink,
                        onChange: (e) => setNewLink(e.target.value.trim()),
                        placeholder: 'https://...',
                        disabled: loading,
                        autoFocus: true,
                    }}
                    hint={
                        newLink && !error
                            ? intl.formatMessage({ id: 'press_enter_to_add' })
                            : ''
                    }
                    error={error}
                />
            </form>
        </div>
    );
};

export default LinksToImportsList;
