import { Suspense, lazy, useEffect, useState } from 'react';
import './index.scss';
import Topbar from './Topbar';
import { IntlShape, injectIntl } from 'react-intl';
import { Outlet } from 'react-router-dom';
import { ReportsSidebar } from '~/modules/Reports/components';
import { ToastAlert } from '../UIElements';
import moment from 'moment';
import GlobalAccessModals from '~/components/Layout/GlobalAccessModals';
import MainSidebar from '~/components/Layout/MainSidebar';
import { SIDEBAR_TYPES } from '~/constants/sidebarViews';
import { useEntityContext, useResizableBox } from '~/hooks';
import { AuthContextInterface } from '~/interfaces/contexts';
import { TagsProviderInterface } from '~/modules/Tags/types/TagsContext.interface';
import { AnimatePresence, motion } from 'framer-motion';
import { TagsContext } from '~/context/tags';
import { AuthContext } from '~/context/auth';
import { EXISTING_STATUSES } from '~/constants/existing_statuses.enum';
import useWorkspacesStore from '~/modules/Workspaces/stores/index.tsx';
import useDocumentViewerStore from '~/modules/Documents/stores/documentViewerStore';
import useDocumentViewer from '~/modules/Documents/hooks/useDocumentViewer';

const DocumentViewer = lazy(
    () => import('~/modules/Documents/components/DocumentViewer/index.tsx')
);

const sidebars: { [key: string]: React.FC } = {
    [SIDEBAR_TYPES.TOPICS_TAGS]: MainSidebar,
    [SIDEBAR_TYPES.REPORTS]: ReportsSidebar,
};

const framerViewerPanel = {
    initial: { x: '100%' },
    animate: { x: 0 },
    exit: { x: '100%' },
    transition: { duration: 0.2 },
};

const mainClass = 'dg-layout';

interface LayoutInterface {
    intl: IntlShape;
    sidebar: string;
    includeViewer?: boolean;
}

const Layout: React.FC<LayoutInterface> = ({
    intl,
    sidebar,
    includeViewer,
}) => {
    const [showWarning, setShowWarning] = useState<{
        days: number;
        hours: number;
        closeToExpire: boolean;
    } | null>(null);

    const SidebarComponent: React.FC = sidebars[sidebar];

    const fetchWorkspaces = useWorkspacesStore(
        (state) => state.fetchWorkspaces
    );

    const { user: userData } =
        useEntityContext<AuthContextInterface>(AuthContext);

    const viewerData = useDocumentViewerStore();

    useEffect(() => {
        // initialize workspace data
        fetchWorkspaces();

        const isPendingActivation =
            userData?.existing_status === EXISTING_STATUSES.PENDING_ACTIVATION;

        if (isPendingActivation) {
            // check if the user has been created more than 14 days ago
            const targetDate = moment(userData.created_at).add(14, 'd');
            const now = moment();

            const daysLeft = targetDate.diff(now, 'days');
            const hoursLeft = targetDate.diff(now, 'hours');

            const closeToExpire = daysLeft <= 2;

            setShowWarning({
                days: daysLeft,
                hours: hoursLeft,
                closeToExpire,
            });
        }
    }, []);

    const { handleTags, tags } =
        useEntityContext<TagsProviderInterface>(TagsContext);

    const {
        viewerWidth: viewerWidthContext,
        handleViewerWidth,
        showViewer,
    } = viewerData;

    const {
        sidebarWidth: viewerWidth,
        startResizing,
        boxRef: viewerRef,
    } = useResizableBox({
        initialWidth: viewerWidthContext,
        originPlace: 'right',
        onChange: handleViewerWidth,
    });

    const onUpdateDocument = () => {
        handleTags([...tags]);
    };

    return (
        <>
            <ToastAlert position="bottom-center" />
            {/* This component wraps all the modals that are accessed and showed globally             */}
            <GlobalAccessModals intl={intl} />

            <div className={mainClass}>
                <Topbar
                    intl={intl}
                    showAccountWarning={showWarning}
                    hideWarning={() => setShowWarning(null)}
                />
                <div className={mainClass + '__wrapper'}>
                    <SidebarComponent />
                    <div className={mainClass + '__content'}>
                        <Outlet />

                        {includeViewer && (
                            <AnimatePresence mode="wait" initial={false}>
                                {showViewer && (
                                    <motion.div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                        {...framerViewerPanel}
                                        ref={viewerRef}
                                    >
                                        <div
                                            className={mainClass + '__resizer'}
                                            onMouseDown={(e) => {
                                                e.preventDefault();
                                                startResizing();
                                            }}
                                        >
                                            <span>ll</span>
                                        </div>
                                        <div
                                            className={mainClass + '__viewer'}
                                            style={{ width: viewerWidth }}
                                        >
                                            <Suspense
                                                fallback={
                                                    <div>
                                                        Loading Document
                                                        Viewer...
                                                    </div>
                                                }
                                            >
                                                <DocumentViewer
                                                    onUpdateCallback={
                                                        onUpdateDocument
                                                    }
                                                    onCreateInsightCallback={
                                                        onUpdateDocument
                                                    }
                                                    {...viewerData}
                                                />
                                            </Suspense>
                                        </div>
                                    </motion.div>
                                )}
                            </AnimatePresence>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

const ExportableLayout = injectIntl(Layout);

export default ExportableLayout;
