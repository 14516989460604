function debounce<T extends (...args: string[]) => void>(
    func: T,
    delay: number
): (...args: Parameters<T>) => void {
    let timerId: NodeJS.Timeout;

    return function (...args: Parameters<T>): void {
        clearTimeout(timerId);
        timerId = setTimeout(() => {
            func.apply(this, args);
        }, delay);
    };
}

export default debounce;
