import Skeleton from 'react-loading-skeleton';
import './ImportHistoryTable.scss';
import { getShortMonthAndDate } from '~/utils/times';
import { IMPORT_STATUSES_TRANSLATION_MAP } from '~/constants/ImportStatuses.enum';
import { useInfiniteScroll } from '~/hooks';
import { ImportInterfaceDetailed } from '~/interfaces/entities';
import { IntlShape } from 'react-intl';
import { ImportStatusTextColor } from '~/constants/importFiles';

const mainClass = 'import-history-table';

interface ImportHistoryTableInterface {
    loading: boolean;
    imports: ImportInterfaceDetailed[];
    intl: IntlShape;
    increasePage: () => void;
    onSelectImport: (importItem: ImportInterfaceDetailed) => void;
}

const ImportHistoryTable: React.FC<ImportHistoryTableInterface> = ({
    loading,
    imports,
    intl,
    increasePage,
    onSelectImport,
}) => {
    const { onScroll } = useInfiniteScroll({
        targetClassName: mainClass + '__table',
        callback: increasePage,
    });

    return (
        <div className={mainClass}>
            {loading && !imports.length ? (
                <Skeleton className={mainClass + '__loading'} count={10} />
            ) : null}

            {!loading && !imports.length ? (
                <div className={mainClass + '__empty'}>
                    {intl.formatMessage({ id: 'not_imports_yet' })}.
                </div>
            ) : null}

            <div className={mainClass + '__table'} onScroll={onScroll}>
                {imports.length > 0 && (
                    <div className={mainClass + '__table__header'}>
                        <div> {intl.formatMessage({ id: 'date' })} </div>
                        <div> {intl.formatMessage({ id: 'queued' })} </div>
                        <div>{intl.formatMessage({ id: 'imported' })}</div>
                        <div>{intl.formatMessage({ id: 'rejected' })}</div>
                        <div> {intl.formatMessage({ id: 'status' })} </div>
                    </div>
                )}
                <div className={mainClass + '__table__body'}>
                    {imports.map((item) => (
                        <div key={item.id} onClick={() => onSelectImport(item)}>
                            <div>
                                {getShortMonthAndDate(
                                    new Date(item.created_at)
                                )}
                            </div>
                            <div> {item.file_count || '-'} </div>
                            <div>
                                {item.details.processing_finished_count || '-'}
                            </div>
                            <div>
                                {item.details.processing_errors_count || '-'}
                            </div>
                            <div
                                style={{
                                    color:
                                        ImportStatusTextColor[item.status] ||
                                        '#8d9197',
                                    textTransform: 'capitalize',
                                }}
                            >
                                {intl.formatMessage({
                                    id: IMPORT_STATUSES_TRANSLATION_MAP[
                                        item.status
                                    ],
                                })}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ImportHistoryTable;
