import { FileRowInterface } from '~/components/Import/FileRow';
import { FileToImportInterface } from '~/modules/Import/types/importFiles';
import { ImportStageInterface } from '~/interfaces/entities';
import { MapFileWithStageStatus } from '~/constants/MapFileStatusWithImportStage';
import { IMPORT_STAGE_STATUSES } from '~/constants/ImportStageStasuses.enum';
import { FileMimeTypesMap } from '~/modules/Import/constants/fileTypes';

export const normalizeFilesToImport = (
    files: FileToImportInterface[]
): FileRowInterface[] => {
    return files.map((file) => ({
        id: file.id,
        name: file.data instanceof File ? file.data.name : file.data,
        type: FileMimeTypesMap[file.data.type],
    }));
};

// items already imported
export const normalizeImportedFiles = (
    files: ImportStageInterface[]
): FileRowInterface[] => {
    return files.map((file) => {
        let msg = file.error_code;

        if (file.status === IMPORT_STAGE_STATUSES.CANCELED) {
            msg = IMPORT_STAGE_STATUSES.CANCELED;
        }

        return {
            id: file.file_id,
            name: file.file.name,
            type: file.file?.type,
            status: {
                type: MapFileWithStageStatus[file.status],
                message: msg,
            },
        };
    });
};
