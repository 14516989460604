import React, { useMemo, useState } from 'react';
import './ProjectRow.scss';
import { Copy, Edit3, MoreHorizontal, Trash2 } from 'react-feather/dist';
import { Popover } from 'react-tiny-popover';
import { DeleteConfirmationDialog, FloatMenu } from '~/components/UIElements';
import { IntlShape } from 'react-intl';
import {
    ProjectInterface,
    ProjectInterfaceDetailed,
} from '~/modules/Projects/types/projects.interface';
import { useEntityContext, useFloatMenu } from '~/hooks';
import {
    DuplicateProjectDialog,
    UpdateProjectDialog,
} from '~/modules/Projects/components';
import { projectsServices } from '~/services';
import toast from 'react-hot-toast';
import { getErrorCode } from '~/utils/getErrorCode';
import { ProjectProviderInterface } from '~/interfaces/contexts';
import { ProjectContext } from '~/context/project';
import useWorkspaces from '~/modules/Workspaces/hooks/useWorkspaces';

interface ProjectRowProps {
    intl: IntlShape;
    project: ProjectInterfaceDetailed;
    canDelete: boolean;
    isSelected: boolean;
    onSelectProject: (p: ProjectInterfaceDetailed) => void;
    onUpdateProject: () => void;
}

const ProjectRow: React.FC<ProjectRowProps> = ({
    intl,
    project,
    canDelete,
    onSelectProject,
    onUpdateProject,
    isSelected,
}) => {
    const { getWorkspaceById } = useWorkspaces();

    const { handleSelectedProject, getProjects } =
        useEntityContext<ProjectProviderInterface>(ProjectContext);

    const menu = useFloatMenu();
    const deleteProjectMenu = useFloatMenu();
    const duplicateProjectMenu = useFloatMenu();

    const [loading, setLoading] = useState(false);

    const [projectToEdit, setProjectToEdit] =
        useState<ProjectInterfaceDetailed | null>(null);

    const MENUS = useMemo(
        () => [
            {
                icon: Copy,
                label: intl.formatMessage({ id: 'duplicate_structure' }),
                onClick: (e: React.MouseEvent) => {
                    e.stopPropagation();
                    duplicateProjectMenu.setShowMenu(true);
                    menu.setShowMenu(false);
                },
            },
            {
                icon: Edit3,
                label: intl.formatMessage({ id: 'rename' }),
                onClick: (e: React.MouseEvent) => {
                    e.stopPropagation();
                    setProjectToEdit(project);
                    menu.setShowMenu(false);
                },
            },
            {
                icon: Trash2,
                label: intl.formatMessage({ id: 'delete' }),
                onClick: (e: React.MouseEvent) => {
                    e.stopPropagation();
                    deleteProjectMenu.setShowMenu(true);
                    menu.setShowMenu(false);
                },
                variant: 'distructive',
            },
        ],
        []
    );

    const onRemoveProject = async () => {
        setLoading(true);

        const [error, response] = await projectsServices.deleteProject(
            project.id
        );

        setLoading(false);

        if (error) {
            toast.error(
                intl.formatMessage({ id: getErrorCode(response.error_code) })
            );
        }

        toast.success(
            intl.formatMessage({ id: 'project_deleted_successfully' })
        );
        deleteProjectMenu.setShowMenu(false);

        onUpdateProject();
    };

    const handleSelect = (e: React.MouseEvent) => {
        if (e.currentTarget === e.target) {
            onSelectProject(project);
        }
    };

    const userCanDuplicateProject = useMemo(
        () =>
            Boolean(
                getWorkspaceById(project.workspace_id)?.details?.user_membership
            ),
        []
    );

    const onDuplicateProject = (newProject: ProjectInterface) => {
        handleSelectedProject(newProject);
        getProjects();
        duplicateProjectMenu.handleMenu();
    };

    return (
        <>
            {duplicateProjectMenu.showMenu && (
                <DuplicateProjectDialog
                    projectId={project.id}
                    callback={onDuplicateProject}
                    handleClose={duplicateProjectMenu.handleMenu}
                    userCanDuplicate={userCanDuplicateProject}
                />
            )}

            {projectToEdit && (
                <UpdateProjectDialog
                    projectToEdit={projectToEdit}
                    handleClose={() => setProjectToEdit(null)}
                    callback={() => {
                        onUpdateProject();
                        setProjectToEdit(null);
                    }}
                />
            )}

            {deleteProjectMenu.showMenu && (
                <DeleteConfirmationDialog
                    intl={intl}
                    handleClose={deleteProjectMenu.handleMenu}
                    callback={onRemoveProject}
                    buttonText={intl.formatMessage({
                        id: 'delete_permanently',
                    })}
                    text={intl.formatMessage(
                        {
                            id: 'delete_project_warning',
                        },
                        { project: project.name }
                    )}
                    title={
                        intl.formatMessage({
                            id: 'delete_project',
                        }) + '?'
                    }
                    loading={loading}
                />
            )}

            <Popover
                isOpen={menu.showMenu}
                positions={['right']}
                align={'center'}
                content={<FloatMenu menus={MENUS} left={0} />}
                onClickOutside={menu.handleMenu}
            >
                <div
                    onClick={handleSelect}
                    className={`cg-project-row  ${isSelected ? 'cg-project-row__selected' : ''}`}
                    title={project.name}
                >
                    <p onClick={handleSelect}>{project.name}</p>
                    {canDelete && <MoreHorizontal onClick={menu.handleMenu} />}
                </div>
            </Popover>
        </>
    );
};

export default ProjectRow;
