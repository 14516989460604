import { useState } from 'react';
import { useIntl } from 'react-intl';
import { FileWithPath } from 'react-dropzone';
import { fireConfirmationDialog } from '~/utils/fireConfirmationDialog';
import { UploadProviderInterface } from '~/interfaces/contexts/UploadContext.interface';
import { ImportFileTypes } from '~/constants/importFiles';
import { FileToImportInterface } from '~/modules/Import/types/importFiles';
import { UploadContext } from '~/context/upload';
import analytics, { EVENT_NAMES_ENUM } from '~/helpers/analytics';
import { useEntityContext } from '~/hooks';
import useWorkspaces from '~/modules/Workspaces/hooks/useWorkspaces';

function useImportModal(handleClose: () => void) {
    const intl = useIntl();

    const { startImport, loading } =
        useEntityContext<UploadProviderInterface>(UploadContext);

    const { selectedWorkspace } = useWorkspaces();

    const [linksToImport, setLinksToImport] = useState<string[]>([]);
    const [rejectedFiles, setRejectedFiles] = useState<FileWithPath[]>([]);
    const [filesToImport, setFilesToImport] = useState<FileToImportInterface[]>(
        []
    );

    const onAddFiles = (files: FileWithPath[]) => {
        const newItems = files.map((file) => ({
            id: crypto.randomUUID(),
            importType: ImportFileTypes.FILE,
            data: file,
        }));
        setFilesToImport([...filesToImport, ...newItems]);
    };

    const onRemoveFile = (fileId: string) => {
        const newItems = filesToImport.filter((file) => file.id !== fileId);
        setFilesToImport(newItems);
    };

    const onStartImport = async () => {
        analytics.trackGenericEvent(EVENT_NAMES_ENUM.IMPORT_DOC);

        const uniqueLinks = Array.from(new Set(linksToImport));
        const linksWithMetadata = uniqueLinks.map((link) => ({
            id: crypto.randomUUID(),
            importType: ImportFileTypes.URL,
            data: link,
        }));

        // there is a running import so files were added to the same one
        const { error } = await startImport([
            ...filesToImport,
            ...linksWithMetadata,
        ]);
        if (error) return;

        handleClose();
    };

    const onClose = () => {
        if (!filesToImport.length && !linksToImport.length)
            return handleClose();

        fireConfirmationDialog({
            title: intl.formatMessage({ id: 'have_pending_files' }),
            text: intl.formatMessage({ id: 'sure_to_exit' }),
            confirmationText: intl.formatMessage({ id: 'yes' }),
            denyText: intl.formatMessage({ id: 'no' }),
            confirmButtonColor: '#3e5bc2',
            onConfirm: handleClose,
        });
    };

    const clearImportFiles = () => {
        setFilesToImport([]);
        setRejectedFiles([]);
    };

    const areWeblinksDisabled =
        !selectedWorkspace?.details.configuration.allow_url_import;

    const importMessage =
        selectedWorkspace?.details?.configuration?.import_message;

    const showImportMessage =
        importMessage &&
        filesToImport.length === 0 &&
        rejectedFiles.length === 0;

    return {
        filesToImport,
        linksToImport,
        rejectedFiles,
        onAddFiles,
        onRemoveFile,
        onStartImport,
        onClose,
        areWeblinksDisabled,
        importMessage,
        showImportMessage,
        importLoading: loading,
        setLinksToImport,
        setRejectedFiles,
        clearImportFiles,
    };
}

export default useImportModal;
