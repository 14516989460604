import { Accept } from 'react-dropzone';
import { FILE_TYPES } from '~/modules/Documents/constants/fileTypes';

enum AllowedFileTypesToImport {
    PDF = '.pdf',
    DOC = '.doc',
    DOCX = '.docx',
    PPT = '.ppt',
    PPTX = '.pptx',
}

enum AllowedMimeTypesToImport {
    PDF = 'application/pdf',
    DOC = 'application/msword',
    DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    PPT = 'application/vnd.ms-powerpoint',
    PPTX = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
}

export const acceptedFormats: Accept = {
    [AllowedMimeTypesToImport.PDF]: [AllowedFileTypesToImport.PDF],
    [AllowedMimeTypesToImport.DOC]: [AllowedFileTypesToImport.DOC],
    [AllowedMimeTypesToImport.DOCX]: [AllowedFileTypesToImport.DOC],
    [AllowedMimeTypesToImport.PPT]: [AllowedFileTypesToImport.PPTX],
    [AllowedMimeTypesToImport.PPTX]: [AllowedFileTypesToImport.PPTX],
};

export const allowedFormats = Object.values(AllowedFileTypesToImport);

export const FileMimeTypesMap = {
    [AllowedMimeTypesToImport.DOC]: FILE_TYPES.DOC,
    [AllowedMimeTypesToImport.DOCX]: FILE_TYPES.DOCX,
    [AllowedMimeTypesToImport.PDF]: FILE_TYPES.PDF,
    [AllowedMimeTypesToImport.PPT]: FILE_TYPES.PPT,
    [AllowedMimeTypesToImport.PPTX]: FILE_TYPES.PPTX,
};
