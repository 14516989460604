import {
    Button,
    ColumnFilter,
    FullModal,
    TagsColumnFilter,
} from '~/components/UIElements';
import './index.scss';
import { IntlShape } from 'react-intl';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { BookOpen, Plus } from 'react-feather';
import {
    useColumnsFilterContext,
    useEntityContext,
    useFloatMenu,
    useTable,
} from '~/hooks';
import { BaseTable, TagCell } from '~/components/Tables';
import { documentServices, importServices, tagsServices } from '~/services';
import { ProjectContext } from '~/context/project';
import { ProjectProviderInterface } from '~/interfaces/contexts';
import {
    CGPublicDocumentInterfaceDetailed,
    PublicTagGroupInterfaceDetailed,
} from '~/interfaces/entities';
import { buildSortingParameters } from '~/helpers/insightsDataHandlers';
import toast from 'react-hot-toast';
import { getErrorCode } from '~/utils/getErrorCode';
import { normalizePublicTags } from '~/helpers/normalizePublicTags';
import { PillVariants } from '~/components/UIElements/Pill/constants';
import groupTagsByFolder from '~/helpers/groupTagsByFolder';
import { assignColorsToEntities } from '~/helpers/assignColorsToEntities';
import ImportPublicTagsDialog from '~/components/PublicLibrary/ImportPublicTagsDialog';
import { getPublicDocumentsColumnValues } from '~/helpers/getPublicDocumentsColumnValues';
import { matchFilterKeys } from '~/helpers/publicDocumentsDataHandlers';
import transformTagGroupWithItems from '~/helpers/transformTagGroupWithItems';
import { getTagColor as getTagColorUtil } from '../../helpers/getTagColor';
import { SORT_TYPE_OPTIONS } from '~/constants/SortTypeOptions.enum';
import analytics, { EVENT_NAMES_ENUM } from '~/helpers/analytics';
import {
    PublicTagInterfaceDetailed,
    TagInterfaceDetailed,
} from '~/modules/Tags/types/Tag.interface';

interface PublicLibraryProps {
    onClose: () => void;
    onImportSuccess: () => void;
    intl: IntlShape;
}

const columnsMetadata = {
    source: {
        label: 'Source',
        key: 'name',
        i8n: 'file_name',
    },
    tag: {
        label: 'Tags',
        key: 'tag_name',
        i8n: 'tags',
    },
};

const mainClass = 'cg-public-library';

const PublicLibrary: React.FC<PublicLibraryProps> = ({
    onClose,
    intl,
    onImportSuccess,
}) => {
    const isFirstRender = useRef(true);
    const tableContainer = useRef();
    const originalDocuments = useRef<CGPublicDocumentInterfaceDetailed[]>([]);

    const confirmationDialog = useFloatMenu();

    const [loading, setLoading] = useState(true);
    const [tags, setTags] = useState<PublicTagInterfaceDetailed[]>([]);

    // since the filters are hanlded frontend-side we need to store a copy of the documents in order to restore the list if is needed
    const [documents, setDocuments] = useState<
        CGPublicDocumentInterfaceDetailed[]
    >([]);
    const [folderTagsColors, setFolderTagsColors] = useState<{
        [key: string]: PillVariants;
    }>({});

    const [folderStructure, setFolderStructure] = useState<
        PublicTagGroupInterfaceDetailed[] | null
    >(null);

    const { selectedProject } =
        useEntityContext<ProjectProviderInterface>(ProjectContext);

    const {
        columnFilters,
        handleColumnValues,
        sortParams,
        resetAllFilters,
        filterParams,
        resetFilterColumn,
        onApplyFilter,
        columnValues,
    } = useColumnsFilterContext(); //custom hook

    const { selectedItems, setSelectedItems } = useTable({
        multipleSelect: true,
    });

    const groupedTags = useMemo(() => {
        if (!folderStructure) return [];

        const grouped = groupTagsByFolder(
            normalizePublicTags(tags),
            folderStructure
        );

        return grouped;
    }, [tags, folderStructure]);

    // LISTENERS
    useEffect(() => {
        if (isFirstRender.current) return;

        setSelectedItems([]);
        getDocuments(true);
    }, [columnFilters, sortParams]);

    useEffect(() => {
        getDocuments();
        getTagFolders();
        getTags();
        isFirstRender.current = false;
    }, []);

    const getDocuments = async (isFiltered?: boolean) => {
        if (Object.keys(columnFilters).length && !isFiltered)
            return resetAllFilters(); // reseting filters will launch a new search

        setLoading(true);

        const filtersPayload = matchFilterKeys(
            filterParams,
            originalDocuments.current,
            groupedTags
        );

        const [error, response] = await documentServices.getPublicDocuments({
            all: true,
            sort_attribute: 'name',
            sort_order: SORT_TYPE_OPTIONS.ASC,
            ...filtersPayload,
            ...buildSortingParameters(sortParams),
        });

        if (error) {
            setLoading(false);
            toast.error(
                intl.formatMessage({ id: getErrorCode(response.error_code) })
            );
            return;
        }
        if (!originalDocuments.current.length) {
            originalDocuments.current = response.data;
        }
        handleColumnValues(getPublicDocumentsColumnValues(response.data));

        setDocuments(response.data);
        setLoading(false);
    };

    const getTags = async () => {
        const [error, response] = await tagsServices.getPublicTags({
            all: true,
            sort_attribute: 'name',
            sort_order: SORT_TYPE_OPTIONS.ASC,
        });

        if (error)
            return toast.error(
                intl.formatMessage({ id: getErrorCode(response.error_code) })
            );

        setTags(response.data || []);
    };

    const getTagFolders = async () => {
        const [error, response] = await tagsServices.getPublicTagFolders({
            sort_order: SORT_TYPE_OPTIONS.ASC,
            sort_attribute: 'name',
            all: true,
        });

        if (error)
            return toast.error(
                intl.formatMessage({ id: getErrorCode(response.error_code) })
            );

        setFolderStructure(response.data);

        const colorsToApply = assignColorsToEntities(
            response.data.map((f) => f.id),
            folderTagsColors
        );

        setFolderTagsColors(colorsToApply);
    };

    // we have to modify the items interface to match the one from the TagsPicker. {value, label}
    const transformedFolderStructure = useMemo(
        () => transformTagGroupWithItems(groupedTags),
        [groupedTags]
    );

    const getTagColor = useCallback(
        (tagId: string) =>
            getTagColorUtil(folderTagsColors, normalizePublicTags(tags), tagId),
        [tags, folderTagsColors]
    );

    const getTagsOrderedByFolder = useCallback(
        (selectedTags: TagInterfaceDetailed[]) => {
            const flat = groupedTags.map((g) => g.items).flat();

            const ordered = flat.filter((gTag) =>
                selectedTags.some((sTag) => sTag.id === gTag.id)
            );

            return ordered;
        },
        [groupedTags]
    );

    const columns = useMemo(
        () => [
            {
                header: () => (
                    <ColumnFilter
                        headerName={columnsMetadata.source.i8n}
                        intl={intl}
                        columnName={columnsMetadata.source.key}
                        resetFilterColumn={resetFilterColumn}
                        columnFilters={columnFilters}
                        onApplyFilter={onApplyFilter}
                        columnValues={columnValues[columnsMetadata.source.key]}
                        sortParams={sortParams}
                    />
                ),
                cell: (row: CGPublicDocumentInterfaceDetailed) => (
                    <div title={row.name} className="capture-cell">
                        {row.name}
                    </div>
                ),
                id: '01',
                field: columnsMetadata.source.key,
                style: { width: '50%' },
            },
            {
                header: () => (
                    <TagsColumnFilter
                        headerName={columnsMetadata.tag.i8n}
                        intl={intl}
                        columnName={columnsMetadata.tag.key}
                        resetFilterColumn={resetFilterColumn}
                        columnFilters={columnFilters}
                        onApplyFilter={onApplyFilter}
                        columnValues={columnValues[columnsMetadata.tag.key]}
                        sortParams={sortParams}
                        folderStructure={transformedFolderStructure}
                        getTagColorCustomFn={getTagColor}
                    />
                ),
                cell: (row: CGPublicDocumentInterfaceDetailed) => (
                    <TagCell
                        documentId={row.id}
                        selectedTags={getTagsOrderedByFolder(
                            normalizePublicTags(row.public_tags)
                        )}
                        folderTagsColors={folderTagsColors}
                        disabled
                        sortAlphabetically={false}
                    />
                ),
                id: '02',
                field: columnsMetadata.tag.key,
                style: { width: '50%' },
            },
        ],
        [
            documents,
            columnValues,
            sortParams,
            columnFilters,
            folderTagsColors,
            transformedFolderStructure,
        ]
    );

    const onImport = async (includeTags: boolean) => {
        analytics.trackGenericEvent(
            includeTags
                ? EVENT_NAMES_ENUM.ADD_PUBLIC_LIBRARY_TO_PROJECT_WITH_TAGS
                : EVENT_NAMES_ENUM.ADD_PUBLIC_LIBRARY_TO_PROJECT_WITHOUT_TAGS
        );
        setLoading(true);

        const [error, response] = await importServices.importPublicDocuments(
            selectedItems,
            includeTags,
            selectedProject?.id as string
        );

        if (error) {
            setLoading(false);
            return toast.error(
                intl.formatMessage({ id: getErrorCode(response.error_code) })
            );
        }

        setLoading(false);
        toast.success(
            intl.formatMessage({ id: 'public_files_imported_success' })
        );
        onClose();
        onImportSuccess();
    };

    return (
        <FullModal isOpen={true} onClose={onClose} className={mainClass}>
            {confirmationDialog.showMenu && (
                <ImportPublicTagsDialog
                    handleClose={() => confirmationDialog.setShowMenu(false)}
                    intl={intl}
                    onConfirm={(includeTags: boolean) => {
                        onImport(includeTags);
                        confirmationDialog.handleMenu();
                    }}
                />
            )}
            <div className={mainClass + '__header'}>
                <div>
                    <BookOpen />
                    <h3>{intl.formatMessage({ id: 'public_documents' })}</h3>
                </div>
                {selectedItems.length > 0 && (
                    <Button
                        variant="primary"
                        onClick={() => confirmationDialog.setShowMenu(true)}
                        iconBefore={Plus}
                        disabled={loading}
                    >
                        {intl.formatMessage(
                            {
                                id:
                                    selectedItems.length > 1
                                        ? 'add_files_to_project'
                                        : 'add_fie_to_project',
                            },
                            { count: selectedItems.length }
                        )}
                    </Button>
                )}
            </div>
            <div className={mainClass + '__table'} ref={tableContainer}>
                <BaseTable
                    data={documents}
                    columns={columns}
                    loading={loading}
                    onSelectionChange={setSelectedItems}
                    selectedItems={selectedItems}
                    onScrollToBottom={() => {}}
                    intl={intl}
                    tableHeight={tableContainer.current?.clientHeight - 20}
                />
            </div>
        </FullModal>
    );
};

export default PublicLibrary;
