import { useState } from 'react';
import { useIntl } from 'react-intl';
import toast from 'react-hot-toast';
import { tagsServices } from '~/services';
import { getErrorCode } from '~/utils/getErrorCode';
import { TagsContext } from '~/context/tags';
import { UNCATEGORIZED_FOLDER_NAME } from '~/constants/folders';
import { useEntityContext } from '~/hooks';
import { TagsProviderInterface } from '~/modules/Tags/types/TagsContext.interface';

const useTag = () => {
    const intl = useIntl();
    const [loading, setLoading] = useState(false);

    const { getTags } = useEntityContext<TagsProviderInterface>(TagsContext);

    const moveTag = async ({
        id,
        folderId,
    }: {
        id: string;
        folderId: string;
    }) => {
        setLoading(true);

        const [error, response] = await tagsServices.updateTag(id, {
            tag_group_id:
                folderId === UNCATEGORIZED_FOLDER_NAME ? null : folderId,
        });

        setLoading(false);

        if (error) {
            toast.error(
                intl.formatMessage({ id: getErrorCode(response.error_code) })
            );

            return;
        }
        toast.success(intl.formatMessage({ id: 'tag_updated_successfully' }));

        getTags();
    };

    const deleteTag = async (tagId: string) => {
        setLoading(true);

        const [error, response] = await tagsServices.deleteTag(tagId);

        setLoading(false);

        if (error) {
            toast.error(
                intl.formatMessage({ id: getErrorCode(response.error_code) })
            );
            return;
        }

        toast.success(intl.formatMessage({ id: 'tag_deleted_successfully' }));

        getTags();
    };

    return {
        loading,
        moveTag,
        deleteTag,
        getTags,
    };
};

export default useTag;
