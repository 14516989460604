import { BaseModal, Button } from '~/components/UIElements';
import './ImportPublicTagsDialog.scss';
import { IntlShape } from 'react-intl';
const mainClass = 'cg-import-public-tags-dialog';

interface ImportPublicTagsDialogProps {
    intl: IntlShape;
    handleClose: () => void;
    onConfirm: (importWithTags: boolean) => void;
}

const ImportPublicTagsDialog: React.FC<ImportPublicTagsDialogProps> = ({
    intl,
    handleClose,
    onConfirm,
}) => {
    return (
        <BaseModal handleClose={handleClose} size="small">
            <div className={mainClass}>
                <h3>
                    {intl.formatMessage({
                        id: 'import_document_tags',
                    })}
                </h3>
                <p>
                    {intl.formatMessage({
                        id: 'import_tags_aclaration',
                    })}
                </p>
                <div className={`${mainClass}__buttons`}>
                    <Button
                        variant="secondary"
                        onClick={() => onConfirm(false)}
                    >
                        {intl.formatMessage({
                            id: 'ignore',
                        })}
                    </Button>
                    <Button variant="secondary" onClick={() => onConfirm(true)}>
                        {intl.formatMessage({
                            id: 'import_tags',
                        })}
                    </Button>
                </div>
            </div>
        </BaseModal>
    );
};

export default ImportPublicTagsDialog;
