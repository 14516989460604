import { useCallback, useContext, useMemo, useState } from 'react';
import './index.scss';
import { Box, Copy, FileText, Mail, Search, X } from 'react-feather';
import {
    BasicMenuList,
    DeleteConfirmationDialog,
    IconButton,
} from '../../UIElements';
import { authenServices } from '~/services';
import { NavLink, useLocation } from 'react-router-dom';
import ProjectMembersStack from './members/ProjectMembersStack';
import { ProjectContext } from '~/context/project';
import { Tooltip } from 'react-tooltip';
import { Edit3, MoreHorizontal, Trash2 } from 'react-feather/dist';
import { useEntityContext, useFloatMenu } from '~/hooks';
import {
    DuplicateProjectDialog,
    UpdateProjectDialog,
} from '~/modules/Projects/components';
import { getErrorCode } from '~/utils/getErrorCode';
import toast from 'react-hot-toast';
import { PRIVATE_ROUTES } from '../../Router/paths';
import RestrictedUI from '../../RestrictedUI';
import { PERMISSIONS } from '~/constants/memberPermissions';
import { ENTITIES } from '~/constants/entities';
import WorkspaceDisplay from './workspaces/WorkspaceDisplay';
import PermissionContext from '~/context/permissions/PermissionContext';
import {
    AuthContextInterface,
    ProjectProviderInterface,
} from '~/interfaces/contexts';
import { UploadProviderInterface } from '~/interfaces/contexts/UploadContext.interface';
import { UploadContext } from '~/context/upload';
import { IntlShape } from 'react-intl';
import { ClockBackIcon } from '~/assets/icons/shared';
import AddFilesButton from '~/components/AddFilesButton';
import { Popover } from 'react-tiny-popover';
import { projectsServices } from '~/services';
import { AuthContext } from '~/context/auth';
import useWorkspaces from '~/modules/Workspaces/hooks/useWorkspaces';
import { ProjectInterface } from '~/modules/Projects/types/projects.interface';

interface TopbarInterface {
    intl: IntlShape;
    showAccountWarning?: {
        days: number;
        hours: number;
        closeToExpire: boolean;
    } | null;
    hideWarning?: () => void;
}

const Topbar: React.FC<TopbarInterface> = ({
    intl,
    showAccountWarning,
    hideWarning,
}) => {
    const { handleImportHistoryModal } =
        useEntityContext<UploadProviderInterface>(UploadContext);

    const { selectedWorkspace } = useWorkspaces();

    const { pathname } = useLocation();

    const [loading, setLoading] = useState(false);
    const { isAllowedTo } = useContext(PermissionContext);

    const { user } = useEntityContext<AuthContextInterface>(AuthContext);

    const {
        hasProjects,
        selectedProject,
        loading: loadingProjects,
        getProjects,
        handleSelectedProject,
        handleShowLibrary,
        handleShowImportModal,
    } = useEntityContext<ProjectProviderInterface>(ProjectContext);

    const editProjectMenu = useFloatMenu();
    const duplicateProjectMenu = useFloatMenu();
    const deleteProjectMenu = useFloatMenu();
    const menu = useFloatMenu();

    const PROJECT_MENUS = useMemo(() => {
        const menus = [
            {
                label: intl.formatMessage({ id: 'import_history' }),
                onClick: () => {
                    handleImportHistoryModal(true);
                    menu.setShowMenu(false);
                },
                icon: ClockBackIcon,
            },
            {
                icon: Copy,
                label: intl.formatMessage({ id: 'duplicate_structure' }),
                onClick: () => {
                    duplicateProjectMenu.setShowMenu(true);
                    menu.setShowMenu(false);
                },
            },
        ];

        const canRename = isAllowedTo(ENTITIES.PROJECTS, PERMISSIONS.CREATE);
        const canDelete = isAllowedTo(ENTITIES.PROJECTS, PERMISSIONS.DELETE);

        if (canRename)
            menus.push({
                icon: Edit3,
                label: intl.formatMessage({ id: 'rename' }),
                onClick: () => {
                    editProjectMenu.setShowMenu(true);
                    menu.setShowMenu(false);
                },
            });

        if (canDelete)
            menus.push({
                icon: Trash2,
                label: intl.formatMessage({ id: 'delete' }),
                onClick: () => {
                    deleteProjectMenu.setShowMenu(true);
                    menu.setShowMenu(false);
                },
                variant: 'distructive',
            });

        return menus;
    }, [isAllowedTo]);

    const resendConfirmationLink = async () => {
        setLoading(true);

        const [error, response] = await authenServices.resendConfirmationLink(
            user.email
        );

        setLoading(false);

        if (error) {
            toast.error(
                intl.formatMessage({ id: getErrorCode(response.code) })
            );
            return;
        }

        toast.success(intl.formatMessage({ id: 'link_sent_successfully' }));
    };

    const getTimeLeft = useCallback(() => {
        if (!showAccountWarning) return '';

        return showAccountWarning.closeToExpire
            ? intl.formatMessage(
                  { id: 'hours_remaining' },
                  { count: showAccountWarning.hours }
              )
            : intl.formatMessage(
                  { id: 'days_remaining' },
                  { count: showAccountWarning.days }
              );
    }, [showAccountWarning]);

    const onDuplicateProject = (newProject: ProjectInterface) => {
        handleSelectedProject(newProject);
        getProjects();
        duplicateProjectMenu.handleMenu();
    };

    const onRemoveProject = async () => {
        setLoading(true);

        const [error, response] = await projectsServices.deleteProject(
            selectedProject?.id as string
        );

        setLoading(false);

        if (error) {
            toast.error(
                intl.formatMessage({
                    id: getErrorCode(response.error_code),
                })
            );
        }

        toast.success(
            intl.formatMessage({ id: 'project_deleted_successfully' })
        );
        deleteProjectMenu.setShowMenu(false);

        getProjects();
    };

    return (
        <div className="dg-topbar">
            {duplicateProjectMenu.showMenu && (
                <DuplicateProjectDialog
                    projectId={selectedProject?.id as string}
                    callback={onDuplicateProject}
                    handleClose={duplicateProjectMenu.handleMenu}
                    userCanDuplicate={Boolean(
                        selectedWorkspace?.details?.user_membership
                    )}
                />
            )}

            {editProjectMenu.showMenu && (
                <UpdateProjectDialog
                    projectToEdit={selectedProject}
                    handleClose={editProjectMenu.handleMenu}
                    callback={() => {
                        getProjects();
                        editProjectMenu.handleMenu();
                    }}
                />
            )}

            {deleteProjectMenu.showMenu && (
                <DeleteConfirmationDialog
                    intl={intl}
                    handleClose={deleteProjectMenu.handleMenu}
                    callback={onRemoveProject}
                    buttonText={intl.formatMessage({
                        id: 'delete_permanently',
                    })}
                    text={intl.formatMessage(
                        {
                            id: 'delete_project_warning',
                        },
                        { project: selectedProject?.name }
                    )}
                    title={
                        intl.formatMessage({
                            id: 'delete_project',
                        }) + '?'
                    }
                    loading={loading}
                />
            )}

            <div className="dg-topbar__main">
                <WorkspaceDisplay intl={intl} />

                <div className="dg-topbar__main__actions">
                    {/* ----- NAVIGATION TABS -----  */}
                    <div className="dg-topbar__main__tabs">
                        <NavLink
                            to={PRIVATE_ROUTES.search}
                            className={({ isActive }) =>
                                `dg-topbar__main__tab ${
                                    isActive || pathname === '/'
                                        ? 'dg-topbar__main__tab-selected'
                                        : ''
                                }`
                            }
                        >
                            <Search />
                            {intl.formatMessage({ id: 'search' })}
                        </NavLink>

                        <NavLink
                            to={PRIVATE_ROUTES.collection_topics}
                            className={({ isActive }) =>
                                `dg-topbar__main__tab ${
                                    isActive || pathname === '/collection_tags'
                                        ? 'dg-topbar__main__tab-selected'
                                        : ''
                                }`
                            }
                        >
                            <Box />
                            {intl.formatMessage({ id: 'collections' })}
                        </NavLink>

                        <NavLink
                            to={PRIVATE_ROUTES.reports}
                            className={({ isActive }) =>
                                `dg-topbar__main__tab ${
                                    isActive
                                        ? 'dg-topbar__main__tab-selected'
                                        : ''
                                }`
                            }
                        >
                            <FileText />
                            {intl.formatMessage({ id: 'reports' })}
                        </NavLink>
                    </div>

                    <div className="dg-topbar__main__buttons">
                        {!hasProjects ? (
                            <Tooltip
                                id={
                                    'dg-topbar__main-no-project-placeholder__tooltip'
                                }
                                place="bottom"
                                opacity={1}
                                noArrow
                            />
                        ) : null}
                        <RestrictedUI
                            to={PERMISSIONS.EDIT}
                            entity={ENTITIES.IMPORT}
                        >
                            <AddFilesButton
                                intl={intl}
                                openImportModal={() =>
                                    handleShowImportModal(true)
                                }
                                openLibraryModal={() => handleShowLibrary(true)}
                            />
                        </RestrictedUI>

                        <RestrictedUI
                            to={PERMISSIONS.EDIT}
                            entity={ENTITIES.PROJECTS}
                        >
                            <Popover
                                isOpen={menu.showMenu}
                                positions={['bottom']}
                                align={'end'}
                                content={
                                    <BasicMenuList menus={PROJECT_MENUS} />
                                }
                                onClickOutside={menu.handleMenu}
                            >
                                <IconButton
                                    icon={MoreHorizontal}
                                    onClick={menu.handleMenu}
                                    variant="secondary"
                                    disabled={loadingProjects}
                                />
                            </Popover>
                        </RestrictedUI>

                        <ProjectMembersStack intl={intl} />
                    </div>
                </div>
            </div>

            {showAccountWarning && (
                <div
                    className={`dg-topbar__warning ${
                        showAccountWarning.closeToExpire
                            ? 'dg-topbar__warning-danger'
                            : ''
                    } `}
                >
                    <div>
                        <Mail />

                        <p className="fw-500">
                            {intl.formatMessage({
                                id: 'validate_your_account',
                            })}
                        </p>
                        <div>
                            <p className="fw-300">
                                {intl.formatMessage({
                                    id: 'click_verification_link_aclaration',
                                })}{' '}
                                <span
                                    className={`f-underline fw-300 clickeable ${
                                        loading ? 'basic-disabled' : ''
                                    } `}
                                    onClick={resendConfirmationLink}
                                >
                                    {intl.formatMessage({
                                        id: 'resend_link',
                                    })}
                                </span>
                            </p>
                        </div>
                    </div>

                    <div>
                        <p className="fw-300">{getTimeLeft()}</p>
                        <X onClick={hideWarning} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Topbar;
