export enum IMPORT_STAGE_STATUSES {
    CREATED = 'created',
    PENDING_PROCESSING = 'pending_processing',
    PROCESSING_INITIATED = 'processing_initiated',
    PROCESSING_CONVERTED = 'processing_converted',
    PROCESSING_TEXT_EXTRACTED = 'processing_text_extracted',
    PROCESSING_NLP_DONE = 'processing_nlp_done',
    FINISHED = 'finished',
    CANCELED = 'canceled',
    FAILED = 'failed',
}
