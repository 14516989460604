import React from 'react';

export default function ProviderCompose(props) {
    const { providers = [], children } = props;

    return (
        <>
            {providers.reduceRight((acc, Comp) => {
                return <Comp>{acc}</Comp>;
            }, children)}
        </>
    );
}
