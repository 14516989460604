import { Button, IconButton } from '~/components/UIElements';
import './ImportStatusHeader.scss';
import { ArrowLeft } from 'react-feather';
import {
    IMPORT_STATUSES,
    IMPORT_STATUSES_TRANSLATION_MAP,
} from '~/constants/ImportStatuses.enum';
import { getShortMonthAndDate } from '~/utils/times';
import { ImportInterfaceDetailed } from '~/interfaces/entities';
import { IntlShape } from 'react-intl';
import { ImportStatusTextColor } from '~/constants/importFiles';
import { importServices } from '~/services';
import toast from 'react-hot-toast';
import { getErrorCode } from '~/utils/getErrorCode';
import RestrictedUI from '~/components/RestrictedUI';
import { ENTITIES } from '~/constants/entities';
import { PERMISSIONS } from '../../../constants/memberPermissions';
import { fireDeletionDialog } from '~/utils/fireDeletionDialog';
import { UploadContext } from '~/context/upload';
import { UploadProviderInterface } from '~/interfaces/contexts/UploadContext.interface';
import { useEntityContext } from '~/hooks';

interface ImportStatusHeaderInterface {
    intl: IntlShape;
    onReturn: () => void;
    selectedImport: ImportInterfaceDetailed;
    onAbortImport: () => void;
    loading: boolean;
    handleLoading: (loading: boolean) => void;
}

const ImportStatusHeader: React.FC<ImportStatusHeaderInterface> = ({
    intl,
    selectedImport,
    loading,
    onReturn,
    onAbortImport,
    handleLoading,
}) => {
    const { runningImport, handleRunningImport } =
        useEntityContext<UploadProviderInterface>(UploadContext);

    const onAbort = async () => {
        handleLoading(true);

        const [error, response] = await importServices.abortImport(
            selectedImport.id
        );

        if (error) {
            handleLoading(false);
            return toast.error(
                intl.formatMessage({ id: getErrorCode(response.error_code) })
            );
        }
        handleLoading(false);

        toast.success(
            intl.formatMessage({ id: 'import_aborted_successfully' })
        );
        onAbortImport();

        if (selectedImport?.id === runningImport?.id) {
            handleRunningImport(response.data);
        }
    };

    const handleAbort = () => {
        fireDeletionDialog({
            title: intl.formatMessage({ id: 'abort_import' }) + '?',
            text: intl.formatMessage({ id: 'abort_import_aclaration' }),
            confirmationText: intl.formatMessage({ id: 'abort' }),
            onConfirm: onAbort,
        });
    };

    if (!selectedImport) return null;

    return (
        <div className="import-status-header">
            <div>
                <IconButton
                    icon={ArrowLeft}
                    onClick={onReturn}
                    variant="secondary"
                    disabled={loading}
                />
                <h4>
                    {intl.formatMessage({ id: 'import_from' })}{' '}
                    {getShortMonthAndDate(new Date(selectedImport.created_at))}
                </h4>
            </div>
            <div>
                <span
                    style={{
                        color:
                            ImportStatusTextColor[selectedImport.status] ||
                            '#8d9197',
                    }}
                >
                    {intl.formatMessage({
                        id: IMPORT_STATUSES_TRANSLATION_MAP[
                            selectedImport.status
                        ],
                    })}
                </span>
                {[IMPORT_STATUSES.PENDING, IMPORT_STATUSES.STARTED].includes(
                    selectedImport.status
                ) && (
                    <RestrictedUI
                        entity={ENTITIES.IMPORT}
                        to={PERMISSIONS.EDIT}
                    >
                        <Button
                            variant="secondary"
                            onClick={handleAbort}
                            disabled={loading}
                        >
                            {intl.formatMessage({ id: 'abort' })}
                        </Button>
                    </RestrictedUI>
                )}
            </div>
        </div>
    );
};

export default ImportStatusHeader;
