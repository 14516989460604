import Swal from 'sweetalert2';

interface ConfirmationDialogInterface {
    title?: string;
    text?: string;
    confirmationText: string;
    confirmButtonColor: string;
    denyText: string;
    onConfirm: () => void;
}

export const fireConfirmationDialog = ({
    title,
    text,
    confirmationText,
    denyText,
    onConfirm,
    ...rest
}: ConfirmationDialogInterface) => {
    Swal.fire({
        title,
        text,
        showDenyButton: true,
        confirmButtonText: confirmationText,
        denyButtonText: denyText,
        showClass: {
            popup: 'animate__animated animate__fadeIn animate__faster',
        },
        hideClass: {
            popup: 'animate__animated animate__fadeOut animate__faster',
        },
        ...rest,
    }).then((result) => {
        if (result.isConfirmed) {
            onConfirm();
        }
    });
};
