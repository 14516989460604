import { PUBLIC_ROUTES } from './paths';
import { Navigate, RouteObject } from 'react-router-dom';
import { lazyLoadRoutes } from '~/components/Router/LazyLoadRoutes';

const Login = lazyLoadRoutes('Login');
const Signup = lazyLoadRoutes('Signup');
const ResetPassword = lazyLoadRoutes('ResetPassword');

const PublicRoutes: RouteObject[] = [
    {
        path: PUBLIC_ROUTES.login,
        element: Login,
    },
    {
        path: PUBLIC_ROUTES.signup,
        element: Signup,
    },
    {
        path: PUBLIC_ROUTES.recover_password,
        element: ResetPassword,
    },
    {
        path: '*',
        element: <Navigate to={PUBLIC_ROUTES.login} />,
    },
];

export default PublicRoutes;
