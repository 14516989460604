import { ChangeEvent, useMemo, useState } from 'react';
import { PlusCircle, Search } from 'react-feather';
import { useIntl } from 'react-intl';
import toast from 'react-hot-toast';
import Skeleton from 'react-loading-skeleton';
import { SlidersIcon } from '~/assets/icons/shared';
import { Popover } from 'react-tiny-popover';
import { ProjectContext } from '~/context/project';
import { TagsContext } from '~/context/tags';
import { tagsServices } from '~/services';
import { getErrorCode } from '~/utils/getErrorCode';
import { simpleFolderSearch } from '~/utils/traverseFolderTreeFunctions';
import { UNCATEGORIZED_FOLDER_NAME } from '~/constants/folders';
import { ProjectProviderInterface } from '~/interfaces/contexts';
import { TagsProviderInterface } from '~/modules/Tags/types/TagsContext.interface';
import { FolderTreeInterface } from '~/interfaces/entities';
import { TagInterface } from '~/modules/Tags/types/Tag.interface';
import TagFolderTree from '~/modules/Tags/components/TagFolderTree';
import { useEntityContext, useFloatMenu } from '~/hooks';
import useSidebarSortingMenu from '~/hooks/useSidebarSortingMenus.hook';
import useTag from '~/modules/Tags/components/TagsSidebar/useTag.hook';
import CreateTagDialog from '~/components/Dialogs/tags/CreateTagDialog';
import {
    BaseModal,
    BasicFieldForm,
    BasicMenuList,
    TextField,
} from '~/components/UIElements';
import TagList from './TagList';
import TagItem from './TagItem';

import './index.scss';

// lite mode will render a basic tag list, without some features like create tag, update tag, etc.
const TagsSidebar = ({ lite }: { lite?: boolean }) => {
    const intl = useIntl();

    const {
        tags = [],
        loading,
        getTags,
        tagMenu,
        sorting,
        handleSorting,
        getFolderStructure,
        openedFolders,
        groupedTags: folderStructure,
        openFolder,
        setLoading,
    } = useEntityContext<TagsProviderInterface>(TagsContext);

    const { selectedProject } =
        useEntityContext<ProjectProviderInterface>(ProjectContext);

    // -------- SEARCH AND FILTERING ----------
    const [showSearch, setShowSearch] = useState(false);
    const [search, setSearch] = useState('');

    const [createFolderLoading, setCreateFolderLoading] = useState(false);

    const sortMenu = useFloatMenu();
    const createTagMenu = useFloatMenu();
    const createFolderMenu = useFloatMenu();

    const { moveTag } = useTag();

    const onMoveTag = async ({
        id,
        folderId,
    }: {
        id: string;
        folderId: string;
    }) => {
        moveTag({ id, folderId });
    };

    const handleShowInput = () => {
        setShowSearch(!showSearch);
        if (showSearch) {
            setSearch('');
        }
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const term = e.target.value;

        setSearch(term);
    };

    const filteredTags = useMemo(
        () =>
            tags.filter((tag) =>
                tag.name.toLowerCase().includes(search.toLowerCase())
            ),
        [tags, search]
    );

    const handleCreateTag = async (tagName: string, parentId?: string) => {
        const [error, response] = await tagsServices.createTag({
            name: tagName,
            project_id: selectedProject?.id as string, // at this stage we are sure that selectedProject is not null
            tag_group_id: parentId || null,
        });

        if (error) {
            toast.error(
                intl.formatMessage({ id: getErrorCode(response.error_code) })
            );
            return;
        }

        toast.success(intl.formatMessage({ id: 'tag_created_successfully' }));
        getTags(false);
    };

    const handleCreateFolder = async (folderName: string) => {
        setCreateFolderLoading(true);

        const [error, response] = await tagsServices.createTagFolder({
            name: folderName,
            project_id: selectedProject?.id as string,
        });

        setCreateFolderLoading(false);

        if (error) {
            toast.error(
                intl.formatMessage({ id: getErrorCode(response.error_code) })
            );
            return;
        }

        toast.success(
            intl.formatMessage({ id: 'folder_created_successfully' })
        );

        getFolderStructure();

        createFolderMenu.handleMenu();
    };

    // to run searches on folders
    const filteredFolders = useMemo(() => {
        if (!search) return folderStructure;

        const filtered = simpleFolderSearch(folderStructure, search);

        return filtered;
    }, [search, folderStructure]);

    const shouldRenderTree = useMemo(() => {
        if (!folderStructure) return false;

        return (
            folderStructure.filter((f) => f.id !== UNCATEGORIZED_FOLDER_NAME)
                .length > 0
        );
    }, [folderStructure]);

    const CREATION_MENUS = useMemo(
        () => [
            {
                label: intl.formatMessage({ id: 'tag' }),
                onClick: () => {
                    createTagMenu.handleMenu();
                    tagMenu.setShowMenu(false);
                },
            },
            {
                label: intl.formatMessage({ id: 'folder' }),
                onClick: () => {
                    createFolderMenu.setShowMenu(true);
                    tagMenu.setShowMenu(false);
                },
            },
        ],
        []
    );

    const onUpdateFolder = async (folder: FolderTreeInterface) => {
        setLoading(true);

        const [error, response] = await tagsServices.updateTagFolder(
            folder.id,
            {
                name: folder.name,
                tag_group_id: folder.tag_group_id,
            }
        );

        setLoading(false);

        if (error) {
            toast.error(
                intl.formatMessage({ id: getErrorCode(response.error_code) })
            );
            return;
        }

        toast.success(
            intl.formatMessage({ id: 'folder_updated_successfully' })
        );

        getFolderStructure();
    };

    const onDeleteFolder = async (folderId: string) => {
        setLoading(true);

        const [error, response] = await tagsServices.deleteTagFolder(folderId);

        // delay a bit the finish of the loader in order to avoid flickering. (The rendering of the folder tree depends on a useMemo that matches tags with folders)
        setTimeout(() => {
            setLoading(false);
        }, 1000);

        if (error)
            return toast.error(
                intl.formatMessage({ id: getErrorCode(response.error_code) })
            );

        toast.success(
            intl.formatMessage({ id: 'folder_deleted_successfully' })
        );

        getTags();
        getFolderStructure();
    };

    const { menus: sortMenus } = useSidebarSortingMenu({
        sorting,
        closeMenu: () => sortMenu.setShowMenu(false),
        handleSorting,
    });

    return (
        <div className={`dg-tags-sidebar `}>
            {createTagMenu.showMenu && (
                <CreateTagDialog
                    handleClose={createTagMenu.handleMenu}
                    callback={getTags}
                />
            )}

            {createFolderMenu.showMenu && (
                <BaseModal
                    size="small"
                    handleClose={createFolderMenu.handleMenu}
                    showCloseIcon
                >
                    <BasicFieldForm
                        intl={intl}
                        onSubmit={handleCreateFolder}
                        title={intl.formatMessage({ id: 'create_folder' })}
                        confirmText={intl.formatMessage({ id: 'create' })}
                        placeholder={intl.formatMessage({ id: 'folder_name' })}
                        loading={createFolderLoading}
                    />
                </BaseModal>
            )}

            <div className="dg-tags-sidebar__header">
                <div className="dg-tags-sidebar__header__actions">
                    <Search onClick={handleShowInput} />

                    {!lite && selectedProject ? (
                        <Popover
                            isOpen={tagMenu.showMenu}
                            positions={['bottom', 'left', 'right']}
                            align="end"
                            content={
                                <BasicMenuList
                                    menus={CREATION_MENUS}
                                    className="dg-topics-sidebar__folder-menu"
                                    legend={intl.formatMessage({
                                        id: 'create_new',
                                    })}
                                />
                            }
                            onClickOutside={() => tagMenu.setShowMenu(false)}
                        >
                            <PlusCircle onClick={tagMenu.handleMenu} />
                        </Popover>
                    ) : null}

                    <Popover
                        isOpen={sortMenu.showMenu}
                        positions={['bottom', 'left', 'right']}
                        align="start"
                        content={<BasicMenuList menus={sortMenus} />}
                        onClickOutside={() => sortMenu.setShowMenu(false)}
                    >
                        <SlidersIcon onClick={sortMenu.handleMenu} />
                    </Popover>
                </div>

                {showSearch && (
                    <form className="animate__animated animate__fadeIn dg-tags-sidebar__search">
                        <TextField
                            iconBefore={Search}
                            inputProps={{
                                onChange: handleChange,
                                value: search,
                                placeholder: intl.formatMessage({
                                    id: 'type_something',
                                }),
                                autoFocus: true,
                            }}
                        />
                        <span onClick={handleShowInput}>
                            {intl.formatMessage({ id: 'hide_search' })}
                        </span>
                    </form>
                )}
            </div>

            {loading && !openedFolders.length ? (
                <div className="dg-tags-sidebar__skeleton">
                    <Skeleton count={10} />
                </div>
            ) : (
                // If folder structure is present, render the folder tree, otherwise render the tag list
                <>
                    {shouldRenderTree ? (
                        <div className="dg-tags-sidebar__container">
                            <TagFolderTree
                                structure={filteredFolders}
                                customItem={(tag: TagInterface) => (
                                    <TagItem
                                        key={tag.id}
                                        tag={tag}
                                        onClick={() => {}}
                                        lite={lite}
                                        folderStructure={folderStructure}
                                        canMoveTags
                                    />
                                )}
                                expandedFolders={openedFolders}
                                expandAllFolders={Boolean(search)}
                                onUpdateFolder={onUpdateFolder}
                                onDeleteFolder={onDeleteFolder}
                                onExpandFolder={openFolder}
                                onCreateFolderItem={handleCreateTag}
                                onMoveItem={onMoveTag}
                            />
                        </div>
                    ) : (
                        <TagList tags={filteredTags} lite={lite} />
                    )}
                </>
            )}
        </div>
    );
};

export default TagsSidebar;
