import { useState, useMemo, useCallback, useRef, useContext } from 'react';
import {
    AvatarStack,
    Button,
    SelectInput,
    TextField,
    BaseFloatMenu,
} from '../../../UIElements';
import { Mail } from 'react-feather';
import { getErrorCode } from '~/utils/getErrorCode';
import { ProjectContext } from '../../../../context/project';
import { useEntityContext, useFloatMenu } from '../../../../hooks';
import getNameInitials from '../../../../helpers/getNameInitials';
import Skeleton from 'react-loading-skeleton';
import MemberRow from './MemberRow';
import './ProjectMembersStack.scss';
import { PROJECT_MEMBER_ROLES } from '../../../../constants/memberRoles';
import toast from 'react-hot-toast';
import { projectsServices } from '~/services';
import RestrictedUI from '../../../RestrictedUI';
import { PERMISSIONS } from '../../../../constants/memberPermissions';
import { ENTITIES } from '../../../../constants/entities';
import PermissionContext from '../../../../context/permissions/PermissionContext';
import {
    AuthContextInterface,
    ProjectProviderInterface,
} from '~/interfaces/contexts';
import analytics, { EVENT_NAMES_ENUM } from '~/helpers/analytics';
import { Popover } from 'react-tiny-popover';
import randomColors from '~/constants/randomColorsArray';
import { AuthContext } from '~/context/auth';
import { useIntl } from 'react-intl';
import { ProjectMemberInterfaceDetailed } from '~/modules/Projects/types/projects.interface';

const ProjectMembersStack = () => {
    const intl = useIntl();

    const {
        projectMembers,
        loadingMembers,
        setLoadingMembers,
        selectedProject,
        getProjectMembers,
    } = useEntityContext<ProjectProviderInterface>(ProjectContext);

    const { user: userData } =
        useEntityContext<AuthContextInterface>(AuthContext);

    const { isAllowedTo } = useContext(PermissionContext);

    const initialValues = useMemo(
        () => ({
            email: '',
            role: {
                value: PROJECT_MEMBER_ROLES.USER,
                label: intl.formatMessage({ id: 'can_edit' }),
            },
        }),
        []
    );

    const AVATARS = useMemo(() => {
        // remove invitations
        const filtered = projectMembers.filter((m) => m.user);

        const circles = filtered.map((member) =>
            getNameInitials(member.user.first_name, member.user.last_name)
        );

        return circles;
    }, [projectMembers]);

    const listRef = useRef(null);

    const [member, setMember] = useState(initialValues);

    const membersMenu = useFloatMenu({
        onClickOutside: () => setMember(initialValues),
    });

    const onAddMember = async (e) => {
        e.preventDefault();

        setLoadingMembers(true);

        const [error, response] = await projectsServices.sendInvitation({
            email: member.email,
            role: member.role.value,
            project_id: selectedProject.id,
        });

        setLoadingMembers(false);

        if (error)
            return toast.error(
                intl.formatMessage({ id: getErrorCode(response.error_code) })
            );

        toast.success(intl.formatMessage({ id: 'invitation_sent' }));

        getProjectMembers();
        setMember(initialValues);
    };

    const onRemoveMember = async (memberId) => {
        setLoadingMembers(true);
        const [error, response] =
            await projectsServices.removeUserFromProject(memberId);
        setLoadingMembers(false);

        if (error) {
            toast.error(
                intl.formatMessage({ id: getErrorCode(response.error_code) })
            );
            setLoadingMembers(false);
            return;
        }

        getProjectMembers();
    };

    const onChangeMemberRole = async (memberId, role) => {
        setLoadingMembers(true);
        const [error, response] = await projectsServices.updateMemberRole(
            memberId,
            role
        );

        if (error) {
            toast.error(
                intl.formatMessage({ id: getErrorCode(response.error_code) })
            );
            setLoadingMembers(false);
            return;
        }

        getProjectMembers();
    };

    const onRemoveInvitation = async (memberId) => {
        setLoadingMembers(true);
        const [error] =
            await projectsServices.deleteProjectInvitation(memberId);
        setLoadingMembers(false);

        if (!error) getProjectMembers();
    };

    const onChangeInvitationRole = async (memberId, role) => {
        setLoadingMembers(true);
        const [error, response] = await projectsServices.updateInvitationRole(
            memberId,
            role
        );

        if (error) {
            toast.error(
                intl.formatMessage({ id: getErrorCode(response.error_code) })
            );
            setLoadingMembers(false);
            return;
        }

        getProjectMembers();
    };

    const onChange = (name, value) => {
        setMember({ ...member, [name]: value });
    };

    // when user change member permission or wants to delete it
    const handleMemberRemove = (member, role) => {
        const isInvitation = !member.user;

        isInvitation
            ? onRemoveInvitation(member.id, role)
            : onRemoveMember(member.id, role);
    };

    const handleMemberPermissions = (member, role) => {
        const isInvitation = !member.user;

        isInvitation
            ? onChangeInvitationRole(member.id, role)
            : onChangeMemberRole(member.id, role);
    };

    const PERMISSIONS_OPTIONS = useMemo(
        () => [
            {
                value: PROJECT_MEMBER_ROLES.ADMIN,
                label: intl.formatMessage({ id: 'manager' }),
            },
            {
                value: PROJECT_MEMBER_ROLES.USER,
                label: intl.formatMessage({ id: 'can_edit' }),
            },
            {
                value: PROJECT_MEMBER_ROLES.READ_ONLY,
                label: intl.formatMessage({ id: 'can_view' }),
            },
        ],
        []
    );

    const DROPDOWN_OPTIONS = useMemo(
        () => [
            ...PERMISSIONS_OPTIONS,
            {
                label: intl.formatMessage({ id: 'remove' }),
                value: 'remove',
                variant: 'distructive',
            },
        ],
        []
    );

    const canUserChangePermissions = useCallback(
        (member: ProjectMemberInterfaceDetailed) => {
            return (
                isAllowedTo(ENTITIES.PROJECT_MEMBERS, PERMISSIONS.EDIT) &&
                member.user?.id !== userData?.id
            );
        },
        [projectMembers, userData]
    );

    // if the user's account is pending to be validated, then the user object doesn't exist.
    const normalizedMembers = useMemo(() => {
        return projectMembers.map((member) => ({
            ...member,
            email: member.email || member.user?.email,
            first_name: member.user?.first_name,
            last_name: member.user?.last_name,
        }));
    }, [projectMembers]);

    const { email, role } = member;

    const containerRef = useRef();

    if (!projectMembers.length) return null;

    return (
        <div
            ref={containerRef}
            className="dg-topbar-member-stack d-none d-md-block"
        >
            <Popover
                isOpen={membersMenu.showMenu}
                positions={['bottom', 'left', 'right']}
                align="end"
                onClickOutside={() => membersMenu.setShowMenu(false)}
                parentElement={containerRef.current}
                content={
                    <BaseFloatMenu top={5} right={0} animated={false}>
                        <div className="dg-topbar-member-stack__menu">
                            <RestrictedUI
                                to={PERMISSIONS.EDIT}
                                entity={ENTITIES.PROJECT_MEMBERS}
                            >
                                <form onSubmit={onAddMember}>
                                    <TextField
                                        label={intl.formatMessage({
                                            id: 'invite_people_mail',
                                        })}
                                        inputProps={{
                                            value: email,
                                            onChange: (e) =>
                                                onChange(
                                                    'email',
                                                    e.target.value
                                                ),
                                            placeholder:
                                                intl.formatMessage({
                                                    id: 'email',
                                                }) + '...',
                                            type: 'text',
                                            disabled: loadingMembers,
                                        }}
                                        iconBefore={Mail}
                                    />
                                    <div
                                        className={`dg-topbar-member-stack__dropdown ${
                                            loadingMembers
                                                ? 'dg-topbar-member-stack__dropdown__loading'
                                                : ''
                                        }`}
                                    >
                                        <SelectInput
                                            options={PERMISSIONS_OPTIONS}
                                            value={role}
                                            onChange={(e) =>
                                                onChange('role', e)
                                            }
                                            dropdownAlign="center"
                                        />
                                        <Button
                                            disabled={
                                                !email.length || loadingMembers
                                            }
                                            type="submit"
                                            variant="accent"
                                            onClick={() =>
                                                analytics.trackGenericEvent(
                                                    EVENT_NAMES_ENUM.INVITE_TEAM
                                                )
                                            }
                                        >
                                            {intl.formatMessage({
                                                id: 'invite',
                                            })}
                                        </Button>
                                    </div>
                                </form>
                            </RestrictedUI>

                            <div
                                className="dg-topbar-member-stack__list"
                                ref={listRef}
                            >
                                {loadingMembers ? (
                                    <div className="dg-topbar-member-stack__skeleton">
                                        <Skeleton count={1} />
                                        <Skeleton count={1} />
                                        <Skeleton count={1} />
                                    </div>
                                ) : (
                                    normalizedMembers.map((member, idx) => (
                                        <MemberRow
                                            key={member.id}
                                            member={member}
                                            onChangeMember={
                                                handleMemberPermissions
                                            }
                                            onRemoveMember={handleMemberRemove}
                                            dropdownOptions={DROPDOWN_OPTIONS}
                                            permissions={PERMISSIONS_OPTIONS}
                                            intl={intl}
                                            color={randomColors[idx]}
                                            editable={canUserChangePermissions(
                                                member
                                            )}
                                            pending={!member.user}
                                        />
                                    ))
                                )}
                            </div>
                        </div>
                    </BaseFloatMenu>
                }
            >
                <AvatarStack
                    disabled={loadingMembers}
                    avatars={AVATARS}
                    maxAvatars={4}
                    size="small"
                    onClick={(e) => {
                        membersMenu.handleMenu();
                        e.stopPropagation();
                    }}
                />
            </Popover>
        </div>
    );
};

export default ProjectMembersStack;
