import { Globe } from 'react-feather';
import { FILE_TYPES } from '~/modules/Documents/constants/fileTypes';

import './index.scss';

const mainClass = 'file-type-thumbnail';

interface FileTypeThumbnailInterface {
    type: FILE_TYPES;
}

const docTypeMap = {
    [FILE_TYPES.DOC]: {
        label: 'DOC',
        color: '#3767c4',
    },
    [FILE_TYPES.DOCX]: {
        label: 'DOC',
        color: '#3767c4',
    },
    [FILE_TYPES.PDF]: {
        label: 'PDF',
        color: '#d23232',
    },
    [FILE_TYPES.PPT]: {
        label: 'PPT',
        color: '#c78a2e',
    },
    [FILE_TYPES.PPTX]: {
        label: 'PPT',
        color: '#c78a2e',
    },
    [FILE_TYPES.HTML]: {
        icon: Globe,
        color: '#58A7E0',
    },
};

const FileTypeThumbnail: React.FC<FileTypeThumbnailInterface> = ({ type }) => {
    const docType = docTypeMap[type];
    return (
        <div
            className={mainClass}
            style={{
                backgroundColor: docType?.color || 'black',
            }}
        >
            {docType?.label && docType.label}

            {docType?.icon && <docType.icon />}
        </div>
    );
};

export default FileTypeThumbnail;
