import { FormEvent, useState } from 'react';
import { BaseModal, Button, TextField } from '~/components/UIElements';
import { X } from 'react-feather';
import { toast } from 'react-hot-toast';
import { projectsServices } from '~/services';
import { useIntl } from 'react-intl';
import { useTemporaryErrorDisplay } from '~/hooks';
import { getErrorCode } from '~/utils/getErrorCode';

import styles from './UpdateProjectDialog.module.scss';
import { ProjectInterfaceDetailed } from '~/modules/Projects/types/projects.interface';

interface UpdateProjectDialogProps {
    projectToEdit: ProjectInterfaceDetailed;
    callback: () => void;
    handleClose: () => void;
}

const UpdateProjectDialog: React.FC<UpdateProjectDialogProps> = ({
    projectToEdit,
    callback,
    handleClose,
}) => {
    const intl = useIntl();

    const { error, handleError } = useTemporaryErrorDisplay<Record<
        string,
        string
    > | null>();

    const [project, setProject] = useState({ ...projectToEdit });
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        if (!project.name.trim()) {
            handleError({ project: 'required_field' });
            return;
        }

        setLoading(true);

        const [error, response] = await projectsServices.updateProject(
            project.id,
            {
                name: project.name,
            }
        );

        setLoading(false);

        if (error) {
            handleError({ server: getErrorCode(response.error_code) });
            return;
        }

        toast.success(
            intl.formatMessage({ id: 'project_updated_successfully' })
        );

        callback?.();
    };

    return (
        <BaseModal size="small" handleClose={handleClose}>
            <div className={styles.cgUpdateProjectDialog}>
                <div className={styles.cgUpdateProjectDialog__header}>
                    <h4> {intl.formatMessage({ id: 'rename_project' })} </h4>
                    <X onClick={handleClose} />
                </div>
                <form
                    className={styles.cgUpdateProjectDialog__form}
                    onSubmit={handleSubmit}
                >
                    <TextField
                        inputProps={{
                            onChange: (e) =>
                                setProject({
                                    ...project,
                                    name: e.target.value,
                                }),
                            value: project.name,
                            autoFocus: true,
                            placeholder: 'e.g. Project Default',
                        }}
                        error={
                            error?.project &&
                            intl.formatMessage({ id: error.project })
                        }
                    />

                    {error?.server && (
                        <div className="error-centered-div">
                            {intl.formatMessage({ id: error.server })}
                        </div>
                    )}

                    <Button variant="accent" type="submit" disabled={loading}>
                        {intl.formatMessage({ id: 'rename' })}
                    </Button>
                </form>
            </div>
        </BaseModal>
    );
};

export default UpdateProjectDialog;
