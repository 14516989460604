import { Bookmark, Tag } from 'react-feather';
import './MainSidebar.scss';
import { useEntityContext } from '~/hooks';
import { SIDEBAR_VIEWS } from '~/constants/sidebarViews';
import { SidebarProviderInterface } from '~/interfaces/contexts';
import { SidebarContext } from '~/context/sidebar';
import ComposedSidebar from '~/components/Layout/ComposedSidebar';
import { BaseFloatMenu } from '~/components/UIElements';
import { AnimatePresence, motion } from 'framer-motion';
import { useRef } from 'react';
import { useIntl } from 'react-intl';

const framerSidebarPanel = {
    initial: { x: '-1000%' },
    animate: { x: 0 },
    exit: { x: '-1000%' },
    transition: { duration: 0.3 },
};

const mainClass = 'cg-main-sidebar';

const MainSidebar = () => {
    const intl = useIntl();

    const { handleView, floatSidebarMenu } =
        useEntityContext<SidebarProviderInterface>(SidebarContext);

    const menuRef = useRef();

    const toggleView = (_e: React.MouseEvent, view: SIDEBAR_VIEWS) => {
        handleView(view);
        floatSidebarMenu.setShowMenu(true);
    };

    return (
        <div className={mainClass}>
            <AnimatePresence mode="wait" initial={false}>
                {floatSidebarMenu.showMenu && (
                    <motion.div
                        {...framerSidebarPanel}
                        ref={menuRef}
                        className={mainClass + '__portal'}
                    >
                        <BaseFloatMenu animated={false}>
                            <ComposedSidebar />
                        </BaseFloatMenu>
                    </motion.div>
                )}
            </AnimatePresence>
            {floatSidebarMenu.showMenu && (
                <div
                    onClick={() => floatSidebarMenu.setShowMenu(false)}
                    className={mainClass + '__portal__overlay'}
                />
            )}
            <div
                className={mainClass + '__tab'}
                onClick={(e) => toggleView(e, SIDEBAR_VIEWS.TOPICS)}
            >
                <Bookmark />
                {intl.formatMessage({ id: 'topics' })}
            </div>

            <div
                className={mainClass + '__tab'}
                onClick={(e) => toggleView(e, SIDEBAR_VIEWS.TAGS)}
            >
                <Tag />
                {intl.formatMessage({ id: 'tags' })}
            </div>
        </div>
    );
};

export default MainSidebar;
