import { PropsWithChildren, useMemo } from 'react';
import PermissionContext from './PermissionContext';
import { ProjectContext } from '../project';
import {
    PROJECT_MEMBER_PERMISSIONS,
    WORKSPACE_MEMBER_PERMISSIONS,
} from '../../constants/memberPermissions';
import { ENTITIES } from '../../constants/entities';
import { useEntityContext } from '~/hooks';
import { PERMISSIONS } from '../../constants/memberPermissions';
import { ProjectProviderInterface } from '~/interfaces/contexts';
import useWorkspaces from '~/modules/Workspaces/hooks/useWorkspaces';

// This provider is intended to be surrounding the whole application.
// It should receive the users permissions as parameter. Type = Array or String
const PermissionProvider = ({ children }: PropsWithChildren) => {
    const { selectedProject } =
        useEntityContext<ProjectProviderInterface>(ProjectContext);

    const { selectedWorkspace } = useWorkspaces();

    const userRoleInProject = useMemo(() => {
        if (!selectedProject) return null;

        const userMembership = selectedProject.details?.user_membership;

        if (!userMembership) return null;
        return userMembership.role;
    }, [selectedProject]);

    const userRoleInWorkspace = useMemo(() => {
        if (!selectedWorkspace) return null;

        const userMembership = selectedWorkspace.details?.user_membership;

        if (!userMembership) return null;

        return userMembership.role;
    }, [selectedWorkspace]);

    // Check if the requested permission is available in the list of permissions
    function isAllowedTo(entity?: ENTITIES, permission?: PERMISSIONS) {
        if (!entity || !permission) return false;

        if (entity === ENTITIES.WORKSPACES) {
            if (!userRoleInWorkspace) return false;

            return WORKSPACE_MEMBER_PERMISSIONS[userRoleInWorkspace][
                entity
            ]?.includes(permission);
        }

        if (!userRoleInProject) return false;
        return PROJECT_MEMBER_PERMISSIONS[userRoleInProject][entity].includes(
            permission
        );
    }

    return (
        <PermissionContext.Provider value={{ isAllowedTo }}>
            {children}
        </PermissionContext.Provider>
    );
};

export default PermissionProvider;
