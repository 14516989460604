import { UploadFileStatuses } from '~/constants/importFiles';
import { IMPORT_STAGE_STATUSES } from '~/constants/ImportStageStasuses.enum';

export const MapFileWithStageStatus = {
    [IMPORT_STAGE_STATUSES.CANCELED]: UploadFileStatuses.ERROR,
    [IMPORT_STAGE_STATUSES.FAILED]: UploadFileStatuses.ERROR,
    [IMPORT_STAGE_STATUSES.FINISHED]: UploadFileStatuses.SUCCESS,
    [IMPORT_STAGE_STATUSES.CREATED]: UploadFileStatuses.LOADING,
    [IMPORT_STAGE_STATUSES.PENDING_PROCESSING]: UploadFileStatuses.LOADING,
    [IMPORT_STAGE_STATUSES.PROCESSING_INITIATED]: UploadFileStatuses.LOADING,
    [IMPORT_STAGE_STATUSES.PROCESSING_CONVERTED]: UploadFileStatuses.LOADING,
    [IMPORT_STAGE_STATUSES.PROCESSING_TEXT_EXTRACTED]:
        UploadFileStatuses.LOADING,
    [IMPORT_STAGE_STATUSES.PROCESSING_NLP_DONE]: UploadFileStatuses.LOADING,
};
