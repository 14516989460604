// get the initials of a given first name and last name

const getNameInitials = (firstName: string, lastName: string) => {
    if (firstName && lastName) {
        return (firstName[0] + lastName[0]).toUpperCase();
    }
    return '';
};

export default getNameInitials;
