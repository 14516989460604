import { ReactNode } from 'react';
import {
    FilledFolderTreeInterface,
    FolderTreeInterface,
} from '~/interfaces/entities';
import { TagInterface } from '~/modules/Tags/types/Tag.interface';
import { FolderItemTypes } from '~/constants/folders';
import FolderTree from '~/components/UIElements/FolderTree';

export interface TagFolderTreeProps<TagInterface> {
    structure: FilledFolderTreeInterface[];
    customItem: (tagData: TagInterface) => ReactNode;
    expandedFolders: string[];
    expandAllFolders: boolean;
    onUpdateFolder: (folder: FolderTreeInterface) => Promise<void>;
    onDeleteFolder: (folderId: string) => void;
    onExpandFolder: (folderId: string) => void;
    onCreateFolderItem: (itemName: string, parentId: string) => void;
    onMoveItem: ({ id, folderId }: { id: string; folderId: string }) => void;
}

const TagFolderTree = ({
    structure,
    expandedFolders,
    expandAllFolders,
    customItem,
    onUpdateFolder,
    onDeleteFolder,
    onExpandFolder,
    onCreateFolderItem,
    onMoveItem,
}: TagFolderTreeProps<TagInterface>) => {
    return (
        <FolderTree
            structure={structure}
            customItem={customItem}
            showItemsCount
            expandAllFolders={expandAllFolders}
            folderItemsType={FolderItemTypes.TAGS}
            expandedFolders={expandedFolders}
            onExpandFolder={onExpandFolder}
            onCreateFolderItem={onCreateFolderItem}
            onUpdateFolder={onUpdateFolder}
            onDeleteFolder={onDeleteFolder}
            onMoveItem={onMoveItem}
        />
    );
};

export default TagFolderTree;
