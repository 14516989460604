import { useMemo } from 'react';
import { normalizeFilesToImport } from '~/helpers/normalizeImportFiles';
import { FileToImportInterface } from '~/modules/Import/types/importFiles';
import FileRow, { FileRowInterface } from '~/components/Import/FileRow';

import styles from './FilesToImportList.module.scss';

interface FilesToImportListProps {
    files: FileToImportInterface[];
    onRemoveFile: (fileId: string) => void;
}

const FilesToImporTList = ({ files, onRemoveFile }: FilesToImportListProps) => {
    const transformedFiles: FileRowInterface[] = useMemo(
        () => normalizeFilesToImport(files),
        [files]
    );

    return (
        <div className={styles.cgFileList}>
            {transformedFiles.map((file) => (
                <FileRow
                    key={file.id}
                    file={file}
                    onRemove={onRemoveFile}
                    fileRowClassName={styles.cgFileList__container}
                />
            ))}
        </div>
    );
};

export default FilesToImporTList;
