import {
    AccumulatedColumnValues,
    ColumnValueOption,
} from '~/interfaces/columnValues/ColumnFilterContext.interface';
import { CGPublicDocumentInterfaceDetailed } from '~/interfaces/entities';

export function getPublicDocumentsColumnValues(
    data: CGPublicDocumentInterfaceDetailed[]
): AccumulatedColumnValues {
    const name: ColumnValueOption[] = data.map((document) => ({
        label: document.name,
        value: document.id,
        __id: document.id + document.name,
    }));

    const tag_name: ColumnValueOption[] = [];

    data.forEach((document) => {
        document.public_tags.forEach((tag) => {
            const tagIndex = tag_name.findIndex(
                (tag_name) => tag_name.value === tag.id
            );

            if (tagIndex === -1) {
                tag_name.push({
                    label: tag.name,
                    value: tag.id,
                    __id: tag.id + tag.name,
                });
            }
        });
    });

    return {
        name,
        tag_name,
    };
}
