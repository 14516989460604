import './ComposedSidebar.scss';
import TagsSidebar from '../../modules/Tags/components/TagsSidebar';
import { useEntityContext } from '../../hooks';
import { SIDEBAR_VIEWS } from '../../constants/sidebarViews';
import { SidebarProviderInterface } from '~/interfaces/contexts';
import { SidebarContext } from '~/context/sidebar';
import { Bookmark, Tag } from 'react-feather';
import { useIntl } from 'react-intl';
import TopicsSidebar from '~/modules/Topics/components/TopicsSidebar';

const mainClass = 'cg-composed-sidebar';

const ComposedSidebar = () => {
    const intl = useIntl();

    const { view, handleView } =
        useEntityContext<SidebarProviderInterface>(SidebarContext);

    return (
        <div className={mainClass}>
            <div className={`${mainClass}__header`}>
                <div
                    onClick={() => handleView(SIDEBAR_VIEWS.TOPICS)}
                    className={`${
                        view === SIDEBAR_VIEWS.TOPICS
                            ? mainClass + '__header__selected'
                            : ''
                    }`}
                >
                    <Bookmark />
                    {intl.formatMessage({ id: 'topics' })}
                </div>
                <div
                    onClick={() => handleView(SIDEBAR_VIEWS.TAGS)}
                    className={`${
                        view === SIDEBAR_VIEWS.TAGS
                            ? mainClass + '__header__selected'
                            : ''
                    }`}
                >
                    <Tag />
                    {intl.formatMessage({ id: 'file_tags' })}
                </div>
            </div>

            {view === SIDEBAR_VIEWS.TOPICS ? (
                <TopicsSidebar />
            ) : (
                <TagsSidebar />
            )}
        </div>
    );
};

export default ComposedSidebar;
