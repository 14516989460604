import { useIntl } from 'react-intl';
import TagItem from './TagItem';

import { TagInterfaceDetailed } from '~/modules/Tags/types/Tag.interface';
import './TagList.scss';

interface TagListProps {
    tags: TagInterfaceDetailed[];
    lite?: boolean;
}

const TagList = ({ tags = [], lite }: TagListProps) => {
    const intl = useIntl();

    return (
        <div className="dg-tags-sidebar__container animate__animated animate__faster animate__fadeInRight">
            {!tags.length && (
                <span className="dg-tags-sidebar__empty">
                    {intl.formatMessage({
                        id: 'no_tags_created_yet',
                    })}
                </span>
            )}

            <div className="dg-tags-sidebar__list">
                {tags.map((tag) => (
                    <TagItem
                        key={tag.id}
                        tag={tag}
                        onClick={() => {}}
                        lite={lite}
                    />
                ))}
            </div>
        </div>
    );
};

export default TagList;
