/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 *
 *   IMPORTANT: This file is used by the internal build
 *   script `extract-intl`, and must use CommonJS module syntax
 *   You CANNOT use import/export in this file.
 */
import enCommon from '~/languages/common/en.json';
import enErrorCodes from '~/languages/errors/en.ts';

import frCommon from '~/languages/common/fr.json';
import frErrorCodes from '~/languages/errors/fr.ts';

export const enTranslationMessages = {
    ...enCommon,
    ...enErrorCodes,
};
export const frTranslationMessages = {
    ...frCommon,
    ...frErrorCodes,
};

const translationMessages = {
    en: enTranslationMessages,
    fr: frTranslationMessages,
};

export { translationMessages };
