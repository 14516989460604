import { useIntl } from 'react-intl';
import ImportStageTabs from '~/components/Import/ImportStatus/ImportStageTabs';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
    ImportInterfaceDetailed,
    ImportStageInterface,
} from '~/interfaces/entities';
import { documentServices, importServices } from '~/services';
import toast from 'react-hot-toast';
import { getErrorCode } from '~/utils/getErrorCode';
import Skeleton from 'react-loading-skeleton';
import FileRow, { FileRowInterface } from '~/components/Import/FileRow';
import { UploadFileStatuses } from '~/constants/importFiles';
import { normalizeImportedFiles } from '~/helpers/normalizeImportFiles';
import { Download, Info } from 'react-feather';
import { IMPORT_STATUSES } from '~/constants/ImportStatuses.enum';
import { Button } from '~/components/UIElements';
import { IMPORT_STAGE_STATUSES } from '~/constants/ImportStageStasuses.enum';
import { StageTabsEnum } from '~/constants/importFiles';

import './index.scss';

interface ImportStatusInterface {
    selectedImport: ImportInterfaceDetailed;
}

const mainClass = 'import-status';

const ImportStatus = ({ selectedImport }: ImportStatusInterface) => {
    const intl = useIntl();

    // rejected warning should be shonw only once. After the user dismisses it, don't show again
    const warningWasAlreadyShown = useRef(false);
    const [showWarning, setShowWarning] = useState(false);

    const [loading, setLoading] = useState<boolean>(true);
    const [selectedTab, setSelectedTab] = useState<StageTabsEnum>(
        StageTabsEnum.ALL
    );

    const [importStatus, setImportStatus] = useState<ImportStageInterface[]>(
        []
    );

    useEffect(() => {
        getImportStatus();
    }, []);

    useEffect(() => {
        if (!selectedImport) return;

        const interval = setInterval(() => {
            const importHasPendingFiles = !importStatus.every((file) =>
                [
                    IMPORT_STAGE_STATUSES.FINISHED,
                    IMPORT_STAGE_STATUSES.CANCELED,
                    IMPORT_STAGE_STATUSES.FAILED,
                ].includes(file.status)
            );

            if (!importHasPendingFiles) {
                clearInterval(interval);
            } else {
                getImportStatus();
            }
        }, 10000);

        return () => clearInterval(interval);
    }, [importStatus]);

    const getImportStatus = async () => {
        setLoading(true);

        const [error, response] = await importServices.getImportFilesStatus({
            import_id: selectedImport?.id,
            page_size: 10000,
        });

        if (error) {
            setLoading(false);
            return toast.error(
                intl.formatMessage({ id: getErrorCode(response.error_code) })
            );
        }

        setLoading(false);

        const existFailedFiles = response.data.filter(
            (f) => f.error_code
        ).length;

        if (existFailedFiles && !warningWasAlreadyShown.current) {
            setShowWarning(true);
            warningWasAlreadyShown.current = true;
        }

        setImportStatus(response.data);
    };

    const TABS = useMemo(() => {
        if (!selectedImport) return [];

        const fileCount =
            selectedImport.details.processing_errors_count +
            selectedImport.details.processing_canceled_count +
            selectedImport.details.processing_finished_count +
            selectedImport.details.processing_in_progress_count;

        const tabs = [
            {
                label: intl.formatMessage({ id: 'all' }),
                value: StageTabsEnum.ALL,
                count: fileCount,
            },
            {
                label: intl.formatMessage({ id: 'rejected' }),
                value: StageTabsEnum.REJECTED,
                count:
                    selectedImport.details.processing_errors_count +
                    selectedImport.details.processing_canceled_count,
            },
            {
                label: intl.formatMessage({ id: 'approved' }),
                value: StageTabsEnum.APPROVED,
                count: selectedImport.details.processing_finished_count,
            },
        ];

        // if current import is not finished, show pendings tab
        if (
            [IMPORT_STATUSES.PENDING, IMPORT_STATUSES.STARTED].includes(
                selectedImport.status
            )
        ) {
            tabs.splice(1, 0, {
                label: intl.formatMessage({ id: 'pending' }),
                value: StageTabsEnum.PENDING,
                count: selectedImport.details.processing_in_progress_count,
            });
        }

        return tabs;
    }, [selectedImport]);

    // we need to normalize the files to be able to display them and also filter them by the selected tab
    const transformedAndFilteredFiles: FileRowInterface[] = useMemo(() => {
        const normalized = normalizeImportedFiles(importStatus);

        if (selectedTab === StageTabsEnum.ALL) return normalized;

        return normalized.filter((file) => {
            if (selectedTab === StageTabsEnum.PENDING)
                return file.status?.type === UploadFileStatuses.LOADING;

            if (selectedTab === StageTabsEnum.REJECTED)
                return file.status?.type === UploadFileStatuses.ERROR;

            if (selectedTab === StageTabsEnum.APPROVED)
                return file.status?.type === UploadFileStatuses.SUCCESS;

            return true;
        });
    }, [importStatus, selectedTab]);

    const failedFiles = useMemo(
        () => importStatus.filter((f) => f.error_code),
        [importStatus]
    );

    const onDownloadRejectedFiles = async () => {
        const rejectedIds = failedFiles.map((f) => f.file_id);

        setLoading(true);
        const { code: error_code, triggerSuccessToast } =
            await documentServices.downloadFiles(rejectedIds);
        setLoading(false);

        if (error_code) {
            return toast.error(
                intl.formatMessage({ id: getErrorCode(error_code) })
            );
        }

        if (triggerSuccessToast) {
            toast.success(
                intl.formatMessage({ id: 'files_downloaded_successfully' })
            );
        }
    };

    return (
        <div className={mainClass}>
            <ImportStageTabs
                tabs={TABS}
                activeTab={selectedTab}
                onChangeTab={setSelectedTab}
            />

            <div className={mainClass + '__content'}>
                {showWarning && selectedTab !== StageTabsEnum.REJECTED ? (
                    <div className={mainClass + '__rejected-warning'}>
                        <div>
                            <Info />
                            <span>
                                {intl.formatMessage(
                                    {
                                        id:
                                            failedFiles.length > 1
                                                ? 'files_were_rejected'
                                                : 'file_was_rejected',
                                    },
                                    {
                                        count: failedFiles.length,
                                    }
                                )}
                            </span>
                        </div>
                        <div>
                            <span
                                onClick={() => {
                                    setSelectedTab(StageTabsEnum.REJECTED);
                                    setShowWarning(false);
                                }}
                            >
                                {intl.formatMessage({ id: 'review' })}
                            </span>
                            <span onClick={() => setShowWarning(false)}>
                                {intl.formatMessage({ id: 'dismiss' })}
                            </span>
                        </div>
                    </div>
                ) : null}

                {/* Ask user want they want to do with rejected files */}
                {selectedTab === StageTabsEnum.REJECTED &&
                failedFiles.length > 0 ? (
                    <div className={mainClass + '__rejected-actions'}>
                        <span>
                            {intl.formatMessage({
                                id: 'rejected_files_not_available',
                            })}
                        </span>
                        <Button
                            variant="secondary"
                            onClick={onDownloadRejectedFiles}
                            disabled={loading}
                            iconBefore={Download}
                        >
                            {intl.formatMessage({ id: 'download' })}
                        </Button>
                    </div>
                ) : null}

                <div className={mainClass + '__list'}>
                    {loading && importStatus.length === 0 ? (
                        <Skeleton
                            count={10}
                            className={mainClass + '__loading'}
                        />
                    ) : null}

                    {!loading && transformedAndFilteredFiles.length === 0 && (
                        <div className={mainClass + '__empty'}>
                            {intl.formatMessage({ id: 'no_files_were_found' })}.
                        </div>
                    )}

                    {transformedAndFilteredFiles.map((file) => (
                        <FileRow key={file.id} file={file} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ImportStatus;
