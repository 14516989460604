import { useState } from 'react';
import './UpdateTagDialog.scss';
import PropTypes from 'prop-types';
import { BaseModal, Button, TextField } from '../../UIElements';
import { X } from 'react-feather';
import { toast } from 'react-hot-toast';
import { tagsServices } from '../../../services';

const UpdateTagDialog = ({ intl, tagToEdit, callback, handleClose }) => {
    const [tag, setTag] = useState(tagToEdit);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!tag.name.trim()) {
            handleError({ tag: 'required_field' });
            return;
        }

        setLoading(true);

        const [error] = await tagsServices.updateTag(tag.id, {
            name: tag.name,
        });

        setLoading(false);

        if (error) {
            handleError({ server: 'general_error' });
            return;
        }

        toast.success(intl.formatMessage({ id: 'tag_updated_successfully' }));

        if (callback) callback();
    };

    const handleError = (err) => {
        setError(err);
        setTimeout(() => {
            setError(null);
        }, [3000]);
    };

    return (
        <BaseModal size="small" handleClose={handleClose}>
            <div className="cg-update-tag-dialog">
                <div>
                    <h4> {intl.formatMessage({ id: 'rename_tag' })} </h4>
                    <X onClick={handleClose} />
                </div>
                <form onSubmit={handleSubmit}>
                    <TextField
                        inputProps={{
                            onChange: (e) =>
                                setTag({ ...tag, name: e.target.value }),
                            value: tag.name,
                            autoFocus: true,
                            placeholder: 'e.g. Economics',
                        }}
                        error={
                            error?.tag && intl.formatMessage({ id: error.tag })
                        }
                    />

                    {error?.server && (
                        <div className="error-centered-div">
                            {intl.formatMessage({ id: error.server })}
                        </div>
                    )}

                    <Button variant="accent" type="submit" disabled={loading}>
                        {intl.formatMessage({ id: 'rename' })}
                    </Button>
                </form>
            </div>
        </BaseModal>
    );
};

UpdateTagDialog.propTypes = {
    intl: PropTypes.object.isRequired,
    callback: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    tagToEdit: PropTypes.object.isRequired,
};

export default UpdateTagDialog;
