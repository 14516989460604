import { useMemo, useState, MouseEventHandler } from 'react';
import { XCircle } from 'react-feather';
import { useIntl } from 'react-intl';
import isValidURL from '~/utils/isValidUrl';
import debounce from '~/utils/debounce';
import { IconButton, TextField } from '~/components/UIElements';

import styles from './LinkRow.module.scss';

interface LinkRowProps {
    link: string;
    disabled?: boolean;
    onModify: (link: string) => void;
    onRemove: MouseEventHandler<HTMLButtonElement>;
}

const LinkRow = ({ link, onModify, onRemove, disabled }: LinkRowProps) => {
    const [linkValue, setLinkValue] = useState<string>(link);
    const intl = useIntl();

    const onChange = (value: string) => {
        setLinkValue(value);

        const debouncedOnModify = debounce((linkValue) => {
            linkValue && onModify(linkValue);
        }, 300);

        debouncedOnModify(value);
    };

    const urlIsValidated: boolean = useMemo(
        () => isValidURL(linkValue),
        [linkValue]
    );

    return (
        <div className={styles.cgLinkRow}>
            <TextField
                inputProps={{
                    value: linkValue,
                    onChange: (e) => onChange(e.target.value.trim()),
                    placeholder: 'https://...',
                    disabled,
                }}
                error={
                    !urlIsValidated
                        ? intl.formatMessage({ id: 'please_enter_valid_url' })
                        : ''
                }
            />
            <IconButton
                variant="tertiary"
                icon={XCircle}
                disabled={disabled}
                onClick={onRemove}
                className={styles.cgLinkRow__button}
            />
        </div>
    );
};

export default LinkRow;
