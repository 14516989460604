import { IntlShape } from 'react-intl';
import './index.scss';
import { useEntityContext } from '~/hooks';
import { UploadProviderInterface } from '~/interfaces/contexts/UploadContext.interface';
import { UploadContext } from '~/context/upload';
import { useMemo, useState } from 'react';
import { IMPORT_STATUSES } from '~/constants/ImportStatuses.enum';
import { Info } from 'react-feather';
import { Button } from '~/components/UIElements';
import toast from 'react-hot-toast';
import { getErrorCode } from '~/utils/getErrorCode';
import { importServices } from '~/services';
import { fireDeletionDialog } from '~/utils/fireDeletionDialog';

const mainClass = 'import-progress-bar';

const ImportProgressBar = ({ intl }: { intl: IntlShape }) => {
    const {
        runningImport,
        handleRunningImport,
        handleSelectedImport,
        handleProgressMenu,
        uploadingFilesCount,
    } = useEntityContext<UploadProviderInterface>(UploadContext);

    const [revertingImport, setRevertingImport] = useState(false);

    const onAbort = async () => {
        setRevertingImport(true);
        if (!runningImport?.id) return;

        const [error, response] = await importServices.abortImport(
            runningImport?.id
        );

        setRevertingImport(false);

        if (error)
            return toast.error(
                intl.formatMessage({
                    id: getErrorCode(response.error_code),
                })
            );

        handleRunningImport(response.data);
    };

    const handleAbort = () => {
        fireDeletionDialog({
            title: intl.formatMessage({ id: 'revert_import' }) + '?',
            text: intl.formatMessage({ id: 'revert_import_aclaration' }),
            confirmationText: intl.formatMessage({ id: 'revert' }),
            onConfirm: onAbort,
        });
    };

    const failedFilesCount = useMemo(
        () => runningImport?.details?.processing_errors_count || 0,
        [runningImport]
    );

    const statusText = useMemo(() => {
        if (!runningImport?.file_count && uploadingFilesCount) {
            const msg = intl.formatMessage(
                { id: uploadingFilesCount > 1 ? 'files' : 'file_with_count' },
                { count: uploadingFilesCount }
            );
            return `${intl.formatMessage({ id: 'uploading' })} ${msg}...`;
        }
        if (revertingImport)
            return intl.formatMessage({ id: 'reverting_import' }) + '...';

        if (!runningImport) return '';

        if (
            [
                IMPORT_STATUSES.PENDING_CANCELED,
                IMPORT_STATUSES.STARTED_CANCELED,
            ].includes(runningImport.status)
        ) {
            return intl.formatMessage({ id: 'import_reverted' });
        }

        if (runningImport.status === IMPORT_STATUSES.FINISHED) {
            return intl.formatMessage({ id: 'import_completed' });
        }

        if (
            [IMPORT_STATUSES.PENDING, IMPORT_STATUSES.STARTED].includes(
                runningImport.status
            )
        ) {
            const count =
                runningImport.details.processing_in_progress_count ||
                runningImport.file_count;

            const fileMsg = intl.formatMessage(
                { id: count > 1 ? 'files' : 'file_with_count' },
                { count }
            );

            return `${intl.formatMessage({ id: 'importing' })} ${fileMsg}...`;
        }

        return '';
    }, [runningImport, revertingImport, uploadingFilesCount]);

    const buttonDisplayConditions = useMemo(() => {
        const runningImportFilesCount = runningImport?.file_count || 0;

        const importIsPending =
            [IMPORT_STATUSES.PENDING, IMPORT_STATUSES.STARTED].includes(
                runningImport?.status
            ) && runningImportFilesCount > 0;

        const importHasFinished = [
            IMPORT_STATUSES.FINISHED,
            IMPORT_STATUSES.STARTED_CANCELED,
            IMPORT_STATUSES.PENDING_CANCELED,
        ].includes(runningImport?.status);

        const conditions = {
            dismiss: importHasFinished,
            abortAndRevert: importIsPending,
            view: importIsPending,
            review: importHasFinished && failedFilesCount > 0,
        };

        return conditions;
    }, [runningImport, failedFilesCount]);

    return (
        <div className={mainClass + ' animate__animated animate__fadeIn'}>
            <div>
                <span>{statusText}</span>

                {failedFilesCount > 0 && !revertingImport ? (
                    <span className={mainClass + '__failed'}>
                        <Info />
                        {failedFilesCount}{' '}
                        {intl.formatMessage({ id: 'failed' })}
                    </span>
                ) : null}
            </div>
            <div>
                {!revertingImport && (
                    <>
                        {buttonDisplayConditions.review && (
                            <Button
                                onClick={() => {
                                    handleSelectedImport(runningImport);
                                }}
                                variant="accent"
                            >
                                {intl.formatMessage({
                                    id: 'review',
                                })}
                            </Button>
                        )}

                        {buttonDisplayConditions.view && (
                            <Button
                                onClick={() => {
                                    handleSelectedImport(runningImport);
                                }}
                                variant="accent"
                            >
                                {intl.formatMessage({
                                    id: 'view',
                                })}
                            </Button>
                        )}

                        {buttonDisplayConditions.abortAndRevert && (
                            <Button onClick={handleAbort} variant="accent">
                                {intl.formatMessage({ id: 'abort_and_revert' })}
                            </Button>
                        )}

                        {buttonDisplayConditions.dismiss && (
                            <Button
                                onClick={() => {
                                    handleRunningImport(null);
                                    handleSelectedImport(null);
                                    handleProgressMenu(false);
                                }}
                                variant="accent"
                            >
                                {intl.formatMessage({ id: 'dismiss' })}
                            </Button>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default ImportProgressBar;
