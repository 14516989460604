import { Navigate } from 'react-router-dom';
import { getQueryParams } from '~/utils/getQueryParams';
import { PRIVATE_ROUTES } from './paths';
import { V1_LINK_ROUTES } from '~/components/Router/constants';
import { QueryParamsInterface } from '~/types/queryParams.interface';

/*
    !!! WARNING !!!
    Never remove a route from the lists below, this insures backward compatibility
    - if the link route params does not match anymore the target component,
      replace the <Route element> by a parameter transformation component + redirect
    - if the link route is not maintained anymore, redirect to a user-friendly fallback component
 */

interface RouteComponentProps {
    children: React.ReactNode;
    state?: QueryParamsInterface | null;
}

interface RouteInterface {
    [entryPath: string]: {
        transformer: React.FC<RouteComponentProps> | null;
        targetPath: string;
    };
}

/* If you need to transform the data in order to keep the compatibility,
 here you have to insert the path and its data transformer  */
const pathsAndTransformers: RouteInterface = {
    [V1_LINK_ROUTES.insights_smartviewer]: {
        transformer: null,
        targetPath: PRIVATE_ROUTES.collection_topics,
    },

    [V1_LINK_ROUTES.insights_smartviewer_page]: {
        transformer: null,
        targetPath: PRIVATE_ROUTES.collection_topics,
    },

    [V1_LINK_ROUTES.insights_smartviewer_page_highlight]: {
        transformer: null,
        targetPath: PRIVATE_ROUTES.collection_topics,
    },

    [V1_LINK_ROUTES.reports]: {
        transformer: null,
        targetPath: PRIVATE_ROUTES.reports,
    },

    [V1_LINK_ROUTES.search]: {
        transformer: null,
        targetPath: PRIVATE_ROUTES.search,
    },

    // this route actually doesn't exist but it's needed to redirect existing query links
    [V1_LINK_ROUTES.collection_tags]: {
        transformer: null,
        targetPath: PRIVATE_ROUTES.collection_topics,
    },
    [V1_LINK_ROUTES.insights]: {
        transformer: null,
        targetPath: PRIVATE_ROUTES.collection_topics,
    },
    [V1_LINK_ROUTES.document_insights]: {
        transformer: null,
        targetPath: PRIVATE_ROUTES.collection_topics,
    },
};

const params: QueryParamsInterface | undefined | null =
    getQueryParams('params');

const getElement = (route: string) => {
    const { transformer: Transformer, targetPath } =
        pathsAndTransformers[route];

    if (!Transformer)
        return <Navigate to={targetPath} state={params} replace />;

    return (
        <Transformer state={params}>
            <Navigate to={targetPath} state={params} replace />
        </Transformer>
    );
};

const LinkRoutes = [
    {
        path: V1_LINK_ROUTES.search,
        element: getElement(V1_LINK_ROUTES.search),
    },
    {
        path: V1_LINK_ROUTES.insights,
        element: getElement(V1_LINK_ROUTES.insights),
    },

    {
        path: V1_LINK_ROUTES.insights_smartviewer,
        element: getElement(V1_LINK_ROUTES.insights_smartviewer),
    },

    {
        path: V1_LINK_ROUTES.document_insights,
        element: getElement(V1_LINK_ROUTES.document_insights),
    },

    {
        path: V1_LINK_ROUTES.insights_smartviewer_page,
        element: getElement(V1_LINK_ROUTES.insights_smartviewer_page),
    },

    {
        path: V1_LINK_ROUTES.insights_smartviewer_page_highlight,
        element: getElement(V1_LINK_ROUTES.insights_smartviewer_page_highlight),
    },

    {
        path: V1_LINK_ROUTES.collection_tags,
        element: getElement(V1_LINK_ROUTES.collection_tags),
    },

    {
        path: V1_LINK_ROUTES.reports,
        element: getElement(V1_LINK_ROUTES.reports),
    },
];

export default LinkRoutes;
