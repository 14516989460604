import Layout from '../Layout';
import { PRIVATE_ROUTES } from './paths';
import { Navigate, Outlet, RouteObject } from 'react-router-dom';
import { SIDEBAR_TYPES } from '~/constants/sidebarViews';
import ProviderCompose from '~/components/ProvidersCompose';
import {
    ImageViewerProvider,
    InsightsProvider,
    ProjectProvider,
    TagsProvider,
    TopicsProvider,
    UploadProvider,
} from '~/context';
import PermissionProvider from '~/context/permissions/PermissionProvider';
import SnippetsProvider from '~/modules/Search/contexts/SnippetsContext';
import AIResultsProvider from '~/modules/Search/contexts/AIResultsContext';
import SourcesProvider from '~/modules/Search/contexts/SourcesContext';
import SidebarProvider from '~/context/sidebar';
import SavedSearchesProvider from '~/modules/Search/contexts/SavedSearchesContext';
import { lazyLoadRoutes } from '~/components/Router/LazyLoadRoutes';

const SearchPage = lazyLoadRoutes('Search');
const InsightsPage = lazyLoadRoutes('Insights');
const ReportsPage = lazyLoadRoutes('Reports');

const Wrapper = () => (
    <ProviderCompose
        providers={[
            ProjectProvider,
            PermissionProvider,
            SnippetsProvider,
            SourcesProvider,
            AIResultsProvider,
            SavedSearchesProvider,
            TopicsProvider,
            TagsProvider,
            UploadProvider,
            InsightsProvider,
            SidebarProvider,
            ImageViewerProvider,
        ]}
    >
        <Outlet />
    </ProviderCompose>
);

const PrivateRoutes: RouteObject[] = [
    {
        path: PRIVATE_ROUTES.root,
        element: <Wrapper />,
        children: [
            {
                path: PRIVATE_ROUTES.root,
                element: (
                    <Layout sidebar={SIDEBAR_TYPES.TOPICS_TAGS} includeViewer />
                ),
                children: [
                    {
                        path: PRIVATE_ROUTES.root,
                        element: SearchPage,
                        index: true,
                    },
                    { path: PRIVATE_ROUTES.search, element: SearchPage },
                ],
            },
            {
                path: PRIVATE_ROUTES.root,
                element: (
                    <Layout sidebar={SIDEBAR_TYPES.TOPICS_TAGS} includeViewer />
                ),
                children: [
                    {
                        path: PRIVATE_ROUTES.collection_topics,
                        element: InsightsPage,
                    },
                ],
            },
            {
                path: PRIVATE_ROUTES.root,
                element: (
                    <Layout sidebar={SIDEBAR_TYPES.TOPICS_TAGS} includeViewer />
                ),
                children: [
                    {
                        // this route actually doesn't exist but it's needed to redirect existing query links
                        path: PRIVATE_ROUTES.collection_tags,
                        element: (
                            <Navigate to={PRIVATE_ROUTES.collection_topics} />
                        ),
                    },
                ],
            },
            {
                path: PRIVATE_ROUTES.root,
                element: <Layout sidebar={SIDEBAR_TYPES.REPORTS} />,
                children: [
                    { path: PRIVATE_ROUTES.reports, element: ReportsPage },
                ],
            },
            {
                path: '*',
                element: <Navigate to={PRIVATE_ROUTES.root} />,
            },
        ],
    },
];
export default PrivateRoutes;
