import { useIntl } from 'react-intl';
import { Check, Info, XCircle } from 'react-feather';
import { Spinner } from '~/components/UIElements';
import FileTypeThumbnail from '~/components/UIElements/FileTypeThumbnail';
import { UploadFileStatuses } from '~/constants/importFiles';
import { getErrorCode } from '~/utils/getErrorCode';
import { FILE_TYPES } from '~/modules/Documents/constants/fileTypes';
import classNames from 'classnames';

import './FileRow.scss';

export interface FileRowInterface {
    id?: string;
    name: string;
    type: FILE_TYPES;
    status?: {
        type: UploadFileStatuses;
        message?: string;
    };
}

export interface FileRowPropsInterface {
    file: FileRowInterface;
    onRemove?: (fileId: string) => void;
    fileRowClassName?: string;
}

const icons = {
    [UploadFileStatuses.LOADING]: (
        <Spinner width={15} height={15} weight={'2'} />
    ),
    [UploadFileStatuses.SUCCESS]: <Check />,
    [UploadFileStatuses.ERROR]: <Info />,
};

const mainClass = 'cg-file-row';

const FileRow = ({
    file,
    onRemove,
    fileRowClassName,
}: FileRowPropsInterface) => {
    const intl = useIntl();

    return (
        <div className={classNames(mainClass, fileRowClassName)}>
            <div>
                <FileTypeThumbnail type={file.type} />{' '}
                <p title={file.name}>{file.name}</p>
            </div>
            <div
                className={`${file?.status?.type === UploadFileStatuses.ERROR ? `${mainClass}__status-error` : ''}`}
                style={{
                    gap: file.status?.message ? '16px' : '0px',
                }}
            >
                {onRemove && (
                    <XCircle onClick={() => file.id && onRemove(file.id)} />
                )}

                {file.status && (
                    <>
                        {file.status.message && (
                            <span>
                                {intl.formatMessage({
                                    id: getErrorCode(file.status.message),
                                })}
                            </span>
                        )}
                        {icons[file.status.type]}
                    </>
                )}
            </div>
        </div>
    );
};

export default FileRow;
