import { GroupedTags } from '~/helpers/groupTagsByFolder';
import { AccumulatedColumnValues } from '~/interfaces/columnValues/ColumnFilterContext.interface';
import { CGPublicDocumentInterfaceDetailed } from '~/interfaces/entities';

export const matchFilterKeys = (
    filters: AccumulatedColumnValues,
    documents: CGPublicDocumentInterfaceDetailed[],
    groupedTags: GroupedTags
): {
    ids?: string[];
} => {
    let filteredDocs: CGPublicDocumentInterfaceDetailed[] = [...documents];

    if (filters.name) {
        const idsToFilter = filters.name.map(
            (documentName) => documentName.value
        );

        filteredDocs = filteredDocs.filter((doc) =>
            idsToFilter.includes(doc.id)
        );
    }

    if (filters.tag_name) {
        const tagIds = filters.tag_name.map((tag) => tag.value);

        // GET THE GROUPS THAT CONTAIN THE TAGS TO FILTER
        let filteredGroups = groupedTags.filter((group) =>
            group.items.some((tag) => tagIds.includes(tag.id))
        );

        // FILTER THE TAGS IN THE GROUPS
        filteredGroups = filteredGroups.map((g) => ({
            ...g,
            items: g.items.filter((tag) => tagIds.includes(tag.id)),
        }));

        filteredDocs = filteredDocs.filter((doc) =>
            filteredGroups.every((group) =>
                group.items.some((tag) =>
                    doc.public_tags.some((t) => t.id === tag.id)
                )
            )
        );
    }

    const isFiltered = Object.keys(filters).length > 0;

    return {
        ids: isFiltered ? filteredDocs.map((d) => d.id) : [],
    };
};
