import { useState } from 'react';
import { X } from 'react-feather';
import { toast } from 'react-hot-toast';
import { projectsServices } from '~/services';
import {
    CheckboxField,
    BaseModal,
    Button,
    TextField,
} from '~/components/UIElements';
import { useIntl } from 'react-intl';
import { useTemporaryErrorDisplay } from '~/hooks';
import { getErrorCode } from '~/utils/getErrorCode';
import {
    ProjectToDuplicate,
    ProjectInterface,
} from '../../types/projects.interface';
import convertProjectUpsertPayload from '../../helpers/convertProjectUpsertPayload';

import styles from './DuplicateProjectDialog.module.scss';

interface DuplicateProjectDialogProps {
    projectId: string;
    userCanDuplicate: boolean;
    callback: (newProject: ProjectInterface) => void;
    handleClose: () => void;
}

const DuplicateProjectDialog: React.FC<DuplicateProjectDialogProps> = ({
    projectId,
    callback,
    handleClose,
    userCanDuplicate,
}) => {
    const intl = useIntl();

    const [project, setProject] = useState<ProjectToDuplicate>({
        name: '',
        copyPermissions: false,
        copyTags: false,
        copyCommentTable: false,
        copyDocumentTable: false,
        copySavedSearches: false,
        copyFolders: false,
        copyDocuments: false,
        copyTopics: false,
    });

    const { error, handleError } = useTemporaryErrorDisplay<Record<
        string,
        string
    > | null>();

    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!project.name.trim()) {
            handleError({ project: 'required_field' });
            return;
        }

        setLoading(true);

        const payload = convertProjectUpsertPayload(project);

        const [error, response] = await projectsServices.duplicateProject(
            projectId,
            payload
        );

        setLoading(false);

        if (error) {
            toast.error(
                intl.formatMessage({ id: getErrorCode(response.error_code) })
            );
            return;
        }

        toast.success(
            intl.formatMessage({ id: 'project_duplicated_successfully' })
        );

        callback?.(response.data);
    };

    return (
        <BaseModal size="small" handleClose={handleClose}>
            {!userCanDuplicate ? (
                <div className="text-center">
                    {intl.formatMessage({
                        id: 'no_member_to_duplicate_project',
                    })}
                </div>
            ) : (
                <div className={styles.cgDuplicateProjectDialog}>
                    <div className={styles.cgDuplicateProjectDialog__header}>
                        <h4>
                            {intl.formatMessage({
                                id: 'duplicate_project_structure',
                            })}
                        </h4>
                        <X onClick={handleClose} />
                    </div>

                    <div>
                        <p className="fw-300">
                            {intl.formatMessage({
                                id: 'duplicate_project_info',
                            })}
                        </p>
                    </div>

                    <form
                        onSubmit={handleSubmit}
                        className={styles.cgDuplicateProjectDialog__form}
                    >
                        <TextField
                            label={intl.formatMessage({ id: 'name' })}
                            inputProps={{
                                onChange: (e) =>
                                    setProject({
                                        ...project,
                                        name: e.target.value,
                                    }),
                                value: project.name,
                                autoFocus: true,
                                placeholder: 'e.g. Project Default',
                            }}
                            error={
                                error?.project &&
                                intl.formatMessage({ id: error.project })
                            }
                        />

                        <div
                            className={styles.cgDuplicateProjectDialog__options}
                        >
                            <label>
                                {intl.formatMessage({ id: 'settings' })}
                            </label>
                            <CheckboxField
                                label={intl.formatMessage({
                                    id: 'duplicate_permissions',
                                })}
                                inputProps={{
                                    onChange: (e) =>
                                        setProject({
                                            ...project,
                                            copyPermissions: e.target.checked,
                                        }),
                                    checked: project.copyPermissions,
                                }}
                                id="copy-permission-checkbox"
                            />

                            <CheckboxField
                                label={intl.formatMessage({
                                    id: 'duplicate_insights_structure',
                                })}
                                inputProps={{
                                    onChange: (e) =>
                                        setProject({
                                            ...project,
                                            copyCommentTable: e.target.checked,
                                        }),
                                    checked: project.copyCommentTable,
                                }}
                                id="copy-insights-checkbox"
                            />

                            <CheckboxField
                                label={intl.formatMessage({
                                    id: 'duplicate_documents_structure',
                                })}
                                inputProps={{
                                    onChange: (e) =>
                                        setProject({
                                            ...project,
                                            copyDocumentTable: e.target.checked,
                                        }),
                                    checked: project.copyDocumentTable,
                                }}
                                id="copy-structure-checkbox"
                            />

                            <CheckboxField
                                label={intl.formatMessage({
                                    id: 'duplicate_favorite_queries',
                                })}
                                inputProps={{
                                    onChange: (e) =>
                                        setProject({
                                            ...project,
                                            copyTags: e.target.checked,
                                            copySavedSearches: e.target.checked,
                                        }),
                                    checked: project.copySavedSearches,
                                }}
                                id="copy-queries-checkbox"
                            />

                            <CheckboxField
                                label={intl.formatMessage({
                                    id: 'duplicate_tags',
                                })}
                                inputProps={{
                                    onChange: (e) =>
                                        setProject({
                                            ...project,
                                            copyTags: e.target.checked,
                                        }),
                                    checked: project.copyTags,
                                    disabled: project.copySavedSearches,
                                }}
                                id="copy-tags-checkbox"
                            />

                            <CheckboxField
                                label={intl.formatMessage({
                                    id: 'duplicate_topics',
                                })}
                                inputProps={{
                                    onChange: (e) =>
                                        setProject({
                                            ...project,
                                            copyTopics: e.target.checked,
                                        }),
                                    checked: project.copyTopics,
                                    disabled: project.copySavedSearches,
                                }}
                                id="copy-topics-checkbox"
                            />

                            <CheckboxField
                                label={intl.formatMessage({
                                    id: 'duplicate_folders',
                                })}
                                inputProps={{
                                    onChange: (e) =>
                                        setProject({
                                            ...project,
                                            copyFolders: e.target.checked,
                                        }),
                                    checked: project.copyFolders,
                                }}
                                id="copy-folders-checkbox"
                            />

                            <CheckboxField
                                label={intl.formatMessage({
                                    id: 'duplicate_documents',
                                })}
                                inputProps={{
                                    onChange: (e) =>
                                        setProject({
                                            ...project,
                                            copyDocuments: e.target.checked,
                                        }),
                                    checked: project.copyDocuments,
                                }}
                                id="copy-docs-checkbox"
                            />
                        </div>

                        {error?.server && (
                            <div className="error-centered-div">
                                {intl.formatMessage({ id: error.server })}
                            </div>
                        )}

                        <Button
                            variant="accent"
                            type="submit"
                            disabled={loading}
                        >
                            {intl.formatMessage({ id: 'duplicate' })}
                        </Button>
                    </form>
                </div>
            )}
        </BaseModal>
    );
};

export default DuplicateProjectDialog;
