function validateURL(url: string) {
    const regex = /^(http|https):\/\/.*/;
    return regex.test(url);
}

const isValidURL = (url: string) => {
    if (!validateURL(url)) return false;

    try {
        new URL(url);
        return true;
    } catch (error) {
        return false;
    }
};

export default isValidURL;
