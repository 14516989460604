import { useIntl } from 'react-intl';
import { Globe, HardDrive } from 'react-feather';
import { Button, SwitchInputText } from '~/components/UIElements';
import { ImportFileTypes } from '~/constants/importFiles';

import styles from './Header.module.scss';

interface HeaderProps {
    currentView: ImportFileTypes;
    areWeblinksDisabled: boolean;
    loading: boolean;
    disableImport: boolean;
    handleView: (view: string) => void;
    onStartImport: () => void;
    onCancelImport: () => void;
}

const Header = ({
    currentView,
    areWeblinksDisabled,
    handleView,
    onStartImport,
    onCancelImport,
    loading,
    disableImport,
}: HeaderProps) => {
    const intl = useIntl();

    return (
        <div className={styles.cgHeader}>
            <div className={styles.cgHeader__container}>
                {areWeblinksDisabled ? (
                    <h4> {intl.formatMessage({ id: 'import_files' })} </h4>
                ) : (
                    <>
                        <span>{intl.formatMessage({ id: 'import_from' })}</span>
                        <SwitchInputText
                            leftValue={{
                                label: intl.formatMessage({
                                    id: 'local_storage',
                                }),
                                value: ImportFileTypes.FILE,
                                icon: HardDrive,
                            }}
                            rightValue={{
                                label: intl.formatMessage({
                                    id: 'web_links',
                                }),
                                value: ImportFileTypes.URL,
                                icon: Globe,
                            }}
                            handleToggle={handleView}
                            value={currentView}
                            disabled={loading}
                            className={styles.cgHeader__switchText}
                        />
                    </>
                )}
            </div>
            <div className={styles.cgHeader__container}>
                <Button
                    variant="secondary"
                    onClick={onCancelImport}
                    disabled={loading}
                >
                    {intl.formatMessage({ id: 'cancel' })}
                </Button>
                <Button
                    disabled={loading || disableImport}
                    variant="accent"
                    onClick={onStartImport}
                >
                    {intl.formatMessage({ id: 'import' })}
                </Button>
            </div>
        </div>
    );
};

export default Header;
