import { CognyErrors } from '~/languages/errors/errors.interface';

const frErrorCodes: CognyErrors = {
    an_error_occurred: 'Something went wrong. Please, try again later',
    invalid_payload: 'Something went wrong. Please, try again later',
    at_least_one_property_attribute_must_be_provided:
        'One property must be provided at minimum',
    comment_has_no_screenshot: 'The comment has no screenshot',
    comment_already_has_screenshot:
        'The comment has already a screenshot associated',
    comment_screenshot_invalid_extension:
        'The comment screenshot extension is invalid',
    comment_screenshot_invalid_mime_type:
        'The comment screenshot mime is invalid',
    comment_not_found: 'The comment was not found',
    document_deletion_impossible_because_has_comments:
        'The document has associated comment, so it cannot be deleted',
    document_deletion_impossible_because_is_included_in_search_results:
        'The document cannot be deleted as it is included in a search result',
    document_not_found: 'The document was not found',
    file_invalid_extension: 'File type not supported',
    file_invalid_mime_type: 'File type not supported',
    file_invalid_url: 'Url format is invalid',
    file_invalid_size: 'File is too large',
    file_not_ready_to_be_fetched: 'The file is not ready yet',
    file_not_found: 'The file was not found',
    import_invalid_status: 'Import state does not allow this operation',
    import_missing_files: 'Import has no file',
    import_not_found: 'The import was not found',
    import_stage_invalid_status: 'Import state does not allow this operation',
    import_stage_not_found: 'The import stage was not found',
    project_must_have_at_least_one_admin:
        'Project must have at least one administrator',
    project_deletion_impossible_because_has_documents:
        'Project cannot be deleted as it contains documents',
    project_deletion_impossible_because_has_topics:
        'Project cannot be deleted as it contains topics',
    project_deletion_impossible_because_has_users:
        'Project cannot be deleted as it contains users',
    project_not_found: 'Project was not found',
    project_member_already_exists: 'Project member already exists',
    project_member_not_found: 'Project member was not found',
    project_member_invitation_already_exists:
        'Project member invitation already exists',
    project_member_invitation_not_found:
        'Project member invitation was not found',
    search_result_not_found: 'Saved result was not found',
    topic_invalid: 'Topic is invalid',
    topic_deletion_impossible_because_has_comments:
        "Can't delete topic with already existing comments",
    topic_not_found: 'Topic was not found',
    user_already_exists: 'User already exists',
    invalid_mail_format: 'Invalid mail format',
    invalid_password_format: 'Invalid password format',
    user_not_found: 'User was not found',
    workspace_member_already_exists: 'Workspace member already exists',
    workspace_member_not_found: 'Workspace member was not found',
    workspace_member_invitation_not_found:
        'Workspace member invitation was not found',
    workspace_not_found: 'Workspace was not found',
};

export default frErrorCodes;
