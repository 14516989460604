import { useMemo, useRef } from 'react';
import { ArrowRight, Edit3, Icon, MoreHorizontal, Trash2 } from 'react-feather';
import { Popover } from 'react-tiny-popover';
import { Tooltip } from 'react-tooltip';
import { useIntl } from 'react-intl';
import toast from 'react-hot-toast';
import { FolderTreeInterface } from '~/interfaces/entities';
import { TagInterface } from '~/modules/Tags/types/Tag.interface';
import { useDraggable, useFloatMenu, useOpenFolders } from '~/hooks';
import { PERMISSIONS } from '~/constants/memberPermissions';
import { ENTITIES } from '~/constants/entities';
import useTag from '~/modules/Tags/components/TagsSidebar/useTag.hook';
import {
    BaseModal,
    BasicMenuList,
    DeleteConfirmationDialog,
    FolderSelector,
    IconButton,
} from '~/components/UIElements';
import UpdateTagDialog from '~/components/Dialogs/tags/UpdateTagDialog';
import RestrictedUI from '~/components/RestrictedUI';
import classNames from 'classnames';

import styles from './TagItem.module.scss';

interface TagItemProps {
    tag: TagInterface;
    onClick: () => void;
    isSelected?: boolean;
    folderStructure?: FolderTreeInterface[];
    selectedIcon?: Icon;
    canMoveTags?: boolean;
    lite?: boolean;
}

const TagItem = ({
    tag,
    onClick,
    isSelected,
    lite,
    folderStructure,
    canMoveTags,
    selectedIcon: SelectedIcon,
}: TagItemProps) => {
    const intl = useIntl();
    const menu = useFloatMenu();
    const deleteMenu = useFloatMenu();
    const renameMenu = useFloatMenu();
    const moveMenu = useFloatMenu();
    const { loading, moveTag, deleteTag, getTags } = useTag();
    const { openedFolders, openFolder } = useOpenFolders(
        tag.tag_group_id ? [tag.tag_group_id] : []
    );

    const { dragItemRef, isDragging } = useDraggable();

    const onDelete = async () => {
        deleteTag(tag.id);
    };

    const onMoveTag = async (destinationFolderId: string) => {
        if (destinationFolderId === tag.tag_group_id) {
            toast.error(intl.formatMessage({ id: 'tag_already_in_folder' }));
            return;
        }

        moveTag({
            id: tag.id,
            folderId: destinationFolderId,
        });
        moveMenu.handleMenu();
    };

    const MENUS = useMemo(() => {
        const menus = [
            {
                label: intl.formatMessage({ id: 'rename' }),
                icon: Edit3,
                onClick: () => {
                    menu.setShowMenu(false);
                    renameMenu.setShowMenu(true);
                },
            },
            {
                label: intl.formatMessage({ id: 'delete' }),
                icon: Trash2,
                onClick: () => {
                    menu.setShowMenu(false);
                    deleteMenu.setShowMenu(true);
                },
                variant: 'distructive',
            },
        ];

        // only if there are created folders we can move the tag (the first menu is the Uncategorized menu that always exists)
        if (canMoveTags)
            menus.splice(1, 0, {
                label: intl.formatMessage({ id: 'move_to' }),
                icon: ArrowRight,
                onClick: () => {
                    menu.setShowMenu(false);
                    moveMenu.setShowMenu(true);
                },
            });

        return menus;
    }, [canMoveTags]);

    return (
        <div
            key={tag.id}
            className={classNames(styles.cgTagItem, {
                [styles.cgTagItem__dragged]: isDragging,
            })}
        >
            <div className={styles.cgTagItem__wrapper}>
                <Tooltip
                    id={`selected-tag-${tag.id}`}
                    className={styles.cgTagItem__tooltip}
                    place="bottom"
                    opacity={1}
                />

                <div
                    ref={dragItemRef}
                    draggable={true}
                    id={tag.id}
                    onClick={onClick}
                    data-tooltip-id={`selected-tag-${tag.id}`}
                    data-tooltip-content={tag.name}
                    data-tooltip-delay-show={1000}
                    className={classNames(styles.cgTagItem__card, {
                        [styles['cgTagItem__card--selected']]: isSelected,
                    })}
                >
                    {isSelected && SelectedIcon && <SelectedIcon />}
                    <p className={styles.cgTagItem__name}> {tag.name}</p>
                </div>

                <span className={styles.cgTagItem__count}>
                    {Number(tag?.details?.document_count) >= 0
                        ? tag.details.document_count
                        : null}
                </span>

                {!lite && (
                    <RestrictedUI
                        entity={[ENTITIES.TAGS]}
                        to={PERMISSIONS.EDIT}
                    >
                        <Popover
                            isOpen={menu.showMenu}
                            positions={['right']}
                            align="start"
                            onClickOutside={menu.handleMenu}
                            content={<BasicMenuList menus={MENUS} />}
                        >
                            <div
                                onClick={menu.handleMenu}
                                className={classNames(
                                    styles['cgTagItem__item--more'],
                                    {
                                        [styles['cgTagItem__item--menuOpen']]:
                                            menu.showMenu,
                                    }
                                )}
                            >
                                <IconButton
                                    variant="tertiary"
                                    icon={MoreHorizontal}
                                    onClick={menu.handleMenu}
                                />
                            </div>
                        </Popover>
                    </RestrictedUI>
                )}

                {renameMenu.showMenu && (
                    <UpdateTagDialog
                        handleClose={renameMenu.handleMenu}
                        intl={intl}
                        tagToEdit={tag}
                        callback={getTags}
                    />
                )}

                {moveMenu.showMenu && folderStructure && (
                    <BaseModal
                        showCloseIcon
                        handleClose={moveMenu.handleMenu}
                        noPadding
                    >
                        <FolderSelector
                            structure={folderStructure}
                            value={tag.tag_group_id}
                            onConfirm={onMoveTag}
                            onCancel={moveMenu.handleMenu}
                            expandedFolders={openedFolders}
                            onExpandFolder={openFolder}
                            placeholder={
                                intl.formatMessage({
                                    id: 'search_folders',
                                }) + '...'
                            }
                            confirmButtonText={intl.formatMessage({
                                id: 'confirm_location',
                            })}
                            loading={loading}
                            showUncategorizedFolder
                        />
                    </BaseModal>
                )}

                {deleteMenu.showMenu && (
                    <DeleteConfirmationDialog
                        handleClose={deleteMenu.handleMenu}
                        callback={onDelete}
                        buttonText={intl.formatMessage({
                            id: 'delete_tag',
                        })}
                        text={intl.formatMessage({
                            id: 'delete_warning',
                        })}
                        title={
                            intl.formatMessage({
                                id: 'delete_tag',
                            }) + '?'
                        }
                        loading={loading}
                    />
                )}
            </div>
        </div>
    );
};

export default TagItem;
