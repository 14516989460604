import { ReactNode, memo } from 'react';
import { IntlProvider } from 'react-intl';
import { translationMessages } from './i18n';

interface LanguageProviderProps {
    children: ReactNode;
}

const LanguageProvider = (props: LanguageProviderProps) => {
    return (
        <IntlProvider locale={'en'} messages={translationMessages['en']}>
            {props.children}
        </IntlProvider>
    );
};

const MemoizedLanguageProvider = memo(LanguageProvider);
export default MemoizedLanguageProvider;
