import './index.scss';
import { BaseModal, Spinner } from '~/components/UIElements';
import { useEntityContext } from '~/hooks';
import { IntlShape } from 'react-intl';
import ImportHistoryTable from '~/components/Import/ImportHistoryModal/ImportHistoryTable';
import ImportStatus from '~/components/Import/ImportStatus';
import ImportStatusHeader from '~/components/Import/ImportStatus/ImportStatusHeader';
import { UploadProviderInterface } from '~/interfaces/contexts/UploadContext.interface';
import { UploadContext } from '~/context/upload';
import { ImportHistoryViews } from '~/constants/importFiles';

const mainClass = 'cg-import-history';

interface ImportHistoryModalInterface {
    intl: IntlShape;
    handleClose: () => void;
}

const ImportHistoryModal: React.FC<ImportHistoryModalInterface> = ({
    intl,
    handleClose,
}) => {
    const {
        historyModalView,
        handleHistoryModalView,
        loading,
        handleLoading,
        selectedImport,
        handleSelectedImport,
        imports,
        getImports,
        increasePage,
    } = useEntityContext<UploadProviderInterface>(UploadContext);

    return (
        <BaseModal
            size="xlarge"
            handleClose={() => {
                handleClose();
                handleSelectedImport(null);
            }}
            noPadding
        >
            <div className={mainClass}>
                {historyModalView === ImportHistoryViews.IMPORT_STATUS ? (
                    <ImportStatusHeader
                        selectedImport={selectedImport}
                        intl={intl}
                        onReturn={() =>
                            handleHistoryModalView(
                                ImportHistoryViews.IMPORT_HISTORY
                            )
                        }
                        onAbortImport={() => {
                            handleHistoryModalView(
                                ImportHistoryViews.IMPORT_HISTORY
                            );
                            getImports();
                        }}
                        loading={loading}
                        handleLoading={handleLoading}
                    />
                ) : (
                    <div className={mainClass + '__header'}>
                        <h4>{intl.formatMessage({ id: 'import_history' })}</h4>
                        {loading && (
                            <Spinner width={20} height={20} weight={'2'} />
                        )}
                    </div>
                )}
                {historyModalView === ImportHistoryViews.IMPORT_STATUS ? (
                    <ImportStatus intl={intl} selectedImport={selectedImport} />
                ) : (
                    <ImportHistoryTable
                        imports={imports}
                        loading={loading}
                        increasePage={increasePage}
                        intl={intl}
                        onSelectImport={(imp) => {
                            handleHistoryModalView(
                                ImportHistoryViews.IMPORT_STATUS
                            );
                            handleSelectedImport(imp);
                        }}
                    />
                )}
            </div>
        </BaseModal>
    );
};

export default ImportHistoryModal;
